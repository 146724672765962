import React from "react";
import { PaginationItem, PaginationLink } from "reactstrap";

function PaginationComponent(props) {
  var { pageNumber, active } = props;

  const onPageSelected = () => {
    props.callback(pageNumber);
  };

  return (
    <PaginationItem className={active ? "active" : ""}>
      <PaginationLink onClick={onPageSelected}>{pageNumber}</PaginationLink>
    </PaginationItem>
  );
}

export default PaginationComponent;
