import LocalizedStrings from "react-localization";

const LocaleStrings = new LocalizedStrings({
  en: {
    // Menu Superadmin
    side_dashboard: "Dashboard",
    side_events: "Events",
    side_clients: "Clients",
    side_master_settings: "Master Settings",
    side_email_template: "Email Templates",

    // Common
    common_fail_message: "Something went wrong!",
    common_success_message: "Saved successfully!",
    common_success_updated: "Successfully updated",
    common_email_exist_in_system_message:
      "Email already used by other member, try another email!",
    search: "Search",
    previous: "Previous",
    next: "Next",
    manager: "Manager",
    male: "Male",
    female: "Female",
    other: "Other",
    edit: "Edit",

    // Common Validations
    requird: "Required",
    invalid_email: "Invalid email",
    invalid_phone: "Invalid phone",
    invalid_number: "Not a valid number",
    invalid_url: "Invalid url",
    invalid_fb_url: "Invalid facebook url",
    invalid_linkedin_url: "Invalid linkedin url",
    invalid_twitter_url: "Invalid twitter url",
    required_terms_condition: "Check the terms & conditions.!",
    invalid_profile_image_size:
      "Image is smaller than recommended image, upload another image!",
    check_dates_diff: "Check the closing date!",
    password_min_length: "Password must be 6 char length",

    // Buttons
    button_login: "Login",
    button_logout: "Logout",
    button_signup: "Signup",
    button_save: "Save",
    button_edit: "Edit",
    button_close: "Close",
    button_cancel: "Cancel",
    button_activate: "Activate",
    button_deactivate: "Deactivate",
    button_duplicate: "Duplicate",
    button_yes: "Yes",
    button_no: "No",
    button_remove: "Remove",
    button_show_teams: "Show Teams",
    button_next: "Next",
    button_previous: "Previous",
    button_add_activity: "Add Activity",
    button_add_activity_with_plus: "+ Activity",
    button_add_event: "Create Event",
    button_event_emails: "Event Emails",
    button_event_urls: "Event URLs",
    button_export: "Export",
    button_add_client: "Add Client",
    button_assign_client: "Assign to Client",
    button_view: "View",
    button_withdraw: "Withdraw",
    button_team_manage: "Manage Team",
    button_export_to_csv: "Export to CSV",
    button_export_to_csv_all: "Export All teams",
    button_export_to_csv_filtered: "Export Confirmed teams",
    button_loading: "Loading...",
    button_add_team_member: "Add Entrant",
    button_go_back: "Back to previous page",
    button_copy_parent_link: "Copy Parent Link",
    button_leg: "Add Leg",
    button_upload_new_image: "Upload New Image",
    button_crop: "Crop",
    button_copy_link: "Copy Link",
    button_copied: "Copied",
    button_password_generate: "Generate New Password",
    label_new_password: "New Password: ",
    button_refresh: "Refresh",

    // Login & Signup
    welcome: "Welcome!",
    welcome_text_sub: "Login to manage your admin panel.",
    login_title_login: "Login",
    login_form_label_email: "Email Address",
    login_form_ph_email: "Enter your email address",
    login_form_label_password: "Password",
    login_form_ph_password: "Enter your password",
    login_text_dont_have_account: "Don't have an account?",
    login_error_text_invalid_email_password: "Invalid email or password!",
    login_error_text_invalid_user: "Your are not registered with us!",

    signup_title_signup: "Signup",
    signup_form_label_fname: "First Name",
    signup_form_ph_fname: "Enter your first name",
    signup_form_label_lname: "Last Name",
    signup_form_ph_lname: "Enter your last name",
    signup_error_already_registered:
      "Email already registered with this event!",
    signup_text_already_an_account: "Aready have an account?",
    logout_confirm_subtext: "Continue to logout?",

    // Events
    events_tabs_active: "Active Events",
    events_tabs_inactive: "Inactive Events",
    events_list_header: "Event List",
    events_text_no_active_data: "No active event available",
    events_text_no_inactive_data: "No inactive event available",
    events_th_eventid: "Event Id",
    events_th_event_name: "Event Name",
    events_th_event_type: "Event Type",
    events_th_event_active_start: "Active Start",
    events_th_event_active_end: "Active End",
    events_activated_subtitle: "Continue to activate?",
    events_deactivated_subtitle: "Continue to deactivate?",
    events_alert_event_activated: "Event activated successfully!",
    events_alert_event_deactivated: "Event deactivated successfully!",
    events_text_event_type: "Event Type",
    events_details_tab_client: "Clients",
    events_details_tab_school_regi: "School Registration",
    events_details_tab_teams: "Teams or Classes",

    events_add_title: "Add Event",
    events_edit_title: "Edit Event",
    events_form_label_type: "Event Type",
    events_form_ph_type: "Select event type",
    events_form_type_option1: "School",
    events_form_type_option2: "Company",
    events_form_label_name: "Event Name",
    events_form_ph_name: "Enter event name",
    events_form_label_subdomain: "Sub-domain",
    events_form_ph_subdomain: "Enter sub-domain",
    events_form_label_app: "Event App",
    events_form_ph_app: "Select evant app",
    events_form_app_option1: "Virtual Adventure",
    events_form_app_option2: "Azion",
    events_form_label_distributor: "Distributor",
    events_form_ph_distributor: "Select distributor",
    events_form_label_coach: "Coach",
    events_form_ph_coach: "Select coach from the list",
    events_form_label_coach_mail: "Coach email",
    events_form_ph_coach_email: "Select coach email from the list",
    events_form_label_system_mail: "System email",
    events_form_ph_system_email: "Select system email from the list",
    events_form_label_active_event: "Active",
    events_form_label_entrants_num: "Target number of confirmed entrants",
    events_form_ph_entrants_num: "Enter target number of confirmed entrants",
    events_form_validation_min_entrants: "Invalid entrants number",
    events_form_label_site_start_date: "Site Open Date",
    events_form_ph_site_start_date: "Select event site open date",
    events_form_label_site_end_date: "Site Close Date",
    events_form_ph_site_end_date: "Select event site close date",
    events_form_data_entry_close_data: "Data entry close date",
    events_form_ph_data_entry_close_date: "Select data entry close date",
    events_form_label_reg_start_date: "Registration for New Classes Open Date",
    events_form_ph_reg_start_date:
      "Select registration for new classes open date",
    events_form_label_reg_end_date: "Registration for New Classes Close Date",
    events_form_ph_reg_end_date:
      "Select registration for new classes close date",
    events_form_label_invitation_expires_days: "Days until Invitation Expire",
    events_form_ph_invitation_expires_days:
      "Enter days until invitation expire",
    events_form_label_auto_reminder_days: "Auto Reminder Days",
    events_form_ph_auto_reminder_days:
      "Enter days to send reminder email to create class",
    events_form_label_withdrawn_interval: "Withdraw Team Days",
    events_form_ph_withdrawn_interval:
      "Enter days to withdraw team if not confirmed",
    events_form_label_indi_reg_cutoff_date:
      "Individual Registration Cutoff Date",
    events_form_ph_indi_reg_cutoff_date:
      "Select individual registration cutoff date",
    events_form_label_challenge_course_start_date: "Challenge Course Open Date",
    events_form_ph_challenge_course_start_date:
      "Select challenge course open date",
    events_form_label_challenge_course_end_date: "Challenge Course Close Date",
    events_form_ph_challenge_course_end_date:
      "Select challenge course close date",
    events_form_ph_challenge_course_open_period: "Challenge Course Period",
    events_form_validation_min_invitation_expires_days:
      "Invalid invitation expiry days",
    events_form_validation_min_auto_reminder_days: "Invalid auto reminder days",
    events_form_validation_min_withdrawn_interval: "Invalid team withdraw days",
    events_form_label_legs: "Number of Legs",
    events_form_ph_legs: "Enter the number of legs team can select",
    events_form_label_bonus_legs: "Number of Bonus Legs",
    events_form_ph_bonus_legs: "Enter the number of bonus legs team can select",
    events_form_label_divisions: "Divisions Active",
    events_form_label_divisions_open: "Open",
    events_form_label_divisions_advance: "Advanced",
    events_form_label_open_points_target: "Daily Points Target, Open",
    events_form_ph_open_points_target: "Enter daily open points target",
    events_form_label_open_maximum_points: "Daily Maximum, Open",
    events_form_ph_open_maximum_points: "Enter daily maximum points",
    events_form_label_open_meter_to_finish_all_legs:
      "Meters Required to Finish All Legs, Open",
    events_form_label_open_team_averege_points_for_each_leg:
      "Team Average Points Required to Finish Each Leg, Open",
    events_form_label_open_team_averege_points_for_all_leg:
      "Team Average Points Required to Finish All Leg, Open",
    events_form_label_open_travel_ratio_points:
      "Ratio of Points Entered to Meters Moved, Open",
    events_form_label_advance_points_target: "Daily Points Target, Advance",
    events_form_ph_advance_points_target: "Enter daily advance points target",
    events_form_label_advance_maximum_points: "Daily Maximum, Advance",
    events_form_ph_advance_maximum_points: "Enter daily maximum points",
    events_form_label_advance_meter_to_finish_all_legs:
      "Meters Required to Finish All Legs, Advance",
    events_form_label_advance_team_averege_points_for_each_leg:
      "Team Average Points Required to Finish Each Leg, Advance",
    events_form_label_advance_team_averege_points_for_all_leg:
      "Team Average Points Required to Finish All Leg, Advance",
    events_form_label_advance_travel_ratio_points:
      "Ratio of Points Entered to Meters Moved, Advance",
    events_form_validation_min_legs: "Invalid leg number",
    events_form_validation_min_bonuslegs: "Invalid bonus leg number",
    events_form_validation_edit_legs_not_less_last_save:
      "You can not reduce the total legs count.",
    events_form_validation_min_teamavgdailypointstargetopen:
      "Invalid team daily target point open",
    events_form_validation_min_dailymaxindividualopen:
      "Invalid individual daily target point open",
    events_form_validation_min_teamavgdailypointstargetadv:
      "Invalid team daily target point advance",
    events_form_validation_min_dailymaxindividualadv:
      "Invalid individual daily target point advance",
    events_form_tab_course: "Course Details",
    events_form_lable_leg_title: "Leg Title",
    events_form_ph_leg_title: "Enter leg title",
    events_form_lable_leg: "Leg",
    events_form_ph_leg: "Select leg",
    events_form_label_start_location: "Start Location",
    events_form_label_end_location: "End Location",
    events_form_lable_leg_animation: "Leg Animation",
    events_form_ph_leg_animation: "Select leg animation",
    events_form_lable_leg_details: "Leg Details",
    events_form_ph_leg_details: "Select leg details",
    events_form_text_leg_gallery: "Leg Gallery",
    events_form_label_leg_image_title: "Gallery Image Title",
    events_form_ph_leg_image_title: "Enter gallery image title",
    events_form_label_leg_image: "Gallery Image",
    events_form_ph_leg_image: "Select gallery image",
    events_form_validation_totalleg_mismatch:
      "Cteate total legs mentioned in previous step",

    events_form_tab_branding: "Branding Files",
    events_form_label_background: "Background",
    events_form_background_option1: "Image",
    events_form_background_option2: "Color",
    events_form_label_logo: "Upload Logo",
    events_form_label_login_background_image: "Upload Login Backgroung Image",

    events_form_tab_certificate: "Certificates",
    events_form_lable_team_certificate: "Team Certificate",
    events_form_ph_team_certificate: "Select team certificate",
    events_form_lable_individual_certificate: "Individual Certificate",
    events_form_ph_individual_certificate: "Select individual certificate",
    event_form_success_subdomain_available: "Sub-domain available",
    event_form_error_subdomain_not_available:
      "sub-domain name already exist. Please try another name.",
    events_form_saved_success: "Event saved successfully!",

    email_settings_coach_add_title: "Coaches",
    email_settings_coach_email_add_title: "Coach Emails",
    email_settings_system_email_add_title: "System Emails",
    email_settings_form_label_text: "Text",
    email_settings_form_ph_text: "Add new text",
    email_settings_form_validate_coach_already_exist: "Coach already exist",
    email_settings_form_validate_coach_email_already_exist:
      "Coach email already exist",
    email_settings_form_validate_system_email_already_exist:
      "System email already exist",

    email_settings_th_text: "Text (select to update)",

    events_url_title: "Event Urls",
    events_url_sub_title: "Here are your event links",
    events_url_text_portal: "Web Portal",
    events_url_text_registration: "Registration Portal",

    events_client_text_no_data: "No event client available",
    events_client_th_clientid: "Client Id",
    events_client_th_name: "Client Name",
    events_client_th_max_member: "Max Mem.",
    // events_client_th_max_member: "Max Members",
    events_client_th_max_member_reqd_each_class: "Mem. Reqd",
    // events_client_th_max_member_reqd_each_class: "Members Reqd",
    events_client_th_member_confirmed: "Confirmed",
    events_client_remove_subtitle: "Continue to remove?",
    events_client_alert_delete_success: "Event client deleted successfully!",

    events_client_add_title: "Add Client to Event",
    events_client_form_label_client: "Client",
    events_client_form_ph_client: "Select client from the list",
    events_client_form_label_timezone: "Timezone",
    events_client_form_ph_timezone: "Select timezone from the list",
    events_client_form_label_maxentrants: "Max #entrants",
    events_client_form_ph_maxentrants: "Enter max entrants number",
    events_client_form_label_coach: "Coach",
    events_client_form_ph_coach: "Select coach from the list",
    events_client_form_label_coach_mail: "Coach email",
    events_client_form_ph_coach_email: "Select coach email from the list",
    events_client_form_label_system_mail: "System email",
    events_client_form_ph_system_email: "Select system email from the list",
    events_client_form_label_prepopulatedlocation:
      "Pre-populated entrant locations",
    events_client_form_label_hideplacingfilter:
      "Hide placings filters industry and company",
    events_client_form_label_useprepopulatedbusiness:
      "Use pre-populated business units",
    events_client_form_label_hidestepcounterqst: "Hide step counter question",
    events_client_form_label_divisioncompulsary: "Make division compulsory",
    events_client_form_label_min_perclass: "Min Student",
    events_client_form_ph_min_perclass: "Enter minimum student per class",
    events_client_form_label_max_perclass: "Max Student",
    events_client_form_ph_max_perclass: "Enter maximum student per class",
    events_client_form_label_allowteammanager:
      "Allow team manager to enter data for entire team",
    events_client_form_validation_client_exist: "Client already added",
    events_client_form_validation_maxteammember_then_minteammember:
      "Max studnet can not be less than min student",
    events_client_form_add_success:
      "Client added successfully under current event!",
    events_client_form_update_success:
      "Client updated successfully under current event!",

    events_regi_request_text_no_data: "No new registration request available",
    events_regi_request_th_id: "Id",
    events_regi_request_th_eventid: "Event Id",
    events_regi_request_th_fname: "First Name",
    events_regi_request_th_lname: "Last Name",
    events_regi_request_th_email: "Email",
    events_regi_request_th_region: "Region",
    events_regi_request_th_school: "School",
    events_regi_request_th_entrants: "Entrants",
    events_regi_request_th_mobile: "Mobile",
    events_regi_request_th_year: "Year",
    events_regi_request_th_status: "Status",
    events_regi_request_assign_title: "Assign to Event Client",
    events_regi_request_form_label_client: "Event Client",
    events_regi_request_form_ph_client: "Search or select client",
    events_regi_request_form_add_success:
      "Interested user assigned successfully!",
    events_regi_request_email_form_title: "Update Email",
    events_regi_request_email_form_label_email: "Email",
    events_regi_request_email_form_ph_email: "Enter email",
    events_regi_request_email_form_updated_error:
      "Sorry, You cann't change the email as this user has already cretaed his/her class!",
    events_regi_request_email_form_updated_success:
      "Email updated successfully!",

    events_team_text_no_data: "No team or class available",
    events_team_th_id: "Id",
    events_team_th_client_name: "Client",
    events_team_th_name: "Team",
    events_team_th_teacher: "Teacher",
    events_team_th_teacher_email: "Teacher Email",
    events_team_th_size: "Size",
    events_team_th_points: "Avg. Points",
    events_team_th_confirmed_Date: "Confirmed",
    events_team_th_withdrawn_Date: "Withdrawn",
    events_team_th_action: "Action",

    events_team_export_th_client_name: "Client Name",
    events_team_export_th_team_name: "Team Name",
    events_team_export_th_team_size: "Team Size",
    events_team_export_th_entrantid: "Entrant Id",
    events_team_export_th_teamid: "Team Id",
    events_team_export_th_fname: "First Name",
    events_team_export_th_lname: "Last Name",
    events_team_export_th_email: "Email",
    events_team_export_th_mobile: "Mobile",
    events_team_export_th_consent: "May we contact you?",
    events_team_export_th_accepted_terms: "Accepted Terms",
    events_team_export_th_address: "Address",
    events_team_export_th_suburb: "Suburb",
    events_team_export_th_region: "Region",
    events_team_export_th_city: "City",
    events_team_export_th_postal: "Postal Code",

    events_team_view_title: "Team or Class Details",
    events_team_view_text_id: "Id",
    events_team_view_text_client: "Client",
    events_team_view_text_team: "Team",
    events_team_view_text_size: "Size",
    events_team_view_text_steps: "Steps",
    events_team_view_text_confirmed: "Confirmed",
    events_team_view_text_withdrawn: "Withdrawn",
    events_team_view_text_registered: "Registered",
    events_team_view_text_created: "Created",
    events_team_view_text_finished: "Finished",

    events_team_edit_title: "Edit Team or Class",
    events_team_form_label_teamname: "Team Name",
    events_team_form_ph_teamname: "Enter team name",
    events_team_form_label_roomno: "Class Name or Room Number",
    events_team_form_ph_roomno: "Enter room number",
    events_team_form_label_school: "School Name",
    events_team_form_ph_school: "Enter school name",
    events_team_form_label_address: "School Address",
    events_team_form_ph_address: "Enter school address",
    events_team_form_label_suburb: "School Subrub",
    events_team_form_ph_suburb: "Enter school subrub",
    events_team_form_label_city: "School City",
    events_team_form_ph_city: "Enter school city",
    events_team_form_label_postalcode: "School Post Code",
    events_team_form_ph_postalcode: "Enter school post code",
    events_team_form_label_region: "Region",
    events_team_form_ph_region: "Enter region",
    events_team_form_updated_success: "Team updated successfully!",

    events_team_withdraw_confirm_title: "Withdraw Team",
    events_team_withdraw_confirm_subtitle:
      "Are you sure you want to withdraw team",
    events_team_withdraw_confirm_warning:
      "Please note selecting the option will withdraw your whole team and your current data will be lost.",
    events_team_withdraw_alert_success: "Team withdrawn successfully!",
    events_team_csv_download_started:
      "Preparing your report. CSV download started.",
    events_team_csv_download_alert_success: "CSV downloaded successfully!",

    events_teammember_text_no_data: "No team member available",
    events_teammember_th_id: "Entrant Id",
    events_teammember_th_fname: "First Name",
    events_teammember_th_lname: "Last Name",
    events_teammember_th_uname: "Username",

    events_teammember_text_team_size_full: "Team Full",
    events_teammember_view_title: "Entrant Details",
    events_teammember_view_text_id: "Entrant Id",
    events_teammember_view_text_teamid: "Team Id",
    events_teammember_view_text_fname: "First Name",
    events_teammember_view_text_lname: "Last Name",
    events_teammember_view_text_uname: "Username",
    events_teammember_view_text_password: "Password",
    events_teammember_view_text_manager: "Manager",
    events_teammember_view_text_last_web_login: "Last login web",
    events_teammember_view_text_last_app_login: "Last login app",
    events_teammember_view_text_withdrawn: "Withdrawn",
    events_teammember_view_text_invite_expired: "Invite expired",
    events_teammember_view_text_invite_declined: "Declined",
    events_teammember_view_text_created: "Created",
    events_teammember_view_text_virtual: "Virtual",
    events_teammember_view_text_removed: "Removed",
    events_teammember_view_text_started: "Started",
    events_teammember_view_text_finished: "Finished",

    events_teammember_add_title: "Add Entrant",
    events_teammember_edit_title: "Edit Entrant",
    events_teammember_edit_section_change_name: "Change Name",
    events_teammember_add_section_change_name: "Set Name",
    events_teammember_form_label_uname: "Username",
    events_teammember_form_ph_uname: "Enter username",
    events_teammember_edit_username_title: "Edit Username",
    events_teammember_edit_username_subtitle:
      "Are you sure you want to edit username",
    events_teammember_form_label_fname: "First Name",
    events_teammember_form_ph_fname: "Enter first name",
    events_teammember_form_label_lname: "Last Initial",
    events_teammember_form_ph_lname: "Enter last initial",
    events_teammember_form_label_gender: "Gender",
    events_teammember_form_ph_gender: "Select gender",
    events_teammember_edit_section_set_password: "Set Password",
    events_teammember_add_section_set_password: "Set Password",
    events_teammember_form_label_password: "Password",
    events_teammember_form_validation_lastname_max_length:
      "Last initial name shoud be 1 char.",
    events_teammember_form_validation_team_full:
      "Required no. of entrants is reached, No speace available.",
    events_teammember_form_create_success: "Team member created successfully!",
    events_teammember_form_updated_error_email_taken:
      "Email already used by another user!",
    events_teammember_form_updated_success: "Team member updated successfully!",

    events_teammember_withdraw_confirm_title: "Withdraw Team Member",
    events_teammember_withdraw_confirm_subtitle:
      "Are you sure you want to withdraw",
    events_teammember_withdraw_alert_success: "Member withdrawn successfully!",

    // Clients
    clients_list_header: "Client List",
    clients_text_no_data: "No client available",
    clients_th_clientid: "Client Id",
    clients_th_client_name: "Client Name",
    clients_th_display_name: "Display Name",
    clients_th_client_type: "Client Type",
    clients_th_admin_name: "Admin Name",
    clients_th_admin_password: "Password",
    clients_activated_subtitle: "Continue to activate?",
    clients_deactivated_subtitle: "Continue to deactivate?",
    clients_alert_event_activated: "Client activated successfully!",
    clients_alert_event_deactivated: "Client deactivated successfully!",
    clients_tabs_active: "Active Clients",
    clients_tabs_inactive: "Inactive Clients",
    filter_by: "Filter",
    add_client: "Add Client",
    edit_client: "Update Client",
    type_of_client: "Type of Client",
    client_form_label_client: "Select Client",
    client_form_ph_client: "Select client fom list",
    display_name_client: "Display Name",
    admin_name_client: "Client Email",
    client_password: "Password",
    clients_add_success: "Client added successfully!",
    clients_email_exist: "Client email already exist!",
    clients_update_success: "Client updated successfully!",
    clients_school_info: "Schools Info",

    // Settings
    settings: "Settings",
    setting_form_label_default_stride_length: "Default Stride Length",
    setting_form_ph_default_stride_length: "Default Stride Length",
    setting_form_label_max_steps_open_div: "Max Steps. Open Division",
    setting_form_ph_max_steps_open_div: "Max Steps. Open Division",
    setting_form_label_max_steps_adv_div: "Max Steps. Advance Division",
    setting_form_ph_max_steps_adv_div: "Max Steps. Advance Division",
    setting_form_label_smtp: "SMTP",
    setting_form_ph_smtp: "SMTP",
    setting_form_label_from: "From",
    setting_form_ph_from: "From",
    setting_form_label_default_leg_open_division:
      "Default Leg length(m) Open Division",
    setting_form_label_default_leg_advanced_division:
      "Default Leg length(m) Advanced Division",

    email_settings: "Email Settings",
    default_leg: "Default Leg",

    // Activity
    activity: "Activity",
    add_new_activity: "Add New Activity",
    edit_activity: "Edit Activity",
    activity_add_success: "Activity added successfully!",
    activity_del_success: "Activity deleted successfully!",
    activity_upd_success: "Activity updated successfully!",
    activity_form_ph_activity_name: "Activity Name",
    activity_form_ph_low: "Low",
    activity_form_ph_medium: "Medium",
    activity_form_ph_high: "High",
    activity_form_ph_type_of_event: "Type of Event",
    activity_form_label_type_of_event: "Type of Event",
    activity_form_label_select_a_type: "Select a type",
    th_activity: "Activity",
    th_low: "Low",
    th_medium: "Medium",
    th_high: "High",
    no_record_activity: "No activity to display.",

    // Bonus Points
    bonus_points: "Bonus Points",
    bonus_type: "Bonus Type",
    bonus_points_data: "Bonus Points Data",
    start_time: "Start Time",
    end_time: "End Time",
    points: "Points",
    quantity: "Quantity",
    teaspoon: "Teaspoon",
    bonuspoints_error: "Bonus Points Data Required",
    bonuspoints_add_success: "Bonus Points added successfully",
    bonuspoints_delete_success: "Bonus Points deleted successfully",
    bonuspoints_delete_title: "Bonus Points Delete",
    bonuspoints_delete_messgae:
      "Are you sure want to delete this? this will delete permanently.",

    // FAQ
    faq: "FAQ",
    edit_faq: "Edit FAQ",
    faq_question: "Question",
    faq_answer: "Answer",
    faq_add_success: "FAQ added successfully",
    faq_update_success: "FAQ updated successfully",
    faq_delete_success: "FAQ deleted successfully",
    question_answer_error: "Question/Answer Required",

    // Region
    tab_name_region: "Region",
    no_region_available: "No region available to display",
    region_th_name: "Region Name",
    region_form_label_name: "Region Name",
    region_form_ph_name: "Enter region name",
    region_form_add_success: "Region added successfully!",
    region_form_update_success: "Region updated successfully!",
    region_delete_confirm_title: "Delete Region",
    region_delete_confirm_subtitle: "Are you sure you want to delete region?",
    region_delete_success: "Region deleted successfully!",

    // Email Template
    email_template: "Email Template",
    email_template_subject: "Subject",
    email_template_gurdain: "Gurdain",
    email_template_school: "School",
    email_template_team: "Team",
    email_template_active_deactive: "Active/Deactive",
    email_template_add_success: "Email Template added successfully",
    email_template_update_success: "Email Template updated successfully",
    email_template_delete_success: "Email Template deleted successfully",
    email_template_activate: "Email Template activate successfully",
    email_template_deactivate: "Email Template deactivate successfully",
    email_template_delete_title: "Email Template Delete",
    email_template_delete_messgae: "Are you sure want to delete this template?",
    no_record_email_template: "No email template found.",

    // Hotzones
    hotzone_refreshing_leg_details: "Refreshing leg list...",
    hotzone_legmaps_tabpane: "Leg Details",
    hotzone_leg_choose_leg: "Choose course leg",
    hotzone_list_heading: "Available hotzones",
    hotzone_name: "Hotzone Name",
    hotzone_name_ph: "Enter a suitable name",
    hotzone_active: "Active Hotzone",
    hotzone_details: "Hotzone Details",
    hotzone_images: "Leg Gallery Images",
    hotzone_updated: "Successfully updated hotzone details!",
  },
  it: {
    dashboard: "Dashboard",
  },
});

export default LocaleStrings;
