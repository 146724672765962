import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Container,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import classnames from "classnames";
import {
  fetchClients,
  searchClients,
  emptyClient,
  openClientModal,
  refreshCleintInfo,
} from "./actions";
import Header from "../../components/Headers/Header";
import { itemCount } from "../Common/constant";
import AddClient from "./components/add-client";
import RenderList from "./components/list-item";
import Pagination from "../Common/pagination";
import Loader from "../Common/loader";
import LocaleStrings from "../../languages";
import { showError, showSuccess } from "../Common/alertmessage";
import { CommonGoBack } from "components/Common/base-components";

const Clients = (props) => {
  var { session, deviceSize, ClientList, addClientModal } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("active");
  const [filter, setFilter] = useState("");
  const [refreshLoading, setRefreshLoading] = useState(false);

  const tabArr = [
    { key: "active", text: LocaleStrings.clients_tabs_active },
    { key: "inactive", text: LocaleStrings.clients_tabs_inactive },
  ];

  const eventTypeArr = [
    { text: LocaleStrings.events_form_type_option1, value: "school" },
    { text: LocaleStrings.events_form_type_option2, value: "company" },
  ];

  useEffect(() => {
    props.fetchClients(session, pageNumber, 1);
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      let pageNumber = 1;
      setActiveTab(tab);
      setPageNumber(pageNumber);
      props.emptyClient();

      if (search === "") {
        props.fetchClients(
          session,
          pageNumber,
          tab == "active" ? 1 : 0,
          filter,
          (response) => {}
        );
      } else {
        props.searchClients(
          session,
          search,
          pageNumber,
          tab == "active" ? 1 : 0,
          filter,
          (response) => {}
        );
      }
    }
  };

  const openModal = () => {
    props.openClientModal({ showModal: true });
  };

  const handleRefreshClients = () => {
    setRefreshLoading(true);
    props.refreshCleintInfo(session, (res) => {
      setRefreshLoading(false);
      if (res?.success) {
        showSuccess(
          `${LocaleStrings.common_success_updated} ${LocaleStrings.clients_school_info}`
        );

        props.fetchClients(session, pageNumber, 1);
      } else showError(LocaleStrings.common_fail_message);
    });
  };

  const filterBy = (e) => {
    var sort = e.target.value;
    let pageNumber = 1;
    setFilter(sort);
    setPageNumber(pageNumber);

    if (search === "") {
      props.fetchClients(
        session,
        pageNumber,
        activeTab == "active" ? 1 : 0,
        sort,
        (response) => {}
      );
    } else {
      props.searchClients(
        session,
        search,
        pageNumber,
        activeTab == "active" ? 1 : 0,
        sort,
        (response) => {}
      );
    }
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNumber = 1;

    if (search === "") {
      props.fetchClients(
        session,
        pageNumber,
        activeTab == "active" ? 1 : 0,
        filter,
        (response) => {}
      );
    } else {
      props.searchClients(
        session,
        search,
        pageNumber,
        activeTab == "active" ? 1 : 0,
        filter,
        (response) => {}
      );
    }

    setSearch(search);
    setPageNumber(pageNumber);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchClients(
        session,
        pageNum,
        activeTab == "active" ? 1 : 0,
        filter,
        (response) => {}
      );
    } else {
      props.searchClients(
        session,
        search,
        pageNum,
        activeTab == "active" ? 1 : 0,
        filter,
        (response) => {}
      );
    }

    setPageNumber(pageNum);
  };

  const renderList = () => {
    let pageDataLength = ClientList?.data ? ClientList?.data?.length : 0;

    return _.map(ClientList.data, (item, index) => {
      return (
        <RenderList
          key={`key_${index}`}
          printList={item}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
          pagination={paginationCallback}
          activeTab={activeTab}
        />
      );
    });
  };

  let eventOptions = [];
  eventOptions.push(<option value="">All Type</option>);
  _.map(eventTypeArr, (item, index) => {
    eventOptions.push(
      <option key={`key_type_${index.toString()}`} value={item.value}>
        {item.text}
      </option>
    );
  });
  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--6" fluid>
        <CommonGoBack />
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="border-0">
                <Row>
                  <Col></Col>
                  <Col>
                    <Nav className="border-bottom-0" tabs>
                      {tabArr.map((tab, tabIdx) => (
                        <NavItem key={`_tab_key_${tabIdx}`} className="p-3">
                          <NavLink
                            className={classnames({
                              active: activeTab === tab.key,
                            })}
                            onClick={() => {
                              toggle(tab.key);
                            }}
                          >
                            {tab.text}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end">
                    <Button
                      disabled={refreshLoading}
                      className="buttons-option"
                      color="primary"
                      size="md"
                      onClick={handleRefreshClients}
                    >
                      {refreshLoading ? (
                        <>
                          <i
                            className={
                              refreshLoading ? `fa fa-refresh fa-spin` : ""
                            }
                          ></i>
                          <span>{LocaleStrings.button_loading}</span>
                        </>
                      ) : (
                        <span>
                          {LocaleStrings.button_refresh}{" "}
                          {LocaleStrings.clients_school_info}
                        </span>
                      )}
                    </Button>

                    <Button
                      className="buttons-option"
                      color="primary"
                      size="md"
                      onClick={openModal}
                    >
                      {LocaleStrings.add_client}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardHeader className="border-0">
                <div className="pull-left">
                  <div className="form-group row ">
                    <label className="control-label-top col-sm-12">
                      {LocaleStrings.filter_by}
                    </label>
                    <div className="col-sm-12 col-sm-offset-0">
                      <select
                        className="form-control input-field"
                        name="filterby"
                        onChange={(e) => filterBy(e)}
                      >
                        {eventOptions}
                      </select>
                      <div className="text-help label-text-help"></div>
                    </div>
                  </div>
                </div>
                <div className="pull-right d-flex align-items-center">
                  <input
                    className="form-control inputsearch"
                    placeholder={LocaleStrings.search}
                    type="search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </CardHeader>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="active">
                  <div style={{ height: deviceSize.height }}>
                    {ClientList?.data ? (
                      <>
                        {ClientList?.count > 0 ? (
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">
                                  {LocaleStrings.clients_th_clientid}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_client_name}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_display_name}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_client_type}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.admin_name_client}
                                </th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>{renderList()}</tbody>
                          </Table>
                        ) : (
                          <div className="no-content-message">
                            <div>
                              <div className="no-content-text">
                                {LocaleStrings.clients_text_no_data}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </TabPane>

                <TabPane tabId="inactive">
                  <div style={{ height: deviceSize.height }}>
                    {ClientList?.data ? (
                      <>
                        {ClientList?.count > 0 ? (
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">
                                  {LocaleStrings.clients_th_clientid}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_client_name}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_client_type}
                                </th>
                                <th scope="col">
                                  {LocaleStrings.clients_th_admin_name}
                                </th>
                                {/* <th scope="col">
                                  {LocaleStrings.clients_th_admin_password}
                                </th> */}
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>{renderList()}</tbody>
                          </Table>
                        ) : (
                          <div className="no-content-message">
                            <div>
                              <div className="no-content-text">
                                {LocaleStrings.clients_text_no_data}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </TabPane>
              </TabContent>

              {ClientList?.count > itemCount ? (
                <CardFooter className="">
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={ClientList?.count}
                    pageRangeDisplayed={3}
                    onChange={paginationCallback}
                  />
                </CardFooter>
              ) : (
                ""
              )}
            </Card>
          </div>
        </Row>
      </Container>

      {addClientModal?.showModal ? (
        <AddClient
          activeTab={activeTab}
          finishOperationsCallback={additionCallback}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log("state.ClientList :- ", state.ClientList);

  return {
    session: state.session,
    deviceSize: state.deviceSize,
    ClientList: state.ClientList,
    addClientModal: state.addClientModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClients,
      searchClients,
      emptyClient,
      openClientModal,
      refreshCleintInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
