import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import {
  openAddEventClientModal,
  updateTeam,
  fetchEventTeams,
  addEventClient,
  updateEventClient,
} from "../../actions";
import { openEmailRelatedModal } from "../../../../actions";
import {
  renderFieldText,
  renderSelect,
  renderSelectWithButton,
  renderFieldCheckbox,
  validateOnlyNums,
} from "../../../Common/base-components";
import {
  LABEL_POSITION_TOP,
  LABEL_POSITION_LEFT,
  COMMON_FAIL_MESSAGE,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";
import { addClientModal } from "components/clients/reducers";

const AddEventClient = (props) => {
  var {
    session,
    AddEventClientModal,
    eventDetails,
    AllActiveClientList,
    AllActiveSchoolClientList,
    AllActiveCompanyClientList,
    timezoneList,
    coachList,
    coachEmails,
    systemEmails,
    toEdit,

    handleSubmit,
    pristine,
    invalid,
    submitting,
  } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.openAddEventClientModal({ showModal: false });
  };

  const onSubmitForm = (values) => {
    if (toEdit) {
      delete values["clients_by_clientidfk"]; // Removing unwanted elememts which is not required to update

      values.clientidfk = AddEventClientModal?.value?.clientidfk;

      setLoading(true);
      props.updateEventClient(session, values, (response) => {
        // console.log('response',response);
        setLoading(false);
        if (response.success === 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else {
          showSuccess(LocaleStrings.events_client_form_update_success);
          props.finishOperationsCallback();
          closeModal();
        }
      });
    } else {
      if (eventDetails?.type === "school") {
        var eventclientlegs = [];
        _.map(eventDetails?.eventleg_by_eventidfk, (item, index) => {
          var clleg = {
            eventidfk: item.eventidfk,
            clientidfk: values.clientidfk,
            eventlegidfk: item.eventlegid,
            legidfk: item.materlegidfk,
            type: item.type,
            order: index + 1,
          };
          eventclientlegs.push(clleg);
        });
        values.eventclientlegs_by_eventclientidfk = eventclientlegs; // eventclientlegs_by_eventclientidfk
      }
      values.eventidfk = eventDetails?.eventid;
      // console.log("final values : - ", values);
      setLoading(true);
      props.addEventClient(session, values, (response) => {
        // console.log('response',response);
        setLoading(false);
        if (response.success === 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else {
          showSuccess(LocaleStrings.events_client_form_add_success);
          props.finishOperationsCallback();
          closeModal();
        }
      });
    }
  };

  const onManageEmailRelated = (type) => {
    // console.log("type :- ", type);

    props.openEmailRelatedModal({ showModal: true, type: type });
  };

  var clientArr = [];
  var clientsData =
    eventDetails?.type === "school"
      ? AllActiveSchoolClientList?.data
      : eventDetails?.type === "company"
      ? AllActiveCompanyClientList?.data
      : AllActiveClientList?.data;

  var eventClients = _.map(
    eventDetails?.eventclient_by_eventidfk,
    "clientidfk"
  );

  _.map(clientsData, (item) => {
    if (
      _.filter(eventClients, (o) => {
        return o == item.clientid;
      }) == 0 &&
      toEdit === false
    ) {
      var obj = { text: item.name, value: item.clientid };
      clientArr.push(obj);
    } else {
      var obj = { text: item.name, value: item.clientid };
      clientArr.push(obj);
    }
  });

  var timezoneArr = [];
  _.map(timezoneList?.data, (item) => {
    var obj = { text: item.name, value: item.timezonesid };
    timezoneArr.push(obj);
  });

  var coachArr = [];
  _.map(coachList?.data, (item) => {
    var obj = { text: item.name, value: item.coachid };
    coachArr.push(obj);
  });

  var coachEmailsArr = [];
  _.map(coachEmails?.data, (item) => {
    var obj = { text: item.email, value: item.coachemailid };
    coachEmailsArr.push(obj);
  });

  var syatemEmailsArr = [];
  _.map(systemEmails?.data, (item) => {
    var obj = { text: item.email, value: item.systememailid };
    syatemEmailsArr.push(obj);
  });

  return (
    <>
      <Modal
        className=""
        isOpen={AddEventClientModal?.showModal ? true : false}
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_client_add_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <Field
              name="clientidfk"
              label={LocaleStrings.events_client_form_label_client}
              placeholder={LocaleStrings.events_client_form_ph_client}
              mandatory="true"
              component={renderSelect}
              opts={clientArr}
              labelposition={LABEL_POSITION_TOP}
              disabled={toEdit}
            />
            {/* <Field
              name="timezoneidfk"
              label={LocaleStrings.events_client_form_label_timezone}
              placeholder={LocaleStrings.events_client_form_ph_timezone}
              mandatory="true"
              component={renderSelect}
              opts={timezoneArr}
              labelposition={LABEL_POSITION_TOP}
            /> */}
            <Field
              name="totalmembers"
              label={LocaleStrings.events_client_form_label_maxentrants}
              placeholder={LocaleStrings.events_client_form_ph_maxentrants}
              type="number"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="coachidfk"
              label={LocaleStrings.events_client_form_label_coach}
              placeholder={LocaleStrings.events_client_form_ph_coach}
              mandatory="false"
              opts={coachArr}
              component={renderSelectWithButton}
              onManageClick={() => onManageEmailRelated("coach")}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="coachemailidfk"
              label={LocaleStrings.events_client_form_label_coach_mail}
              placeholder={LocaleStrings.events_client_form_ph_coach_email}
              mandatory="false"
              opts={coachEmailsArr}
              component={renderSelectWithButton}
              onManageClick={() => onManageEmailRelated("coachemail")}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="systememailidfk"
              label={LocaleStrings.events_client_form_label_system_mail}
              placeholder={LocaleStrings.events_client_form_ph_system_email}
              mandatory="false"
              opts={syatemEmailsArr}
              component={renderSelectWithButton}
              onManageClick={() => onManageEmailRelated("systememail")}
              labelposition={LABEL_POSITION_TOP}
            />

            <hr />
            <Field
              name="minteammember"
              label={LocaleStrings.events_client_form_label_min_perclass}
              placeholder={LocaleStrings.events_client_form_ph_min_perclass}
              type="number"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_LEFT}
            />
            <Field
              name="maxteammember"
              label={LocaleStrings.events_client_form_label_max_perclass}
              placeholder={LocaleStrings.events_client_form_ph_max_perclass}
              type="number"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_LEFT}
            />
            <Field
              name="isprepopulatedentrantlocation"
              type="checkbox"
              label={
                LocaleStrings.events_client_form_label_prepopulatedlocation
              }
              component={renderFieldCheckbox}
            />
            <Field
              name="ishideplacingfilter"
              type="checkbox"
              label={LocaleStrings.events_client_form_label_hideplacingfilter}
              component={renderFieldCheckbox}
            />
            <Field
              name="isuseprepopulatedbusiness"
              type="checkbox"
              label={
                LocaleStrings.events_client_form_label_useprepopulatedbusiness
              }
              component={renderFieldCheckbox}
            />
            <Field
              name="ishidestepcounterqst"
              type="checkbox"
              label={LocaleStrings.events_client_form_label_hidestepcounterqst}
              component={renderFieldCheckbox}
            />
            <Field
              name="isdivisioncompulsary"
              type="checkbox"
              label={LocaleStrings.events_client_form_label_divisioncompulsary}
              component={renderFieldCheckbox}
            />
            <Field
              name="isallowteammanager"
              type="checkbox"
              label={LocaleStrings.events_client_form_label_allowteammanager}
              component={renderFieldCheckbox}
            />
          </div>

          <div className="modal-footer">
            <Button color="secondary" onClick={closeModal}>
              {LocaleStrings.button_close}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={pristine || invalid || submitting || loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ""}{" "}
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  var clientidfk = values["clientidfk"];
  var timezoneidfk = values["timezoneidfk"];
  var totalmembers = values["totalmembers"];
  var minteammember = values["minteammember"];
  var maxteammember = values["maxteammember"];

  var eventClients = _.map(
    ownProps?.eventDetails?.eventclient_by_eventidfk,
    "clientidfk"
  );

  if (!clientidfk) {
    errors["clientidfk"] = LocaleStrings.requird;
  } else if (
    clientidfk &&
    _.filter(eventClients, (o) => {
      return o == clientidfk;
    }) > 0 &&
    ownProps?.toEdit === false
  ) {
    errors["clientidfk"] =
      LocaleStrings.events_client_form_validation_client_exist;
  }
  if (!timezoneidfk) {
    errors["timezoneidfk"] = LocaleStrings.requird;
  }
  if (!totalmembers) {
    errors["totalmembers"] = LocaleStrings.requird;
  }
  if (!minteammember) {
    errors["minteammember"] = LocaleStrings.requird;
  } else if (
    minteammember &&
    (!validateOnlyNums(minteammember) || minteammember < 1)
  ) {
    errors["minteammember"] = LocaleStrings.invalid_number;
  }
  if (!maxteammember) {
    errors["maxteammember"] = LocaleStrings.requird;
  } else if (
    maxteammember &&
    (!validateOnlyNums(maxteammember) || maxteammember < 1)
  ) {
    errors["maxteammember"] = LocaleStrings.invalid_number;
  }
  if (
    minteammember &&
    maxteammember &&
    parseInt(minteammember) > parseInt(maxteammember)
  ) {
    errors["maxteammember"] =
      LocaleStrings.events_client_form_validation_maxteammember_then_minteammember;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.AddEventClientModal?.value);
  var initVals = {
    isfixedcourses: 1,
    isprepopulatedentrantlocation: 1,
    isuseprepopulatedbusiness: 1,
    isdivisioncompulsary: 1,
    isallowteammanager: 1,
  };

  if (toEdit) {
    initVals = state?.AddEventClientModal?.value;
  }

  return {
    session: state.session,
    AddEventClientModal: state.AddEventClientModal,
    initialValues: initVals,
    toEdit,
    AllActiveClientList: state.AllActiveClientList,
    AllActiveSchoolClientList: state.AllActiveSchoolClientList,
    AllActiveCompanyClientList: state.AllActiveCompanyClientList,
    timezoneList: state.timezoneList,
    coachList: state.coachList,
    coachEmails: state.coachEmails,
    systemEmails: state.systemEmails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openAddEventClientModal,
      updateTeam,
      fetchEventTeams,
      addEventClient,
      updateEventClient,
      openEmailRelatedModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EventClientForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddEventClient)
);
