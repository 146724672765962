import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import _ from "lodash";
import DataTable from "react-data-table-component";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

// constants
import { showSuccess, showError } from "../../../Common/alertmessage";
import { COMMON_FAIL_MESSAGE } from "../../../Common/constant";
import Loader from "../../../Common/loader";
import LocaleStrings from "../../../../languages";

// actions
import {
  fetchEventClients,
  searchEventClients,
  openAddEventClientModal,
  deleteEventClient,
  selectedClient,
  clearSelectedClient,
  clearEventClients,
} from "../../actions";
import {
  fetchAllActiveClients,
  fetchAllActiveSchoolClients,
  fetchAllActiveCompanyClients,
} from "../../../clients/actions";

// components
import RenderList from "./list-item";
import AddEventClient from "./event-client-add";

const EventClients = (props) => {
  const {
    session,
    eventid,
    SingleEventClients,
    AddEventClientModal,
    eventDetails,
    selectedClient,
    clearSelectedClient,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.clearEventClients();
    props.fetchEventClients(session, eventid);
  }, []);

  var tempData = [];
  tempData =
    SingleEventClients?.data && SingleEventClients?.data.length > 0
      ? SingleEventClients.data
      : [];

  if (!_.isEmpty(tempData)) {
    let myList = [],
      singleDetails = {};

    _.map(tempData, (singleEvCl, key) => {
      singleDetails = {
        id: key,
        eventclientid: singleEvCl.id,
        fullDetails: singleEvCl,
        client_id: singleEvCl.clientidfk,
        client_name: singleEvCl.clients_by_clientidfk.name,
        max_member: singleEvCl.totalmembers,
        member_reqd: singleEvCl.maxteammember,
        confirmed: singleEvCl.confirmedmembers,
        options: "",
      };
      myList.push(singleDetails);
    });

    var data = myList;
  }

  let filteredData = !_.isEmpty(data)
    ? data.filter((item) => {
        return (
          item?.client_name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
      })
    : [];

  const columns = [
    {
      name: LocaleStrings.events_client_th_clientid,
      selector: (row) => row.client_id,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_client_th_name,
      selector: (row) => row.client_name,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: LocaleStrings.events_client_th_max_member,
      selector: (row) => row.max_member,
    },
    {
      name: LocaleStrings.events_client_th_max_member_reqd_each_class,
      selector: (row) => row.member_reqd,
    },
    {
      name: LocaleStrings.events_client_th_member_confirmed,
      selector: (row) => row.confirmed,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => handleOptions(e)}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem onClick={() => openAddEventClient("edit", row)}>
              {LocaleStrings.button_edit}
            </DropdownItem>
            <DropdownItem onClick={() => handleClientRemove(row)}>
              {LocaleStrings.button_remove}
            </DropdownItem>
            <DropdownItem onClick={() => handleShowTeams(row)}>
              {LocaleStrings.button_show_teams}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const handleOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClientRemove = (row) => {
    let values = {
      id: row.eventclientid,
      isdeleted: 1,
    };

    confirmAlert({
      title: LocaleStrings.button_remove,
      message: LocaleStrings.events_client_remove_subtitle,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.deleteEventClient(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                showSuccess(LocaleStrings.events_client_alert_delete_success);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const handleShowTeams = (row) => {
    clearSelectedClient({}); // To clear the team first, if redux have any
    selectedClient(row.fullDetails);

    setTimeout(() => {
      history.push({
        pathname: `/admin/client-team/${row.fullDetails?.eventidfk}/${row.fullDetails?.clientidfk}`,
      });
    }, 500);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      props.fetchEventClients(session, eventid);
    } else {
      props.searchEventClients(session, search, eventid);
    }

    setPageNumber(pageNum);
  };

  const openAddEventClient = (action, data = {}) => {
    if (eventDetails?.type === "school") {
      props.fetchAllActiveSchoolClients(session);
    } else if (eventDetails?.type === "company") {
      props.fetchAllActiveCompanyClients(session);
    } else {
      props.fetchAllActiveClients(session);
    }
    props.openAddEventClientModal({
      showModal: true,
      ...(action === "edit" ? { value: data?.fullDetails } : {}),
    });
  };

  return (
    <>
      {SingleEventClients?.data ? (
        <>
          <div className="custom-header pb-3 d-flex justify-content-end">
            <ul className="header-buttons">
              <li className="buttons-option">
                <input
                  className="form-control inputsearch buttons-option"
                  placeholder={LocaleStrings.search}
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </li>
              <li className="buttons-option">
                <Button
                  className="float-right"
                  color="primary"
                  size="md"
                  onClick={() => openAddEventClient("add")}
                >
                  {LocaleStrings.button_add_client}
                </Button>
              </li>
            </ul>
          </div>
          {SingleEventClients?.count > 0 ? (
            <>
              <DataTable
                className="common-data-table"
                columns={columns}
                data={filteredData}
                defaultSortField="client_name"
                striped
                pagination
              />
            </>
          ) : (
            <div className="no-content-message">
              <div>
                <div className="no-content-text">
                  {LocaleStrings.events_client_text_no_data}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      {AddEventClientModal?.showModal ? (
        <AddEventClient
          eventDetails={eventDetails}
          finishOperationsCallback={additionCallback}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    SingleEventClients: state.SingleEventClients,
    AddEventClientModal: state.AddEventClientModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEventClients,
      searchEventClients,
      openAddEventClientModal,
      fetchAllActiveClients,
      fetchAllActiveSchoolClients,
      fetchAllActiveCompanyClients,
      deleteEventClient,
      selectedClient,
      clearSelectedClient,
      clearEventClients,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventClients);
