import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  openAddTeamMemberModal,
  updateTeamMember,
  createTeamMember,
  fetchTeamClientEntrantCount,
  fetchSelectedTeam,
} from "../../../actions";
import {
  renderFieldText,
  renderSelect,
  renderHiddenFieldText,
  validateEmail,
} from "../../../../Common/base-components";
import {
  LABEL_POSITION_TOP,
  COMMON_FAIL_MESSAGE,
  DEFAULT_PASSWORD,
} from "../../../../Common/constant";
import { showSuccess, showError } from "../../../../Common/alertmessage";
import LocaleStrings from "../../../../../languages";

const AddTeamMember = (props) => {
  const {
    session,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    AddTeamMembers,
    toEdit,
    initialValues,
    teamid,
    SelectedTeam,
  } = props;
  const [password, setPassword] = useState(DEFAULT_PASSWORD);
  const [updatedUsername, setUpdatedUsername] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNewPW, setShowNewPW] = useState(false);

  useEffect(() => {
    if (toEdit) {
      setPassword(initialValues?.password);
    }
  }, [toEdit]);

  const closeModal = () => {
    props.openAddTeamMemberModal({ showModal: false });
    setShowNewPW(true);
  };

  const handlePassword = () => {
    const min = 100000;
    const max = 999999;
    const random = Math.floor(min + Math.random() * (max - min));
    var password = random;

    props.autofill("setpassword", password);
    setPassword(password);
    setShowNewPW(true);
  };

  const handleUsername = () => {
    confirmAlert({
      title: LocaleStrings.events_teammember_edit_username_title,
      message: `${LocaleStrings.events_teammember_edit_username_subtitle} ?`,
      buttons: [
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            setUpdatedUsername(true);
          },
        },
      ],
    });
  };

  const onSubmitForm = (values) => {
    setLoading(true);
    if (toEdit) {
      // Update Team Member
      var data = {
        id: values.id,
        dfid: values.dfid,
        firstname: values.firstname,
        lastname: values.lastname,
        gender: values.gender,
        ismanager: values.ismanager,
        ...(values?.setpassword
          ? {
              setpassword: values.setpassword,
              old_password: values.password,
              password: values.setpassword
                ? values.setpassword
                : values.password,
            }
          : {}),
        ...(updatedUsername && values.username !== initialValues.username
          ? { username: values.username }
          : {}),
      };

      props.updateTeamMember(session, data, (response) => {
        setLoading(false);
        if (response.success === 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else if (response.success === 2) {
          if (response?.message?.password) {
            showError(response?.message?.password);
          } else {
            showError(COMMON_FAIL_MESSAGE);
          }
        } else if (response.success === 3) {
          showError(
            LocaleStrings.events_teammember_form_updated_error_email_taken
          );
        } else {
          // props.fetchEventTeams(session, eventid);
          setUpdatedUsername(false);
          showSuccess(LocaleStrings.events_teammember_form_updated_success);
          props.finishOperationsCallback();
          closeModal();
        }
      });
    } else {
      // Add Team Member
      var user = (values.firstname + values.lastname)
        .split(" ")
        .join("")
        .toLowerCase();
      values = {
        ...values,
        username: user,
        password: values.setpassword ? values.setpassword : password,
        membertype: "student",
        ismanager: 0,
        teamid,
      };

      props.fetchTeamClientEntrantCount(session, teamid, (res) => {
        if (
          SelectedTeam?.eventclient_by_eventclientidfk?.maxteammember >
            res?.data?.teamentrantcount &&
          SelectedTeam?.eventclient_by_eventclientidfk?.totalmembers >
            res?.data?.cliententrantcount
        ) {
          props.createTeamMember(session, values, (response) => {
            setLoading(false);
            if (response.success === 0) {
              showError(COMMON_FAIL_MESSAGE);
            } else {
              showSuccess(LocaleStrings.events_teammember_form_create_success);
              props.finishOperationsCallback();
              closeModal();
            }
          });
        } else {
          props.fetchSelectedTeam(session, teamid);
          showError(LocaleStrings.events_teammember_form_validation_team_full);
        }
      });
    }
  };

  var genderOptions = [
    { text: LocaleStrings.male, value: "male" },
    { text: LocaleStrings.female, value: "female" },
    { text: LocaleStrings.other, value: "others" },
  ];

  return (
    <>
      <Modal className="" isOpen={AddTeamMembers?.showModal ? true : false}>
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {toEdit
              ? LocaleStrings.events_teammember_edit_title
              : LocaleStrings.events_teammember_add_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <h4 className="mb-4">
              {toEdit
                ? LocaleStrings.events_teammember_edit_section_change_name
                : LocaleStrings.events_teammember_add_section_change_name}
            </h4>
            <div className="pl-5">
              {toEdit ? (
                <>
                  {updatedUsername && initialValues.ismanager ? (
                    <Field
                      name="username"
                      label={LocaleStrings.events_teammember_form_label_uname}
                      placeholder={
                        LocaleStrings.events_teammember_form_ph_uname
                      }
                      type="text"
                      mandatory="true"
                      component={renderFieldText}
                      labelposition={LABEL_POSITION_TOP}
                    />
                  ) : (
                    <div className="form-group row">
                      <label className="control-label-top col-sm-12">
                        {LocaleStrings.events_teammember_form_label_uname}:{" "}
                        <b>{initialValues.username}</b>{" "}
                        {initialValues.ismanager ? (
                          <img
                            className="cursor-pointer"
                            onClick={handleUsername}
                            alt="..."
                            src={
                              require("../../../../../assets/img/icons/common/edit-icon-small-black.png")
                                .default
                            }
                            height={20}
                          />
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
              <Field
                name="firstname"
                label={LocaleStrings.events_teammember_form_label_fname}
                placeholder={LocaleStrings.events_teammember_form_ph_fname}
                type="text"
                mandatory="true"
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />
              <Field
                name="lastname"
                label={LocaleStrings.events_teammember_form_label_lname}
                placeholder={LocaleStrings.events_teammember_form_ph_lname}
                type="text"
                mandatory="true"
                maxlength={
                  toEdit && initialValues?.membertype === "teacher" ? "50" : "1"
                }
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />
              <Field
                name="gender"
                label={LocaleStrings.events_teammember_form_label_gender}
                placeholder={LocaleStrings.events_teammember_form_ph_gender}
                mandatory="true"
                component={renderSelect}
                labelposition={LABEL_POSITION_TOP}
                opts={genderOptions}
              />
            </div>

            {/* <h4 className="mb-4">
              {toEdit
                ? LocaleStrings.events_teammember_edit_section_set_password
                : LocaleStrings.events_teammember_add_section_set_password}
            </h4>
            <div className="pl-5">
              <div className="form-group">
                <label className="control-label-top">
                  {LocaleStrings.events_teammember_form_label_password}
                </label>
                <div className="d-flex align-items-center justify-content-between">
                  <h4>{showNewPW && <>{password}</>}</h4>
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    onClick={handlePassword}
                  >
                    {LocaleStrings.button_password_generate}
                  </Button>
                </div>
              </div>
              <Field
                name="setpassword"
                type="text"
                component={renderHiddenFieldText}
              />
            </div> */}

            <div className="d-flex flex-column justify-content-center align-items-center mb-5">
              <div className="mb-2">
                <Button
                  className="mr-0"
                  type="button"
                  color="primary"
                  size="sm"
                  onClick={handlePassword}
                >
                  {LocaleStrings.button_password_generate}
                </Button>
                <Field
                  name="setpassword"
                  type="text"
                  component={renderHiddenFieldText}
                />
              </div>

              {showNewPW && (
                <h4>
                  <span>
                    {LocaleStrings.label_new_password} {password}
                  </span>
                </h4>
              )}
            </div>
          </div>

          <div className="modal-footer">
            <Button color="secondary" onClick={closeModal}>
              {LocaleStrings.button_close}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={pristine || invalid || submitting || loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ""}{" "}
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values, ownProps) => {
  // console.log("values : - ", values);
  var errors = {};
  var username = values["username"];
  var firstname = values["firstname"];
  var lastname = values["lastname"];
  var gender = values["gender"];
  if (ownProps.toEdit) {
    if (!username || username.trim() === "") {
      errors["username"] = LocaleStrings.requird;
    } else if (username && !validateEmail(username)) {
      errors["username"] = LocaleStrings.invalid_email;
    }
  }
  if (!firstname || firstname.trim() === "") {
    errors["firstname"] = LocaleStrings.requird;
  }
  if (!lastname || lastname.trim() === "") {
    errors["lastname"] = LocaleStrings.requird;
  }
  if (
    (ownProps.toEdit === false && lastname && lastname.length > 1) ||
    (ownProps.toEdit && values?.membertype === "student" && lastname.length > 1)
  ) {
    errors["lastname"] =
      LocaleStrings.events_teammember_form_validation_lastname_max_length;
  }
  if (!gender || gender.trim() === "") {
    errors["gender"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.AddTeamMembers?.value);
  var initVals = {};

  if (toEdit) {
    initVals = state?.AddTeamMembers?.value;
  }

  return {
    session: state.session,
    AddTeamMembers: state.AddTeamMembers,
    initialValues: initVals,
    toEdit,
    SelectedTeam: state.SelectedTeam,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openAddTeamMemberModal,
      updateTeamMember,
      createTeamMember,
      fetchTeamClientEntrantCount,
      fetchSelectedTeam,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "TeamMemberForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddTeamMember)
);
