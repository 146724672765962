import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, reset } from "redux-form";
import _ from "lodash";
import { Row, Col, Button, Table } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { LABEL_POSITION_TOP, COMMON_FAIL_MESSAGE } from "../../Common/constant";
import { renderFieldText } from "../../Common/base-components";
import { showSuccess, showError } from "../../Common/alertmessage";
import LocaleStrings from "../../../languages";
import Loader from "../../Common/loader";
import Pagination from "../../Common/pagination";
import { fetchRegion, addRegion, editRegion, updateRegion } from "../actions";

const Region = (props) => {
  let {
    session,
    regionList,
    toEdit,

    handleSubmit,
    pristine,
    submitting,
    invalid,
  } = props;
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const regionPerPage = 10;

  useEffect(() => {
    props.reset();
    // reset("RegionForm");
    props.editRegion({});
    props.fetchRegion(session, 0, regionPerPage);
  }, []);

  const paginationCallback = (pageNum) => {
    props.fetchRegion(session, pageNum, regionPerPage, (response) => {});

    setPageNumber(pageNum);
  };

  const onSubmitForm = (values, dispatch) => {
    // console.log("values :- ", values);

    setLoading(true);
    if (!toEdit) {
      props.addRegion(session, values, (res) => {
        setLoading(false);

        if (res.success) {
          paginationCallback(pageNumber);
          showSuccess(LocaleStrings.region_form_add_success);
          props.reset();
          // dispatch(reset("RegionForm"));
        } else {
          showError(COMMON_FAIL_MESSAGE);
        }
      });
    } else {
      props.updateRegion(session, values, (res) => {
        setLoading(false);
        if (res.success) {
          paginationCallback(pageNumber);
          showSuccess(LocaleStrings.region_form_update_success);
          // dispatch(reset("RegionForm"));
          props.reset();
          props.editRegion({});
        } else {
          showError(COMMON_FAIL_MESSAGE);
        }
      });
    }
  };

  const handleEdit = (item) => {
    props.reset();
    // reset("RegionForm");
    props.editRegion({ values: item });
  };

  const handleDelete = (item) => {
    confirmAlert({
      title: LocaleStrings.region_delete_confirm_title,
      message: `${LocaleStrings.region_delete_confirm_subtitle}?`,
      buttons: [
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            var values = { id: item?.id, isdeleted: 1 };
            props.updateRegion(session, values, (res) => {
              if (res.success) {
                paginationCallback(pageNumber);
                showSuccess(LocaleStrings.region_delete_success);
              } else {
                showError(COMMON_FAIL_MESSAGE);
              }
            });
          },
        },
      ],
    });
  };

  const renderList = () => {
    return _.map(regionList.data, (item, index) => {
      return (
        <tr key={`region_key_${index}`}>
          <td style={{ width: "60%" }}>{item.region}</td>
          <td style={{ width: "5%" }}>
            <i
              className="fa fa-pen cursor-pointer"
              aria-hidden="true"
              onClick={() => handleEdit(item)}
            ></i>
          </td>
          <td style={{ width: "5%" }}>
            <i
              className="fa fa-trash cursor-pointer"
              aria-hidden="true"
              onClick={() => handleDelete(item)}
            ></i>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Row>
          <Col sm="12">
            <div className="p-3"> {LocaleStrings.tab_name_region} </div>
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col sm="6">
            <Field
              name="region"
              type="text"
              mandatory="true"
              label={LocaleStrings.region_form_label_name}
              placeholder={LocaleStrings.region_form_ph_name}
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
          </Col>
        </Row>

        <Row className="justify-content-around my-3">
          <Col sm="6">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>
      <hr className="m-3" />
      <Row className="mt-4 mb-7">
        <Col sm="12">
          {regionList?.data ? (
            <div style={{ marginTop: 15 }}>
              {regionList?.count > 0 ? (
                <div className="faq-list">
                  <Table
                    className="tablelist align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: "60%" }}>
                          {LocaleStrings.region_th_name}
                        </th>
                        <th scope="col" style={{ width: "5%" }} />
                        <th scope="col" style={{ width: "5%" }} />
                      </tr>
                    </thead>

                    <tbody>{renderList()}</tbody>
                  </Table>
                </div>
              ) : (
                <div className="no-content-message">
                  {LocaleStrings.no_region_available}
                </div>
              )}
            </div>
          ) : (
            <div className="loaderstyle">
              <Loader />
            </div>
          )}

          {regionList?.count > regionPerPage ? (
            <div>
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={regionPerPage}
                totalItemsCount={regionList?.count}
                pageRangeDisplayed={3}
                onChange={paginationCallback}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

const validate = (values) => {
  var errors = {
    eventtype: "",
    faq: [],
  };

  if (!values?.eventtype || values?.eventtype.trim() === "") {
    errors.eventtype = LocaleStrings.requird;
  }

  values?.faq?.forEach((f, i) => {
    errors.faq.push({});
    if (!f.question) {
      errors.faq[i].question = "Required*";
    }

    if (!f.answer) {
      errors.faq[i].answer = "Required*";
    }
  });

  return errors;
};

const mapStateToProps = (state) => {
  var initVals = {};
  var toEdit = !_.isEmpty(state?.editRegionInfo?.values);

  if (toEdit) {
    initVals = state?.editRegionInfo?.values;
  }

  return {
    session: state.session,
    regionList: state.regionList,
    editRegionInfo: state.editRegionInfo,
    initialValues: initVals,
    toEdit,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRegion,
      addRegion,
      editRegion,
      updateRegion,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RegionForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Region)
);
