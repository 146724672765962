import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { login } from "../../actions";
import { LABEL_POSITION_TOP } from "../../components/Common/constant";
import {
  renderFieldText,
  validateEmail,
} from "../../components/Common/base-components";
import { showError } from "../../components/Common/alertmessage";
import LocaleStrings from "../../languages";

const Login = (props) => {
  let { session, isLoggedIn, handleSubmit, login } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onSubmitForm = (values) => {
    setLoading(true);
    login(session, values, (response) => {
      setLoading(false);
      if (response.success === 0) {
        showError(LocaleStrings.login_error_text_invalid_email_password);
      } else if (response.success === 2) {
        showError(LocaleStrings.login_error_text_invalid_user);
      } else {
        history.push("/admin/events");
      }
    });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <Field
                name="email"
                label={LocaleStrings.login_form_label_email}
                placeholder={LocaleStrings.login_form_ph_email}
                type="text"
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />
              <Field
                name="password"
                label={LocaleStrings.login_form_label_password}
                placeholder={LocaleStrings.login_form_ph_password}
                type="password"
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />
              <Button
                type="submit"
                className="w-100"
                color="primary"
                disabled={loading}
              >
                <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
                &ensp;
                {LocaleStrings.button_login}
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var email = values["email"];
  var password = values["password"];

  if (!email || email.trim() === "") {
    errors["email"] = LocaleStrings.requird;
  } else if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }
  if (!password || password.trim() === "") {
    errors["password"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  session: state.session,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "LoginForm",
  })(Login)
);
