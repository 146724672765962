import { store } from "react-notifications-component";

export const showSuccess = (message) => {
  store.addNotification({
    title: "Success!",
    message: `${message}`,
    type: "success",
    insert: "top",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
      click: true,
      pauseOnHover: true,
    },
  });
};

export const showError = (message) => {
  store.addNotification({
    title: "Error!",
    message: `${message}`,
    type: "danger",
    insert: "top",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
      click: true,
      pauseOnHover: true,
    },
  });
};

export const showWarning = (message) => {
  store.addNotification({
    title: "Warning!",
    message: `${message}`,
    type: "warning",
    insert: "top",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
      click: true,
      pauseOnHover: true,
    },
  });
};
