import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, FieldArray, reset } from "redux-form";
import { Row, Col, Button, Table } from "reactstrap";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";

// constants
import {
  LABEL_POSITION_TOP,
  itemCount,
  COMMON_FAIL_MESSAGE,
} from "../../../Common/constant";
import {
  renderFieldText,
  renderRichTextareaWithLabelTinymce,
  renderFieldCheckbox,
} from "../../../Common/base-components";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";
import Loader from "../../../Common/loader";

// actions
import {
  addEmailTemplate,
  fetchTemplates,
  updateTemplate,
  editTemplate,
} from "../../actions";

// components
import Pagination from "../../../Common/pagination";

const EmailTemplate = (props) => {
  let {
    session,
    handleSubmit,
    eventEmailTemplateList,
    pristine,
    submitting,
    invalid,
    editEventEmailTemplate,
    eventid,
  } = props;
  const topRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [editTrue, setEditTrue] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    props.fetchTemplates(session, eventid);
  }, []);

  const paginationCallback = (pageNum) => {
    props.fetchTemplates(session, eventid, pageNum, (response) => {});
    setPageNumber(pageNum);
  };

  const onSubmitForm = (values, dispatch) => {
    setLoading(true);

    let resource = [];
    values.eventidfk = eventid;
    resource.push(values);

    !editTrue
      ? props.addEmailTemplate(session, resource, (res) => {
          if (res.success) {
            dispatch(reset("EmailEventForm"));
            setLoading(false);
            props.fetchTemplates(session, eventid);
            showSuccess(LocaleStrings.email_template_add_success);
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        })
      : props.updateTemplate(
          session,
          editEventEmailTemplate?.id,
          resource[0],
          (res) => {
            if (res.success) {
              dispatch(reset("EmailEventForm"));
              setLoading(false);
              props.fetchTemplates(session, eventid);
              showSuccess(LocaleStrings.email_template_update_success);

              let tempObj = {};
              props.editTemplate(tempObj);
              setEditTrue(false);
            } else {
              setLoading(false);
              showError(COMMON_FAIL_MESSAGE);
            }
          }
        );
  };

  const eventEmailTemplateListRender = () => {
    return _.map(eventEmailTemplateList.data, (temp) => {
      return (
        <tr>
          <td style={{ width: "30%", whiteSpace: "normal" }}>{temp.subject}</td>
          <td style={{ width: "50%", whiteSpace: "normal" }}>
            <div dangerouslySetInnerHTML={{ __html: temp.body }}></div>
          </td>
          <td style={{ width: "15%" }}>
            {temp.isactive ? (
              <a
                onClick={() => activateDeactivate(temp)}
                style={{ color: "green", cursor: "pointer" }}
              >
                Active
              </a>
            ) : (
              <a
                onClick={() => activateDeactivate(temp)}
                style={{ color: "red", cursor: "pointer" }}
              >
                Deactive
              </a>
            )}
          </td>
          <td style={{ width: "5%" }}>
            <i
              className="fa fa-pen"
              aria-hidden="true"
              onClick={() => handleEdit(temp)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
          <td style={{ width: "5%" }}>
            {!temp.isdeleted ? (
              <i
                className="fa fa-trash"
                aria-hidden="true"
                onClick={() => handleDelete(temp.id)}
                style={{ cursor: "pointer" }}
              ></i>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: LocaleStrings.email_template_delete_title,
      message: LocaleStrings.email_template_delete_messgae,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            let values = {};
            values.isactive = 0;
            values.isdeleted = 1;
            props.updateTemplate(session, id, values, (res) => {
              if (res.success) {
                props.fetchTemplates(session, eventid);
                showSuccess(LocaleStrings.email_template_delete_success);
              } else {
                showError(COMMON_FAIL_MESSAGE);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const handleEdit = (tempObj) => {
    props.editTemplate(tempObj);
    setEditTrue(true);
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const activateDeactivate = (temp) => {
    let values = {};
    values.isactive = temp.isactive ? 0 : 1;

    props.updateTemplate(session, temp.id, values, (res) => {
      if (res.success) {
        props.fetchTemplates(session);
        showSuccess(
          values.isactive
            ? LocaleStrings.email_template_activate
            : LocaleStrings.email_template_deactivate
        );
      } else {
        showError(COMMON_FAIL_MESSAGE);
      }
    });
  };

  return (
    <div ref={topRef}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div
          className="col-sm-12 col-sm-offset-0"
          style={{ padding: "0px 30px" }}
        >
          <Field
            name="subject"
            label={LocaleStrings.email_template_subject}
            placeholder={LocaleStrings.email_template_subject}
            type="text"
            mandatory="true"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
          />

          <Field
            name="body"
            label={LocaleStrings.email_template}
            placeholder={LocaleStrings.email_template}
            component={renderRichTextareaWithLabelTinymce}
            labelposition={LABEL_POSITION_TOP}
            height={300}
            mandatory="true"
            type="minimal"
          />
          <Field
            name="isteam"
            type="checkbox"
            label={LocaleStrings.email_template_team}
            component={renderFieldCheckbox}
          />
          <Field
            name="isschool"
            type="checkbox"
            label={LocaleStrings.email_template_school}
            component={renderFieldCheckbox}
          />
          <Field
            name="isguardian"
            type="checkbox"
            label={LocaleStrings.email_template_gurdain}
            component={renderFieldCheckbox}
          />
        </div>
        <Row className="justify-content-around my-3">
          <Col sm="6">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>
      <hr className="m-3" />
      <Row className="mt-4 mb-7">
        <Col sm="12">
          {eventEmailTemplateList && eventEmailTemplateList.data ? (
            <div style={{ marginTop: 15 }}>
              {eventEmailTemplateList.data &&
              eventEmailTemplateList.data.length > 0 ? (
                <div className="faq-list">
                  <Table
                    className="tablelist align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: "30%" }}>
                          {LocaleStrings.email_template_subject}
                        </th>
                        <th scope="col" style={{ width: "50%" }}>
                          {LocaleStrings.email_template}
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          {LocaleStrings.email_template_active_deactive}
                        </th>
                        <th scope="col" style={{ width: "5%" }}></th>
                        <th scope="col" style={{ width: "5%" }}></th>
                      </tr>
                    </thead>

                    <tbody>{eventEmailTemplateListRender()}</tbody>
                  </Table>
                </div>
              ) : (
                <div className="no-content-message">
                  {LocaleStrings.no_record_email_template}
                </div>
              )}
            </div>
          ) : (
            <div className="loaderstyle">
              <Loader />
            </div>
          )}

          {eventEmailTemplateList &&
          eventEmailTemplateList.count > itemCount ? (
            <div>
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={itemCount}
                totalItemsCount={eventEmailTemplateList?.count}
                pageRangeDisplayed={3}
                onChange={paginationCallback}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

const validate = (values) => {
  var errors = {};

  if (!values?.subject || values?.subject.trim() === "") {
    errors.subject = LocaleStrings.requird;
  }
  if (!values?.body || values?.body.trim() === "") {
    errors.body = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // console.log("settingsReducer: ", state.settingsReducer);

  var initVals = {};
  var toEdit = !_.isEmpty(state?.editEventEmailTemplate);

  if (toEdit) {
    initVals = {
      subject: state?.editEventEmailTemplate.subject,
      body: state?.editEventEmailTemplate.body,
    };
  }

  return {
    session: state.session,
    initialValues: initVals,
    toEdit,
    eventEmailTemplateList: state.eventEmailTemplateList,
    editEventEmailTemplate: state.editEventEmailTemplate,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { addEmailTemplate, fetchTemplates, updateTemplate, editTemplate },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EmailEventForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EmailTemplate)
);
