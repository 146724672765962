import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field } from "redux-form";
import _ from "lodash";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import { storeAddEventFormData, openEventModal } from "../actions";
import {
  renderFieldText,
  renderSelect,
  renderFieldRadio,
  renderHiddenFieldText,
  renderHiddenErrorMessageField,
} from "../../Common/base-components";
import { LABEL_POSITION_TOP, BASE_IMAGES_URL } from "../../Common/constant";
import ImageCropperName from "../../Common/image-cropper-with_nameview";
import ColorPicker from "../../Common/color-picker";
import LocaleStrings from "../../../languages";

const AddEventStep4 = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const _toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs className="">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              _toggle("1");
            }}
          >
            {LocaleStrings.events_form_tab_course}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              _toggle("2");
            }}
          >
            {LocaleStrings.events_form_tab_branding}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              _toggle("3");
            }}
          >
            {LocaleStrings.events_form_tab_certificate}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Course {...props} />
        </TabPane>
        <TabPane tabId="2">
          <Branding {...props} />
        </TabPane>
        <TabPane tabId="3">
          <Certificate {...props} />
        </TabPane>
      </TabContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeAddEventFormData, openEventModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEventStep4);

const Course = (props) => {
  var { EventFormData, legsList, toEdit, toDisabled, AddEventModal } = props;
  var totalLegs =
    EventFormData?.legs && EventFormData?.bonuslegs
      ? parseInt(EventFormData.legs) + parseInt(EventFormData.bonuslegs)
      : EventFormData?.bonuslegs
      ? EventFormData.bonuslegs
      : EventFormData?.legs
      ? EventFormData.legs
      : 0;
  var eventLegs = EventFormData?.legsArr ? EventFormData.legsArr.length : 0;

  const _handelLegs = (action, item = {}, index = 0) => {
    var legsArr = EventFormData?.legsArr ? EventFormData.legsArr : [];
    var removedArr = EventFormData?.removedArr ? EventFormData.removedArr : []; // This will use in event edit

    if (action === "add") {
      var lastData = legsArr[legsArr.length - 1];
      legsArr.push({
        key: `${lastData ? parseInt(lastData?.key) + 1 : 1}`,
        legkey: `Leg ${lastData ? parseInt(lastData?.key) + 1 : 1}`,
        isOpen: true,
        selected: "",
        legtitle: "",
        isnew: true,
      });
    }
    if (action === "remove") {
      // var newLeg = _.filter(legsArr, (obj) => {
      //   return obj.key !== item.key;
      // });
      var newLeg = _.filter(legsArr, (obj, i) => {
        return i !== index;
      });

      if (toEdit) {
        var removed = _.filter(legsArr, (obj) => {
          return obj.key === item.key;
        });
        // var removed = _.filter(legsArr, (obj, i) => {
        //   return i !== index;
        // });
        // console.log("removed :- ", removed);
        // console.log("index :- ", index);

        // Removed from initial value data
        if (!removed?.[0]?.isnew && removed?.[0]?.eventlegid) {
          // console.log("AddEventModal :- ", AddEventModal);
          AddEventModal?.value?.eventleg_by_eventidfk.splice(index, 1);
          // AddEventModal?.value?.leg_locationtitle.splice(index, 1);
          // console.log("AddEventModal :- ", AddEventModal);

          props.openEventModal(AddEventModal);
          // console.log("props?.initialValues :- ", props?.initialValues);

          var newTitle = _.filter(
            props?.initialValues?.leg_locationtitle,
            (obj, i) => {
              return i !== index;
            }
          );
          var newLegId = _.filter(
            props?.initialValues?.leg_materlegidfk,
            (obj, i) => {
              return i !== index;
            }
          );
          var newAnimation = _.filter(
            props?.initialValues?.leg_animationidfk,
            (obj, i) => {
              return i !== index;
            }
          );
          var newDetails = _.filter(
            props?.initialValues?.leg_detailsidfk,
            (obj, i) => {
              return i !== index;
            }
          );
          var newGalleryTitle = _.filter(
            props?.initialValues?.leggallery_title,
            (obj, i) => {
              return i !== index;
            }
          );
          var newGalleryImg = _.filter(
            props?.initialValues?.leggallery_leggalleryidfk,
            (obj, i) => {
              return i !== index;
            }
          );

          props.autofill("leg_locationtitle", newTitle);
          props.autofill("leg_materlegidfk", newLegId);
          props.autofill("leg_animationidfk", newAnimation);
          props.autofill("leg_detailsidfk", newDetails);
          props.autofill("leggallery_title", newGalleryTitle);
          props.autofill("leggallery_leggalleryidfk", newGalleryImg);

          removedArr.push({ ...removed[0] });
        }
        // console.log("removedArr :- ", removedArr);
      }

      var newArr = [];
      _.map(newLeg, (obj, index) => {
        newArr.push({
          key: `${index + 1}`,
          legkey: `Leg ${index + 1}`,
          ...obj,
        });
      });

      legsArr = newArr;
    }

    props.storeAddEventFormData({
      ...EventFormData,
      legsArr,
      ...(toEdit ? { removedArr: removedArr } : {}),
    });
  };

  const _handelLegsOpen = (index) => {
    var legsArr = EventFormData?.legsArr ? EventFormData.legsArr : [];

    legsArr[index].isOpen = !legsArr[index].isOpen;

    props.storeAddEventFormData({
      ...EventFormData,
      legsArr,
    });
  };

  const _handelLegTitle = (e, index) => {
    var val = e.target.value;
    var legsArr = EventFormData?.legsArr ? EventFormData.legsArr : [];

    legsArr[index].legtitle = val;

    props.storeAddEventFormData({
      ...EventFormData,
      legsArr,
    });
  };

  const _handelSelectedLeg = (e, index) => {
    var val = e.target.value;
    var legsArr = EventFormData?.legsArr ? EventFormData.legsArr : [];

    legsArr[index].selected = val;

    props.storeAddEventFormData({
      ...EventFormData,
      legsArr,
    });
  };

  const _renderLegs = () => {
    var legsData = EventFormData?.legsArr ? EventFormData.legsArr : [];

    return _.map(legsData, (item, index) => {
      var legTitle = item?.legtitle ? item.legtitle : "";
      var sLocation = "";
      var eLocation = "";
      var legsOptions = [];
      var legAnimation = [];
      var legDetails = [];
      var legGallery = [];

      _.map(legsList?.data, (val) => {
        let obj = { text: val.name, value: val.id };
        legsOptions.push(obj);
      });

      if (item?.selected) {
        let match = _.filter(legsList?.data, (o) => {
          return o.id == item.selected;
        });
        sLocation = match?.length > 0 ? match[0].startpoint : "";
        eLocation = match?.length > 0 ? match[0].endpoint : "";

        if (match?.length > 0) {
          _.map(match[0].masterleganimation_by_masterlegidfk, (o) => {
            legAnimation.push({ text: o.name, value: o.id });
          });
          _.map(match[0].masterlegdetails_by_masterlegidfk, (o) => {
            legDetails.push({ text: o.display, value: o.id });
          });
          _.map(match[0].masterleggallery_by_masterlegidfk, (o) => {
            legGallery.push({ text: o.name, value: o.id });
          });
        }
      }

      return (
        <Card className="mb-3" key={`legs_${index}`}>
          <CardHeader>
            <div className="pull-left font-weight-normal">{item.legkey}</div>
            <div className="pull-right">
              {toDisabled ? (
                ""
              ) : (
                <Button
                  className="cursor-pointer pr-2 pl-2 pt-1 pb-1"
                  onClick={() => _handelLegs("remove", item, index)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </Button>
              )}
              <Button
                className="cursor-pointer pr-2 pl-2 pt-1 pb-1"
                onClick={() => _handelLegsOpen(index)}
              >
                <i
                  className={`fa ${
                    item.isOpen ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                  aria-hidden="true"
                ></i>
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            {!item?.isOpen ? (
              <>
                <div>
                  <label className="control-label">
                    {LocaleStrings.events_form_lable_leg_title}:{" "}
                    <span className="h4">{legTitle}</span>
                  </label>
                </div>
                {/* <div>
                  <label className="control-label">
                    {LocaleStrings.events_form_label_start_location}:{" "}
                    <span className="h4">{sLocation}</span>
                  </label>
                </div>
                <div>
                  <label className="control-label">
                    {LocaleStrings.events_form_label_end_location}:{" "}
                    <span className="h4">{eLocation}</span>
                  </label>
                </div> */}
              </>
            ) : (
              <>
                <Field
                  name={`leg_locationtitle[${index}]`}
                  label={LocaleStrings.events_form_lable_leg_title}
                  placeholder={LocaleStrings.events_form_ph_leg_title}
                  type="text"
                  mandatory="true"
                  component={renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  onChange={(e) => _handelLegTitle(e, index)}
                />
                <Field
                  name={`leg_materlegidfk[${index}]`}
                  label={LocaleStrings.events_form_lable_leg}
                  placeholder={LocaleStrings.events_form_ph_leg}
                  mandatory="true"
                  component={renderSelect}
                  labelposition={LABEL_POSITION_TOP}
                  opts={legsOptions}
                  onChange={(e) => _handelSelectedLeg(e, index)}
                />
              </>
            )}
          </CardBody>
        </Card>
      );
    });
  };

  return (
    <>
      {_renderLegs()}

      {totalLegs > eventLegs ? (
        <Button
          color="primary"
          type="button"
          onClick={() => _handelLegs("add")}
        >
          {LocaleStrings.button_leg}
        </Button>
      ) : (
        ""
      )}

      <Field
        name={`totallegsmismatch`}
        type="text"
        component={renderHiddenErrorMessageField}
      />
    </>
  );
};

const Branding = (props) => {
  var { toEdit, initialValues } = props;
  const [backgroundType, setBackgroundType] = useState("color");
  const [bannerData, setBannerData] = useState({});
  const [logoData, setLogoData] = useState({});
  const [login1Data, setLogin1Data] = useState({});
  const [login2Data, setLogin2Data] = useState({});
  const [login3Data, setLogin3Data] = useState({});
  const [login4Data, setLogin4Data] = useState({});
  const [login5Data, setLogin5Data] = useState({});

  useEffect(() => {
    // console.log("initialValues :- ", initialValues);
    if (toEdit) {
      var bdData = {};
      if (initialValues?.bgtype === "image") {
        if (initialValues?.bgvalue) {
          var f1 = initialValues?.bgvalue.split("/").pop();
          var f2 = f1.split("_A_").pop();
          var ext = f2.split(".").pop();

          bdData = {
            file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
            name: f2,
            type: `image/${ext}`,
            isnew: false,
          };
        }
      } else {
        bdData = { color: initialValues?.bgvalue, isnew: false };
      }
      setBackgroundType(initialValues?.bgtype);
      setBannerData(bdData);

      if (initialValues?.logo) {
        var f1 = initialValues?.logo.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogoData(obj);
      }

      if (initialValues?.loginbgimage) {
        var f1 = initialValues?.loginbgimage.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogin1Data(obj);
      }

      if (initialValues?.loginbgimage1) {
        var f1 = initialValues?.loginbgimage1.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogin2Data(obj);
      }

      if (initialValues?.loginbgimage2) {
        var f1 = initialValues?.loginbgimage2.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogin3Data(obj);
      }

      if (initialValues?.loginbgimage3) {
        var f1 = initialValues?.loginbgimage3.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogin4Data(obj);
      }

      if (initialValues?.loginbgimage4) {
        var f1 = initialValues?.loginbgimage4.split("/").pop();
        var f2 = f1.split("_A_").pop();
        var ext = f2.split(".").pop();

        var obj = {
          file: `${BASE_IMAGES_URL}/${f1}?api_key=${props.session.apiKey}&session_token=${props.session.sessionToken}`,
          name: f2,
          type: `image/${ext}`,
          isnew: false,
        };
        setLogin5Data(obj);
      }
    }
  }, []);

  const handleBgType = (e) => {
    var type = e.target.value;

    setBackgroundType(type);
    setBannerData({});
  };

  const onBannerImageSave = (file) => {
    // console.log("Banner file :- ", file);
    setBannerData(file);
    props.autofill("bgvalue", file);
  };
  const onBannerImageChange = (bool) => {};

  const onBannerBGSave = (color) => {
    // console.log("Banner color :- ", color);
    setBannerData(color);
    props.autofill("bgvalue", color);
  };

  const onLogoImageSave = (file) => {
    setLogoData(file);
    props.autofill("logo", file);
  };
  const onLogoImageChange = (bool) => {};

  const onLogin1ImageSave = (file) => {
    setLogin1Data(file);
    props.autofill("loginbgimage", file);
  };
  const onLogin1ImageChange = (bool) => {};

  const onLogin2ImageSave = (file) => {
    setLogin2Data(file);
    props.autofill("loginbgimage1", file);
  };
  const onLogin2ImageChange = (bool) => {};

  const onLogin3ImageSave = (file) => {
    setLogin3Data(file);
    props.autofill("loginbgimage2", file);
  };
  const onLogin3ImageChange = (bool) => {};

  const onLogin4ImageSave = (file) => {
    setLogin4Data(file);
    props.autofill("loginbgimage3", file);
  };
  const onLogin4ImageChange = (bool) => {};

  const onLogin5ImageSave = (file) => {
    setLogin5Data(file);
    props.autofill("loginbgimage4", file);
  };
  const onLogin5ImageChange = (bool) => {};

  // console.log("backgroundType :- ", backgroundType);
  // console.log("bannerData :- ", bannerData);

  return (
    <>
      <div className="row">
        <div className="col-4">
          <label className="control-label">
            {LocaleStrings.events_form_label_background}
          </label>
        </div>
        <div className="col-4">
          <Field
            name="bgtype"
            label={LocaleStrings.events_form_background_option1}
            type="radio"
            value="image"
            component={renderFieldRadio}
            onChange={handleBgType}
          />
        </div>
        <div className="col-4">
          <Field
            name="bgtype"
            label={LocaleStrings.events_form_background_option2}
            type="radio"
            value="color"
            component={renderFieldRadio}
            onChange={handleBgType}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-12">
          {backgroundType === "image" ? (
            <ImageCropperName
              requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
              cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
              onImageSave={onBannerImageSave}
              onImageChange={onBannerImageChange}
              imageData={bannerData}
              imageType="jpg"
              labelKey="eventbanner"
              className="drop-zone-area-custom-image"
              insideText="Upload (1024 x 1024 image)."
            />
          ) : (
            <ColorPicker selected={bannerData} onColorChnage={onBannerBGSave} />
          )}
          <Field
            name={`bgvalue`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="control-label-top col-md-12">
          {LocaleStrings.events_form_label_logo}
        </label>
        <div className="col-12">
          <ImageCropperName
            requiredSize={{ width: 300, height: 300 }} // For image size required validation
            cropperSize={{ width: 250, height: 250 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogoImageSave}
            onImageChange={onLogoImageChange}
            imageData={logoData}
            imageType="jpg"
            labelKey="eventlogo"
            className="drop-zone-area-custom-image"
            insideText="Upload (300 x 300 image)."
          />
          <Field name={`logo`} type="text" component={renderHiddenFieldText} />
        </div>
      </div>
      <div className="form-group row">
        <label className="control-label-top col-md-12">
          {LocaleStrings.events_form_label_login_background_image}
        </label>
        <div className="col-12 mb-3">
          <ImageCropperName
            requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
            cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogin1ImageSave}
            onImageChange={onLogin1ImageChange}
            imageData={login1Data}
            imageType="jpg"
            labelKey="eventlogin1"
            className="drop-zone-area-custom-image"
            insideText="Upload (1024 x 1024 image)."
          />
          <Field
            name={`loginbgimage`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
        <div className="col-12 mb-3">
          <ImageCropperName
            requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
            cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogin2ImageSave}
            onImageChange={onLogin2ImageChange}
            imageData={login2Data}
            imageType="jpg"
            labelKey="eventlogin2"
            className="drop-zone-area-custom-image"
            insideText="Upload (1024 x 1024 image)."
          />
          <Field
            name={`loginbgimage1`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
        <div className="col-12 mb-3">
          <ImageCropperName
            requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
            cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogin3ImageSave}
            onImageChange={onLogin3ImageChange}
            imageData={login3Data}
            imageType="jpg"
            labelKey="eventlogin3"
            className="drop-zone-area-custom-image"
            insideText="Upload (1024 x 1024 image)."
          />
          <Field
            name={`loginbgimage2`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
        <div className="col-12 mb-3">
          <ImageCropperName
            requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
            cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogin4ImageSave}
            onImageChange={onLogin4ImageChange}
            imageData={login4Data}
            imageType="jpg"
            labelKey="eventlogin4"
            className="drop-zone-area-custom-image"
            insideText="Upload (1024 x 1024 image)."
          />
          <Field
            name={`loginbgimage3`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
        <div className="col-12">
          <ImageCropperName
            requiredSize={{ width: 1024, height: 1024 }} // For image size required validation
            cropperSize={{ width: 1000, height: 1000 }} // Cropper display size. Note its add 50px for padding
            onImageSave={onLogin5ImageSave}
            onImageChange={onLogin5ImageChange}
            imageData={login5Data}
            imageType="jpg"
            labelKey="eventlogin5"
            className="drop-zone-area-custom-image"
            insideText="Upload (1024 x 1024 image)."
          />
          <Field
            name={`loginbgimage4`}
            type="text"
            component={renderHiddenFieldText}
          />
        </div>
      </div>
    </>
  );
};

const Certificate = (props) => {
  var { certificatesList } = props;
  var certificatesArr = [];
  _.map(certificatesList?.data, (item) => {
    var obj = { text: item.name, value: item.id };
    certificatesArr.push(obj);
  });

  return (
    <>
      <Field
        name="teamcertificateidfk"
        label={LocaleStrings.events_form_lable_team_certificate}
        placeholder={LocaleStrings.events_form_ph_team_certificate}
        mandatory="false"
        component={renderSelect}
        labelposition={LABEL_POSITION_TOP}
        opts={certificatesArr}
      />
      <Field
        name="individualcertificateidfk"
        label={LocaleStrings.events_form_lable_individual_certificate}
        placeholder={LocaleStrings.events_form_ph_individual_certificate}
        mandatory="false"
        component={renderSelect}
        labelposition={LABEL_POSITION_TOP}
        opts={certificatesArr}
      />
    </>
  );
};
