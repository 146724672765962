import _ from "lodash";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  uploadEncodedFileLarge,
} from "../../Common/network-call";
import {
  BASE_URL,
  BASE_IMAGES_URL,
  itemCount,
  CUSTOM_ITEM_COUNT,
} from "../../Common/constant";
import randomstring from "randomstring";

import { convertDateTimeIntoLocal } from "../../Common/constant";

export const ACTIVE_EVENT_LIST = "ACTIVE_EVENT_LIST";
export const INACTIVE_EVENT_LIST = "INACTIVE_EVENT_LIST";
export const ADD_EVENT_MODAL = "ADD_EVENT_MODAL";
export const EVENT_CURRENT_STEP = "EVENT_CURRENT_STEP";
export const EVENT_FORM_DATA = "EVENT_FORM_DATA";
export const SINGLE_EVENT_DETAILS = "SINGLE_EVENT_DETAILS";
export const EVENT_URL_MODAL = "EVENT_URL_MODAL";
export const SINGLE_EVENT_ALL_CLIENT_LIST = "SINGLE_EVENT_ALL_CLIENT_LIST";
export const SINGLE_EVENT_CLIENTS = "SINGLE_EVENT_CLIENTS";
export const SINGLE_EVENT_REGISTRATION_REQUEST =
  "SINGLE_EVENT_REGISTRATION_REQUEST";
export const REQUESTED_USER_EMAIL_UPPDATE_MODAL =
  "REQUESTED_USER_EMAIL_UPPDATE_MODAL";
export const EVENT_CLIENT_LIST_MODAL = "EVENT_CLIENT_LIST_MODAL";
export const SINGLE_EVENT_TEAMS = "SINGLE_EVENT_TEAMS";
export const ADD_EVENT_CLIENT_MODAL = "ADD_EVENT_CLIENT_MODAL";
export const SELECTED_CLIENT = "SELECTED_CLIENT";
export const ADD_TEAM_MODAL = "ADD_TEAM_MODAL";
export const VIEW_TEAM_MODAL = "VIEW_TEAM_MODAL";
export const SELECTED_TEAM = "SELECTED_TEAM";
export const TEAM_CLIENT_ENTRANT_COUNT = "TEAM_CLIENT_ENTRANT_COUNT";
export const SELECTED_TEAM_MEMBERS = "SELECTED_TEAM_MEMBERS";
export const VIEW_TEAM_MEMBER_MODAL = "VIEW_TEAM_MEMBER_MODAL";
export const ADD_TEAM_MEMBER_MODAL = "ADD_TEAM_MEMBER_MODAL";
export const DOWNLOAD_TEAM_INFO = "DOWNLOAD_TEAM_INFO";
export const EVENT_TEMPLATE_LIST = "EVENT_TEMPLATE_LIST";
export const EVENT_TEMPLATE_EDIT = "EVENT_TEMPLATE_EDIT";

export function fetchActiveEvents(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = encodeURI(`isactive=1`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?include_count=true&related=eventsettings_by_eventidfk,eventleg_by_eventidfk,leggallery_by_eventidfk&filter=(${filter})&order=createdon%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: ACTIVE_EVENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchInactiveEvents(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = encodeURI(`isactive=0`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?include_count=true&related=eventsettings_by_eventidfk,eventleg_by_eventidfk,leggallery_by_eventidfk&filter=(${filter})&order=createdon%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: INACTIVE_EVENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function searchActiveEvents(session, search, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var searchQuery = `(name like %${search}%)OR(type like %${search}%)OR(app like %${search}%)`;
  var filter = encodeURI(`(isactive=1)AND(${searchQuery})`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?filter=(${filter})&include_count=true&order=createdon%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: ACTIVE_EVENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function searchInactiveEvents(session, search, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var searchQuery = `(name like %${search}%)OR(type like %${search}%)OR(app like %${search}%)`;
  var filter = encodeURI(`(isactive=0)AND(${searchQuery})`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?filter=(${filter})&include_count=true&order=createdon%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: INACTIVE_EVENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function clearActiveEvents() {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_EVENT_LIST,
      payload: {},
    });
  };
}

export function clearInactiveEvents() {
  return (dispatch) => {
    dispatch({
      type: INACTIVE_EVENT_LIST,
      payload: {},
    });
  };
}

export function activateDeactivateEvent(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/event`;
  var body = { resource: { ...values } };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function openEventModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_EVENT_MODAL,
      payload: obj,
    });
  };
}

export function currentStep(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CURRENT_STEP,
      payload: obj,
    });
  };
}

export function storeAddEventFormData(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_FORM_DATA,
      payload: obj,
    });
  };
}

export function checkSubdomain(session, subdomain, callback) {
  var filter = encodeURI(`(subdomain=${subdomain})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/event?filter=${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response.resource.length > 0) {
          callback({ success: 2 });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function createEvent(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/event`;
  var body = { resource: [values] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (values?.eventleg_by_eventidfk?.length > 0) {
          var filter = encodeURI(
            `eventidfk=${response?.resource?.[0]?.eventid}`
          );
          var getUrl = `${BASE_URL}api/v2/teamchallenge/_table/eventleg?fields=eventlegid&filter=(${filter})`;
          getRequest(
            getUrl,
            session,
            dispatch,
            (res) => {
              if (res.resource.length > 0) {
                var eventLeg = _.map(res?.resource, "eventlegid").join(",");

                var filter1 = encodeURI(`eventlegidfk IN (${eventLeg})`);
                var patchUrl = `${BASE_URL}api/v2/teamchallenge/_table/leggallery?filter=(${filter1})`;
                var patchBody = {
                  resource: [{ eventidfk: response?.resource?.[0]?.eventid }],
                };

                patchRequest(
                  patchUrl,
                  patchBody,
                  session,
                  dispatch,
                  (response) => {
                    callback({ success: 1, data: response.resource });
                  },
                  (error) => {
                    callback({ success: 0, error: error });
                  }
                );
              } else {
                callback({ success: 1, data: response.resource });
              }
            },
            (error) => {
              callback({ success: 0, error: error });
            }
          );
        } else {
          callback({ success: 1, data: response.resource });
        }
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function updateEvent(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/event`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const uploadImagesWithFormData = (
  session,
  imagesData,
  eventid,
  callback = null
) => {
  let dataMedia = new FormData();
  let name = imagesData?.name;
  let file = imagesData?.file;
  let filetype = imagesData?.type;
  let filename = `${randomstring.generate()}_A_${name}`;

  let blob = new Blob([file], { type: filetype });
  dataMedia.append("files", blob, filename);

  return (dispatch) => {
    uploadEncodedFileLarge(
      `${BASE_IMAGES_URL}/teamchallenge/${eventid}/?check_exist=false`,
      session,
      dataMedia,
      dispatch,
      (data) => {
        callback && callback({ success: 1, data: data });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export function deleteMultipleEventLegs(session, eventlegids, callback) {
  var filter = encodeURI(`eventlegid IN (${eventlegids})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventleg?filter=(${filter})`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function deleteAllEventLegsGallery(session, eventid, callback) {
  var filter = encodeURI(`eventidfk=${eventid}`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/leggallery?filter=(${filter})`;

  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function updateEventClientLegFromEvent(session, data, callback) {
  var filter = encodeURI(
    `(eventidfk=${data?.eventidfk})AND(eventlegidfk=${data?.eventlegid})`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclientlegs?filter=(${filter})`;
  var body = { resource: [{ legidfk: data?.materlegidfk }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const fetchSingleEvent = (session, eventid, callback = null) => {
  let filter = encodeURI(`eventid=${eventid}`);
  let url = `${BASE_URL}api/v2/teamchallenge/_table/event?filter=(${filter})&related=eventleg_by_eventidfk,eventclient_by_eventidfk&eventclient_by_eventidfk.fields=id,clientidfk`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SINGLE_EVENT_DETAILS,
          payload: { data: response.resource[0] },
        });
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export function clearSingleEvent() {
  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_DETAILS,
      payload: {},
    });
  };
}

export function openEventUrl(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_URL_MODAL,
      payload: obj,
    });
  };
}

export const fetchEventAllClients = (
  session,
  eventid,
  related = "",
  callback = null
) => {
  let filter = encodeURI(`(eventidfk=${eventid})AND(isdeleted=0)`);

  let relatedTable = "";
  if (related !== "") {
    relatedTable = `&related=${related}`;
  }

  let url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient?filter=(${filter})${relatedTable}&include_count=true&order=createdon%20DESC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SINGLE_EVENT_ALL_CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export const fetchEventClients = (session, eventid, callback = null) => {
  let filter = encodeURI(`(eventidfk=${eventid})AND(isdeleted=0)`);
  let url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient?filter=(${filter})&include_count=true&order=createdon%20DESC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        const payloadObj = {
          data: response.resource,
          count: response.meta.count,
        };
        dispatch({
          type: SINGLE_EVENT_CLIENTS,
          payload: payloadObj,
        });
        callback && callback({ success: 1, ...payloadObj });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export const searchEventClients = (
  session,
  search,
  eventid,
  callback = null
) => {
  let url = `${BASE_URL}api/v2/custom_script?type=searcheventclient&eventidfk=${eventid}&search=${search}&include_count=true`;

  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_CLIENTS,
      payload: { currentSearch: search },
    });
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SINGLE_EVENT_CLIENTS,
          payload: {
            data: response.resource,
            count: response.meta.count,
            lastSearch: search,
          },
        });
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export const clearEventClients = () => {
  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_CLIENTS,
      payload: {},
    });
  };
};

export const fetchEventRegistrationRequest = (
  session,
  eventid,
  pageCount = 0,
  callback = null
) => {
  let perPage = CUSTOM_ITEM_COUNT;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offSetQuery = `limit=${perPage}&offset=${offset}`;
  let filter = encodeURI(`eventidfk=${eventid}`);

  let url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests?related=client_info_by_schoolid&filter=(${filter})&include_count=true&related=*&order=createdon%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        const payloadObj = {
          data: response.resource,
          count: response.meta.count,
        };
        dispatch({
          type: SINGLE_EVENT_REGISTRATION_REQUEST,
          payload: payloadObj,
        });
        callback && callback({ success: 1, error: null, ...payloadObj });
      },
      (error) => {
        callback && callback({ success: 0, error });
      }
    );
  };
};

export const clearEventRegistrationRequest = () => {
  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_REGISTRATION_REQUEST,
      payload: {},
    });
  };
};

export function requestedUserEmailModal(obj) {
  return (dispatch) => {
    dispatch({
      type: REQUESTED_USER_EMAIL_UPPDATE_MODAL,
      payload: obj,
    });
  };
}

export function eventClientListModal(obj) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CLIENT_LIST_MODAL,
      payload: obj,
    });
  };
}

export function updateIntrestedUsers(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests`;
  var body = { resource: values };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchSingleRequestedUser(session, id, callback) {
  var filter = encodeURI(`id=${id}`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests?related=client_info_by_schoolid&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)

        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export const sendEmailtoAssignedIntrestedUsers = (
  session,
  values,
  callback
) => {
  var url = `${BASE_URL}api/v2/sendemail`;
  var body = {
    type: "class_registration",
    registrationid: values,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
};

export const fetchEventTeams = (session, eventid, callback = null) => {
  let filter = encodeURI(`eventidfk=${eventid}`);
  let relateds =
    "eventclient_by_eventclientidfk,teammembers_by_teamidfk,registrationrequests_by_teamidfk&teammembers_by_teamidfk.fields=id,dfid,teamidfk,firstname,lastname,username,gender,ismanager,withdrawndate,isremoved&registrationrequests_by_teamidfk.fields=id,email,status";
  let orderBy = "createdon%20DESC";
  let url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})&include_count=true&related=${relateds}&order=${orderBy}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        const payloadObj = {
          data: response.resource,
          count: response.meta.count,
        };
        dispatch({
          type: SINGLE_EVENT_TEAMS,
          payload: payloadObj,
        });
        callback && callback({ success: 1, error: null, ...payloadObj });
      },
      (error) => {
        callback && callback({ success: 0, error, data: null });
      }
    );
  };
};

export const searchEventTeams = (
  session,
  search,
  eventid,
  pageCount = 0,
  callback = null
) => {
  let perPage = CUSTOM_ITEM_COUNT;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offSetQuery = `limit=${perPage}&offset=${offset}`;
  let searchQuery = `(teamname like %${search}%)`;
  let filter = encodeURI(`(eventidfk=${eventid})AND(${searchQuery})`);
  let relateds =
    "eventclient_by_eventclientidfk,teammembers_by_teamidfk,registrationrequests_by_teamidfk&teammembers_by_teamidfk.fields=id,dfid,teamidfk,firstname,lastname,username,gender,ismanager,withdrawndate,isremoved&registrationrequests_by_teamidfk.fields=id,email,status";
  let orderBy = "createdon%20DESC";

  let url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})&include_count=true&related=${relateds}&order=${orderBy}&${offSetQuery}`;

  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_TEAMS,
      payload: { currentSearch: search },
    });

    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SINGLE_EVENT_TEAMS,
          payload: {
            data: response.resource,
            count: response.meta.count,
            lastSearch: search,
          },
        });

        callback && callback({ success: 1, error: null });
      },
      (error) => {
        callback && callback({ success: 0, error });
      }
    );
  };
};

export const clearEventTeams = () => {
  return (dispatch) => {
    dispatch({
      type: SINGLE_EVENT_TEAMS,
      payload: {},
    });
  };
};

export function openAddEventClientModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_EVENT_CLIENT_MODAL,
      payload: obj,
    });
  };
}

export function addEventClient(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateEventClient(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteEventClient(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const selectedClient = (obj) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_CLIENT,
      payload: obj,
    });
  };
};

export const clearSelectedClient = (obj) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_CLIENT,
      payload: obj,
    });
  };
};

export function createTeam(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team`;
  var body = { resource: values };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (data) => {
        callback({ success: 1, resource: data?.resource });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function openAddTeamModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MODAL,
      payload: obj,
    });
  };
}

export function updateTeam(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/team`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function withdrawAllTeamMember(session, teamid = null, date, callback) {
  var filter = encodeURI(`(teamidfk=${teamid})AND(withdrawndate IS NULL)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
  var body = { resource: [{ withdrawndate: date }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const sendWithdrawEmail = (session, id = null, callback = null) => {
  let url = `${BASE_URL}api/v2/sendemail`;
  let body = {
    type: "team_withdrawn",
    registrationid: id,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback && callback({ success: 1 });
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
};

export function openViewTeamModal(obj) {
  return (dispatch) => {
    dispatch({
      type: VIEW_TEAM_MODAL,
      payload: obj,
    });
  };
}

export function selectedTeam(obj) {
  return (dispatch) => {
    dispatch({
      type: SELECTED_TEAM,
      payload: obj,
    });
  };
}

export const fetchSelectedTeam = (session, teamid, callback = null) => {
  let filter = encodeURI(`id=${teamid}`);
  let url = `${BASE_URL}api/v2/teamchallenge/_table/team?related=event_by_eventidfk,eventclient_by_eventclientidfk&filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SELECTED_TEAM,
          payload: response.resource[0],
        });
        callback && callback({ success: 1, error: null });
      },
      (error) => {
        callback && callback({ success: 0, error });
      }
    );
  };
};

export function clearSelectedTeam(obj) {
  return (dispatch) => {
    dispatch({
      type: SELECTED_TEAM,
      payload: obj,
    });
  };
}

export const fetchTeamClientEntrantCount = (
  session,
  teamid,
  callback = null
) => {
  let url = `${BASE_URL}api/v2/custom_script`;
  let body = {
    type: "eventcliententrantcount",
    teamid: teamid,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: TEAM_CLIENT_ENTRANT_COUNT,
          payload: { data: response },
        });
        callback && callback({ success: 1, data: response });
      },
      (error) => {
        callback && callback({ success: 0, error: error });
      }
    );
  };
};

export const fetchTeamMembers = (
  session,
  eventid,
  teamid,
  pageCount = 0,
  callback = null
) => {
  let perPage = CUSTOM_ITEM_COUNT;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offSetQuery = `limit=${perPage}&offset=${offset}`;
  let filter = encodeURI(
    `(eventidfk=${eventid})AND(teamidfk=${teamid})AND(isremoved=0)`
  );
  let orderby = `ismanager%20DESC%2C%20createdon%20DESC`;

  let url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})&include_count=true&related=eventclient_by_eventclientidfk&order=${orderby}&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        dispatch({
          type: SELECTED_TEAM_MEMBERS,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback && callback({ success: 1, error: null });
      },
      (error) => {
        callback && callback({ success: 0, error });
      }
    );
  };
};

export function openViewTeamMemberModal(obj) {
  return (dispatch) => {
    dispatch({
      type: VIEW_TEAM_MEMBER_MODAL,
      payload: obj,
    });
  };
}

export function openAddTeamMemberModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_TEAM_MEMBER_MODAL,
      payload: obj,
    });
  };
}

export const createTeamMember = (session, values, callback = null) => {
  let url = `${BASE_URL}api/v2/custom_script`;
  let body = { ...values, type: "createmember" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (response.is_error) {
          callback && callback({ success: 0 });
        } else {
          callback && callback({ success: 1 });
        }
      },
      (error) => {
        callback && callback({ success: 0 });
      }
    );
  };
};

export const updateTeamMember = (session, values, callback) => {
  const url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers`;

  let memberPatchBody = _.cloneDeep(values);
  delete memberPatchBody.password;
  let memberPatchBodyResource = { resource: [{ ...memberPatchBody }] };

  return (dispatch) => {
    if (values?.setpassword || values?.username) {
      const systemUserUrl = `${BASE_URL}api/v2/system/user`;
      const payloadObj = {
        ...(values?.setpassword && values?.username
          ? { email: values?.username, password: values.setpassword }
          : values?.setpassword
          ? { password: values.setpassword }
          : values?.username
          ? { email: values?.username }
          : {}),
      };

      if (!values?.setpassword && values?.username) {
        const systemUserFilter = `(email=${values?.username})`;

        getRequest(
          `${systemUserUrl}?include_count=true&filter=${systemUserFilter}`,
          session,
          dispatch,
          (fetchRes) => {
            if (fetchRes?.meta?.count) {
              const newUserNameDFid = fetchRes?.resource?.[0]?.id;

              patchRequest(
                url,
                {
                  resource: [
                    {
                      ...memberPatchBody,
                      dfid: newUserNameDFid,
                    },
                  ],
                },
                session,
                dispatch,
                (response) => {
                  // if (values?.setpassword) {
                  // const filter = encodeURI(`dfid=${newUserNameDFid}`);
                  // const DFUrl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
                  // const DFBody = {
                  //   resource: [{ password: values.setpassword }],
                  // };
                  // patchRequest(
                  //   DFUrl,
                  //   DFBody,
                  //   session,
                  //   dispatch,
                  //   (response) => {
                  //     callback({ success: 1 });
                  //   },
                  //   (error) => {
                  //     callback({ success: 0 });
                  //   }
                  // );
                  // } else {
                  callback({ success: 1 });
                  // }
                },
                (error) => {
                  callback({ success: 0 });
                }
              );
            } else {
              patchRequest(
                `${systemUserUrl}/${values.dfid}`,
                payloadObj,
                session,
                dispatch,
                (response) => {
                  patchRequest(
                    url,
                    { resource: [{ ...memberPatchBody }] },
                    session,
                    dispatch,
                    (response) => {
                      // if (values?.setpassword) {
                      // const filter = encodeURI(`dfid=${values.dfid}`);
                      // const DFUrl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
                      // const DFBody = {
                      //   resource: [{ password: values.setpassword }],
                      // };
                      // patchRequest(
                      //   DFUrl,
                      //   DFBody,
                      //   session,
                      //   dispatch,
                      //   (response) => {
                      //     callback({ success: 1 });
                      //   },
                      //   (error) => {
                      //     callback({ success: 0 });
                      //   }
                      // );
                      // } else {
                      callback({ success: 1 });
                      // }
                    },
                    (error) => {
                      callback({ success: 0 });
                    }
                  );
                },
                (error) => {
                  if (error?.code === 400) {
                    callback({ success: 2, message: error?.context });
                  } else {
                    callback({ success: 3, message: error });
                  }
                }
              );
            }
          },
          (error) => {
            callback({ success: 0 });
          }
        );
      } else {
        patchRequest(
          `${systemUserUrl}/${values.dfid}`,
          payloadObj,
          session,
          dispatch,
          (response) => {
            patchRequest(
              url,
              memberPatchBodyResource,
              session,
              dispatch,
              (response) => {
                // if (values?.setpassword) {
                // const filter = encodeURI(`dfid=${values.dfid}`);
                // const DFUrl = `${BASE_URL}api/v2/teamchallenge/_table/teammembers?filter=(${filter})`;
                // const DFBody = {
                //   resource: [{ password: values.setpassword }],
                // };
                // patchRequest(
                //   DFUrl,
                //   DFBody,
                //   session,
                //   dispatch,
                //   (response) => {
                //     callback({ success: 1 });
                //   },
                //   (error) => {
                //     callback({ success: 0 });
                //   }
                // );
                // } else {
                callback({ success: 1 });
                // }
              },
              (error) => {
                callback({ success: 0 });
              }
            );
          },
          (error) => {
            if (error?.code === 400) {
              callback({ success: 2, message: error?.context });
            } else {
              callback({ success: 3, message: error });
            }
          }
        );
      }
    } else {
      patchRequest(
        url,
        memberPatchBodyResource,
        session,
        dispatch,
        (response) => {
          callback({ success: 1 });
        },
        (error) => {
          callback({ success: 0 });
        }
      );
    }
  };
};

export function withdrawTeamMember(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/teammembers`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function deleteTeamMemberSession(session, dfid, callback) {
  var url = `${BASE_URL}api/v2/custom_script`;
  var body = { dfid: dfid, type: "deleteusersession" };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        // callback({ success: 0 });
      }
    );
  };
}

export function fetchDownloadUsersInfo(session, eventid, option, callback) {
  switch (option) {
    case "1":
      var filter = encodeURI(`(eventidfk=${eventid})AND(isdeleted=0)`);
      var url = `${BASE_URL}api/v2/teamchallenge/_table/eventclient?filter=(${filter})&include_count=true&order=createdon%20DESC`;
      break;

    case "2":
      var filter = encodeURI(`(eventidfk=${eventid})AND(isremoved=0)`);
      var url = `${BASE_URL}api/v2/teamchallenge/_table/registrationrequests?related=client_info_by_schoolid&filter=(${filter})&include_count=true&order=createdon%20DESC`;
      break;

    case "3":
      var filter = encodeURI(`eventidfk=${eventid}`);
      var url = `${BASE_URL}api/v2/teamchallenge/_table/team?filter=(${filter})&include_count=true&related=eventclient_by_eventclientidfk,teammembers_by_teamidfk&teammembers_by_teamidfk.fields=id,dfid,teamidfk,membertype,firstname,lastname,username,gender,ismanager,mobile,withdrawndate,isremoved&order=createdon%20DESC`;
      break;

    default:
      break;
  }

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        let allDetails = [];
        _.map(response.resource, (singleEvCl, key) => {
          let obj = {};

          switch (option) {
            case "1":
              obj = {
                "Client Id": singleEvCl.clientidfk,
                "Client Name": singleEvCl.clients_by_clientidfk.name,
                "Max Member": singleEvCl.totalmembers,
                "Members Required": singleEvCl.maxteammember,
                Confirmed: singleEvCl.confirmedmembers,
              };
              break;
            case "2":
              obj = {
                "First Name": singleEvCl.firstname,
                "Last Name": singleEvCl.lastname,
                Email: singleEvCl.email,
                School: singleEvCl.client_info_by_schoolid?.orgname,
                Entrants: singleEvCl.noofmembers,
                Region: singleEvCl?.region ?? "",
                Mobile: singleEvCl?.mobile ?? "",
                Year: singleEvCl?.year ?? "",
                Status: singleEvCl.status,
              };
              break;
            case "3":
              var teacher = _.filter(
                singleEvCl?.teammembers_by_teamidfk,
                (obj) => {
                  return obj?.membertype === "teacher" && obj?.ismanager == 1;
                }
              );

              obj = {
                "Client Name":
                  singleEvCl?.eventclient_by_eventclientidfk
                    ?.clients_by_clientidfk?.name,
                "Team Name": singleEvCl?.teamname,
                "Team Size": singleEvCl?.teammembers_by_teamidfk.length,
                "Entrant Id": teacher?.[0]?.id,
                "Team Id": singleEvCl?.id,
                "First Name": teacher?.[0]?.firstname,
                "Last Name": teacher?.[0]?.lastname,
                Email: teacher?.[0]?.username,
                Mobile: teacher?.[0]?.mobile,
                "May we contact you": singleEvCl?.consent ? "Y" : "N",
                "Accepted Terms": singleEvCl?.confirmeddate
                  ? convertDateTimeIntoLocal(singleEvCl?.confirmeddate)
                  : "",
                "Pedomerts Requirement":
                  singleEvCl?.pedometersrequired === "forsome"
                    ? "Some"
                    : singleEvCl?.pedometersrequired === "forall"
                    ? "All"
                    : "None",
                Address: singleEvCl?.address,
                Suburb: singleEvCl?.suburb,
                Region: singleEvCl?.region,
                City: singleEvCl?.city,
                "Postal Code": singleEvCl?.postalcode,
              };
              break;
            default:
              break;
          }

          allDetails.push(obj);
        });
        callback({ success: 1, data: allDetails });
      },
      (error) => {}
    );
  };
}

export const fetchFilteredTeamCSV = (session, eventid, callback) => {
  let url = `${BASE_URL}api/v2/custom_script?type=filtered_team_csv&eventidfk=${eventid}`;

  return (dispatch) => {
    postRequest(
      url,
      null,
      session,
      dispatch,
      (res) => {
        callback({ success: 1, data: res?.resource, error: null });
      },
      (error) => {
        callback({ success: 0, data: null, error });
      }
    );
  };
};

export const fetchTemplates = (session, eventid, pageCount = 0, callback) => {
  let perPage = itemCount;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offSetQuery = `limit=${perPage}&offset=${offset}`;
  let filter = encodeURI(`eventidfk=${eventid}`);
  let url = `${BASE_URL}api/v2/teamchallenge/_table/eventemailtemplates?filter=(${filter})&include_count=true&order=id%20DESC&${offSetQuery}`;

  return (dispatch) => {
    dispatch({
      type: EVENT_TEMPLATE_LIST,
      payload: {},
    });
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: EVENT_TEMPLATE_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
};

export const addEmailTemplate = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventemailtemplates`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function updateTemplate(session, id, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/eventemailtemplates/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export const editTemplate = (resource = {}) => {
  return (dispatch) => {
    dispatch({
      type: EVENT_TEMPLATE_EDIT,
      payload: resource,
    });
  };
};
