import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import * as XLSX from "xlsx";
import classnames from "classnames";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// constants
import {
  convertDateTimeIntoLocal,
  COMMON_FAIL_MESSAGE,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";

// actions
import {
  fetchSingleEvent,
  fetchEventClients,
  fetchEventTeams,
  fetchEventRegistrationRequest,
  openEventUrl,
  fetchDownloadUsersInfo,
  fetchFilteredTeamCSV,
} from "../../actions";
import {
  fetchTimezones,
  fetchCoachs,
  fetchCoachEmails,
  fetchSystemEmails,
} from "../../../../actions";

// components
import Header from "../../../Headers/Header";
import EventClients from "./event-clients";
import EventRegistrationRequest from "./event-registration-request";
import EventTeams from "./event-teams";
import AddEmailOptions from "./email-options-add";
import EventUrls from "./event-urls";
import EventTemplate from "./email-template";
import { CommonGoBack } from "components/Common/base-components";

const EventManage = (props) => {
  const {
    session,
    deviceSize,
    SingleEventDetails,
    ManageEmailRelatedModal,
    EventUrlModal,
    fetchDownloadUsersInfo,
  } = props;

  const eventid = props?.match?.params?.eventid
    ? props?.match?.params?.eventid
    : null;
  const navItems = [
    {
      id: "1",
      title: LocaleStrings.events_details_tab_client,
    },
    {
      id: "2",
      title: LocaleStrings.events_details_tab_school_regi,
    },
    {
      id: "3",
      title: LocaleStrings.events_details_tab_teams,
    },
    {
      id: "4",
      title: LocaleStrings.email_template,
    },
  ];

  const [activeTab, setActiveTab] = useState("1");
  const [clientloading, setClientLoading] = useState(false);
  const [requestloading, setRequestLoading] = useState(false);
  const [teamloading, setTeamLoading] = useState(false);
  const [confirmTeamCSVLoading, setConfirmTeamCSVLoading] = useState(false);

  useEffect(() => {
    props.fetchSingleEvent(session, eventid);
    props.fetchCoachs(session);
    props.fetchCoachEmails(session);
    props.fetchSystemEmails(session);
  }, []);

  const _toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const openEventUrlModal = () => {
    props.openEventUrl({ showModal: true });
  };

  const additionCallbackTeam = () => {
    props.fetchEventTeams(session, eventid);
  };

  const downloadCSV = (option) => {
    if (teamloading || requestloading || clientloading || confirmTeamCSVLoading)
      return;

    switch (option) {
      case "3":
        setTeamLoading(true);
        fetchDownloadUsersInfo(session, eventid, option, (response) => {
          setTeamLoading(false);
          if (response.success) {
            JSONToCSVConvertor(response.data, eventid, option);
            showSuccess(LocaleStrings.events_team_csv_download_alert_success);
          } else {
            showError(COMMON_FAIL_MESSAGE);
          }
        });
        break;

      case "2":
        setRequestLoading(true);
        fetchDownloadUsersInfo(session, eventid, option, (response) => {
          setRequestLoading(false);
          if (response.success) {
            JSONToCSVConvertor(response.data, eventid, option);
            showSuccess(LocaleStrings.events_team_csv_download_alert_success);
          } else {
            showError(COMMON_FAIL_MESSAGE);
          }
        });
        break;

      case "1":
        setClientLoading(true);
        fetchDownloadUsersInfo(session, eventid, option, (response) => {
          setClientLoading(false);
          if (response.success) {
            JSONToCSVConvertor(response.data, eventid, option);
            showSuccess(LocaleStrings.events_team_csv_download_alert_success);
          } else {
            showError(COMMON_FAIL_MESSAGE);
          }
        });
        break;

      case "filtered":
        setConfirmTeamCSVLoading(true);
        props.fetchFilteredTeamCSV(session, eventid, (res) => {
          setConfirmTeamCSVLoading(false);
          if (res.success) {
            JSONToCSVConvertor(res.data, eventid, "filtered");
            showSuccess(LocaleStrings.events_team_csv_download_alert_success);
          } else showError(COMMON_FAIL_MESSAGE);
        });
        break;

      default:
        break;
    }
  };

  const JSONToCSVConvertor = (JSONData, eventid, option) => {
    var obj = { teaminfo: JSONData };

    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();

    var sheetName = "Sheet";
    switch (option) {
      case "1":
        var sheetName = "Client Info";
        break;
      case "2":
        var sheetName = "Request Info";
        break;
      case "3":
        var sheetName = "All Registered Teams";
        break;
      case "filtered":
        var sheetName = "All Confirmed Teams";
        break;

      default:
        break;
    }

    var ws = XLSX.utils.json_to_sheet(obj.teaminfo);

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    switch (option) {
      case "1":
        XLSX.writeFile(wb, `client_details_for_event_id_${eventid}.xlsx`);
        break;

      case "2":
        XLSX.writeFile(wb, `client_request_for_event_id_${eventid}.xlsx`);
        break;

      case "3":
        XLSX.writeFile(
          wb,
          `registered_team_details_for_event_id_${eventid}.xlsx`
        );
        break;
      case "filtered":
        XLSX.writeFile(
          wb,
          `confirmed_team_details_for_event_id_${eventid}.xlsx`
        );
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--6" fluid>
        <CommonGoBack />
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              {/* - Event name, type, duration - Export CSV - Event URLS - */}
              <CardHeader className="">
                <div className="pull-left">
                  <h3>{SingleEventDetails?.data?.name}</h3>
                  <div className="text-sm text-capitalize mb-2">
                    {LocaleStrings.events_text_event_type}:{" "}
                    {SingleEventDetails?.data?.type}
                  </div>
                  <div className="text-sm text-capitalize">
                    {convertDateTimeIntoLocal(
                      SingleEventDetails?.data?.activestart
                    )}{" "}
                    -{" "}
                    {convertDateTimeIntoLocal(
                      SingleEventDetails?.data?.activeend
                    )}
                  </div>
                </div>
                <div className="pull-right">
                  <Button
                    className="float-right"
                    color="primary"
                    size="sm"
                    onClick={openEventUrlModal}
                  >
                    {LocaleStrings.button_event_urls}
                  </Button>
                  {activeTab !== "4" ? (
                    <Button
                      className="float-right mr-2 pr-3"
                      color="primary"
                      size="sm"
                      onClick={
                        (activeTab === "3" && teamloading) ||
                        (activeTab === "2" && requestloading) ||
                        (activeTab === "1" && clientloading)
                          ? null
                          : () => downloadCSV(activeTab)
                      }
                    >
                      <i
                        className={
                          (activeTab === "3" && teamloading) ||
                          (activeTab === "2" && requestloading) ||
                          (activeTab === "1" && clientloading)
                            ? `fa fa-refresh fa-spin`
                            : ""
                        }
                      ></i>
                      &ensp;
                      {activeTab === 3
                        ? LocaleStrings.button_export_to_csv_all
                        : LocaleStrings.button_export_to_csv}
                    </Button>
                  ) : (
                    ""
                  )}
                  {activeTab === "3" && (
                    <Button
                      type="button"
                      className="float-right"
                      color="primary"
                      size="sm"
                      disabled={confirmTeamCSVLoading}
                      onClick={() =>
                        confirmTeamCSVLoading ? {} : downloadCSV("filtered")
                      }
                    >
                      <i
                        className={
                          confirmTeamCSVLoading ? `fa fa-refresh fa-spin` : ""
                        }
                      ></i>
                      {LocaleStrings.button_export_to_csv_filtered}
                    </Button>
                  )}
                </div>
              </CardHeader>

              <CardBody>
                <Nav tabs className="">
                  {_.map(navItems, (item, itemKey) => {
                    return (
                      <NavItem key={`tab_${item.id}_key_${itemKey}`}>
                        <NavLink
                          className={classnames({
                            active: activeTab === item.id,
                          })}
                          onClick={() => {
                            _toggle(item.id);
                          }}
                        >
                          {item.title}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane
                    tabId="1"
                    style={{ height: deviceSize.height - 300 }}
                  >
                    {activeTab == 1 ? (
                      <EventClients
                        eventid={eventid}
                        eventDetails={SingleEventDetails?.data}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane
                    tabId="2"
                    style={{ height: deviceSize.height - 300 }}
                  >
                    {activeTab == 2 ? (
                      <EventRegistrationRequest eventid={eventid} />
                    ) : null}
                  </TabPane>
                  <TabPane
                    tabId="3"
                    style={{ height: deviceSize.height - 300 }}
                  >
                    {activeTab == 3 ? (
                      <EventTeams
                        eventid={eventid}
                        finishOperationsCallback={additionCallbackTeam}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane
                    tabId="4"
                    style={{ minHeight: deviceSize.height - 300 }}
                  >
                    {activeTab == 4 ? (
                      <EventTemplate eventid={eventid} />
                    ) : null}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {ManageEmailRelatedModal?.showModal ? <AddEmailOptions /> : ""}
      {EventUrlModal?.showModal ? <EventUrls /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    SingleEventDetails: state.SingleEventDetails,
    ManageEmailRelatedModal: state.ManageEmailRelatedModal,
    EventUrlModal: state.EventUrlModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSingleEvent,
      fetchEventClients,
      fetchEventTeams,
      fetchEventRegistrationRequest,
      fetchTimezones,
      fetchCoachs,
      fetchCoachEmails,
      fetchSystemEmails,
      openEventUrl,
      fetchDownloadUsersInfo,
      fetchFilteredTeamCSV,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventManage);
