import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PaginationComponent from "./pagination-component";
import LocaleStrings from "../../../languages";

const PaginationList = (props) => {
  var {
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    pageRangeDisplayed,
  } = props;
  const callPagination = (pageNumber) => {
    if (pageNumber !== activePage) {
      props.onChange(pageNumber);
    }
  };

  const callPaginationPrevious = () => {
    var call = activePage - 1;
    callPagination(call);
  };

  const callPaginationNext = () => {
    var call = activePage + 1;
    callPagination(call);
  };

  const renderPagination = () => {
    let totalPageCount = Math.ceil(totalItemsCount / itemsCountPerPage);
    var pageCount = Math.ceil(totalItemsCount / itemsCountPerPage);
    pageCount = pageCount > pageRangeDisplayed ? pageRangeDisplayed : pageCount; // check page number range

    var startFrom = 1;
    // startFrom = activePage > pageRangeDisplayed ? (activePage-pageRangeDisplayed)+startFrom : startFrom;
    startFrom =
      activePage > pageRangeDisplayed
        ? activePage - pageRangeDisplayed + startFrom
        : startFrom;

    var endTo = pageCount;
    endTo = activePage > pageRangeDisplayed ? activePage : endTo;
    // endTo = activePage > pageRangeDisplayed ? totalPageCount == activePage ? totalPageCount : Math.ceil((endTo+pageRangeDisplayed)-1) : endTo;

    var paginationItems = [];
    paginationItems.push(
      <PaginationItem
        key="previous"
        className={activePage == "1" ? "disabled" : ""}
      >
        <PaginationLink onClick={callPaginationPrevious}>
          <i className="fas fa-angle-left" />
          <span className="sr-only">{LocaleStrings.previous}</span>
        </PaginationLink>
      </PaginationItem>
    );
    for (var pageNumber = startFrom; pageNumber <= endTo; pageNumber++) {
      var isactive = activePage === pageNumber;
      paginationItems.push(
        <PaginationComponent
          key={pageNumber.toString()}
          active={isactive}
          pageNumber={pageNumber}
          callback={callPagination}
        />
      );
    }
    paginationItems.push(
      <PaginationItem
        key="next"
        className={activePage === totalPageCount ? "disabled" : ""}
      >
        <PaginationLink onClick={callPaginationNext}>
          <i className="fas fa-angle-right" />
          <span className="sr-only">{LocaleStrings.next}</span>
        </PaginationLink>
      </PaginationItem>
    );
    return paginationItems;
  };

  return (
    <Pagination
      className="pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      {renderPagination()}
    </Pagination>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaginationList);
