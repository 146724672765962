import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, Container, Row } from "reactstrap";
import Header from "../../components/Headers/Header";
import LocaleStrings from "../../languages";

const EmailTemplates = (props) => {
  var { session } = props;

  useEffect(() => {}, []);

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0 h-100vh">Email Templates</Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
