import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  activateDeactivateClient,
  openClientModal,
  editClientData,
} from "../actions";
import { COMMON_FAIL_MESSAGE } from "../../Common/constant";
import { showSuccess, showError } from "../../Common/alertmessage";
import LocaleStrings from "../../../languages";

const ClientList = (props) => {
  var { printList, currentPage, session, activeTab } = props;

  const activateDeactivate = () => {
    let values = {
      clientid: printList.clientid,
      isactive: printList.isactive ? 0 : 1,
    };

    confirmAlert({
      title: printList.isactive
        ? LocaleStrings.button_deactivate
        : LocaleStrings.button_activate,
      message: printList.isactive
        ? LocaleStrings.clients_deactivated_subtitle
        : LocaleStrings.clients_activated_subtitle,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.activateDeactivateClient(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                props.pagination(currentPage);
                showSuccess(
                  printList.isactive
                    ? LocaleStrings.clients_alert_event_deactivated
                    : LocaleStrings.clients_alert_event_activated
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const editClient = (item) => {
    props.editClientData(item);
    props.openClientModal({ showModal: true });
  };

  var isDeactive = printList?.isactive ? "" : "deactivated";

  return (
    <tr className={isDeactive}>
      <td>{printList.clientid}</td>
      <td>{printList?.client_info_by_referenceid?.orgname ?? "-"}</td>
      <td>{printList.name}</td>
      <td className="text-capitalize">{printList.type}</td>
      {/* <td>{printList.adminname}</td> */}
      <td>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {activeTab == "active" ? (
              <DropdownItem onClick={() => editClient(printList)}>
                {LocaleStrings.button_edit}
              </DropdownItem>
            ) : (
              ""
            )}
            <DropdownItem onClick={activateDeactivate}>
              {printList?.isactive
                ? LocaleStrings.button_deactivate
                : LocaleStrings.button_activate}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { activateDeactivateClient, openClientModal, editClientData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
