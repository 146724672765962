import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import { openAddTeamModal, updateTeam } from "../../actions";
import { renderFieldText } from "../../../Common/base-components";
import {
  LABEL_POSITION_TOP,
  COMMON_FAIL_MESSAGE,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";

const AddEventTeams = (props) => {
  var { session, AddTeamModal, handleSubmit, pristine, invalid, submitting } =
    props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.openAddTeamModal({ showModal: false });
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);

    setLoading(true);
    props.updateTeam(session, values, (response) => {
      // console.log('response',response);
      setLoading(false);
      if (response.success === 0) {
        showError(COMMON_FAIL_MESSAGE);
      } else {
        showSuccess(LocaleStrings.events_team_form_updated_success);
        props.finishOperationsCallback();
        closeModal();
      }
    });
  };

  return (
    <>
      <Modal className="" isOpen={AddTeamModal?.showModal ? true : false}>
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_team_edit_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <Field
              name="teamname"
              label={LocaleStrings.events_team_form_label_teamname}
              placeholder={LocaleStrings.events_team_form_ph_teamname}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="roomno"
              label={LocaleStrings.events_team_form_label_roomno}
              placeholder={LocaleStrings.events_team_form_ph_roomno}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="schoolname"
              label={LocaleStrings.events_team_form_label_school}
              placeholder={LocaleStrings.events_team_form_ph_school}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="address"
              label={LocaleStrings.events_team_form_label_address}
              placeholder={LocaleStrings.events_team_form_ph_address}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="suburb"
              label={LocaleStrings.events_team_form_label_suburb}
              placeholder={LocaleStrings.events_team_form_ph_suburb}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="city"
              label={LocaleStrings.events_team_form_label_city}
              placeholder={LocaleStrings.events_team_form_ph_city}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="postalcode"
              label={LocaleStrings.events_team_form_label_postalcode}
              placeholder={LocaleStrings.events_team_form_ph_postalcode}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="region"
              label={LocaleStrings.events_team_form_label_region}
              placeholder={LocaleStrings.events_team_form_ph_region}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
          </div>

          <div className="modal-footer">
            <Button color="secondary" onClick={closeModal}>
              {LocaleStrings.button_close}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={pristine || invalid || submitting || loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ""}{" "}
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var teamname = values["teamname"];
  var roomno = values["roomno"];
  var schoolname = values["schoolname"];
  var address = values["address"];
  var suburb = values["suburb"];
  var city = values["city"];
  var postalcode = values["postalcode"];
  var region = values["region"];

  if (!teamname || teamname.trim() === "") {
    errors["teamname"] = LocaleStrings.requird;
  }
  if (!roomno || roomno.trim() === "") {
    errors["roomno"] = LocaleStrings.requird;
  }
  if (!schoolname || schoolname.trim() === "") {
    errors["schoolname"] = LocaleStrings.requird;
  }
  if (!address || address.trim() === "") {
    errors["address"] = LocaleStrings.requird;
  }
  if (!suburb || suburb.trim() === "") {
    errors["suburb"] = LocaleStrings.requird;
  }
  if (!city || city.trim() === "") {
    errors["city"] = LocaleStrings.requird;
  }
  if (!postalcode) {
    errors["postalcode"] = LocaleStrings.requird;
  }
  if (!region || region.trim() === "") {
    errors["region"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.AddTeamModal?.value);
  var initVals = {};

  if (toEdit) {
    initVals = state?.AddTeamModal?.value;
  }

  return {
    session: state.session,
    AddTeamModal: state.AddTeamModal,
    initialValues: initVals,
    toEdit,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openAddTeamModal, updateTeam }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "TeamForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddEventTeams)
);
