import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { storeAddEventFormData, checkSubdomain } from "../actions";
import { openEmailRelatedModal } from "../../../actions";
import {
  renderSelect,
  renderSelectWithButton,
  renderFieldText,
  renderFieldCheckbox,
} from "../../Common/base-components";
import { showSuccess, showError } from "../../Common/alertmessage";
import { LABEL_POSITION_TOP, COMMON_FAIL_MESSAGE } from "../../Common/constant";
import LocaleStrings from "../../../languages";

const AddEventStep1 = (props) => {
  var {
    session,
    toEdit,
    coachList,
    coachEmails,
    systemEmails,
    distributorsList,
    EventFormData,
  } = props;

  const onManageEmailRelated = (type) => {
    // console.log("type :- ", type);
    props.openEmailRelatedModal({ showModal: true, type: type });
  };

  const haldelEventTpe = (e) => {
    var type = e.target.value;

    props.storeAddEventFormData({
      ...EventFormData,
      eventType: type,
    });
  };

  const checkAvailability = (e) => {
    var inputVal = e.target.value;

    if (inputVal !== "") {
      props.checkSubdomain(session, inputVal, (response) => {
        // console.log("response :- ", response);
        if (response.success == 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else if (response.success == 2) {
          showError(
            `"${inputVal}" ${LocaleStrings.event_form_error_subdomain_not_available}`
          );
        } else {
          showSuccess(LocaleStrings.event_form_success_subdomain_available);
        }
      });
    }
  };

  let eventtype = [
    { text: LocaleStrings.events_form_type_option1, value: "school" },
    { text: LocaleStrings.events_form_type_option2, value: "company" },
  ];
  let eventapp = [
    { text: LocaleStrings.events_form_app_option1, value: "virtualadventure" },
    { text: LocaleStrings.events_form_app_option2, value: "azion" },
  ];
  var coachArr = [];
  _.map(coachList?.data, (item) => {
    var obj = { text: item.name, value: item.coachid };
    coachArr.push(obj);
  });

  var coachEmailsArr = [];
  _.map(coachEmails?.data, (item) => {
    var obj = { text: item.email, value: item.coachemailid };
    coachEmailsArr.push(obj);
  });

  var syatemEmailsArr = [];
  _.map(systemEmails?.data, (item) => {
    var obj = { text: item.email, value: item.systememailid };
    syatemEmailsArr.push(obj);
  });
  var distributorArr = [];
  _.map(distributorsList?.data, (item) => {
    var obj = { text: item.name, value: item.distributorid };
    distributorArr.push(obj);
  });

  return (
    <>
      <Field
        name="type"
        label={LocaleStrings.events_form_label_type}
        placeholder={LocaleStrings.events_form_ph_type}
        mandatory="true"
        component={renderSelect}
        labelposition={LABEL_POSITION_TOP}
        opts={eventtype}
        onChange={haldelEventTpe}
        disabled={toEdit}
      />
      <Field
        name="name"
        label={LocaleStrings.events_form_label_name}
        placeholder={LocaleStrings.events_form_ph_name}
        type="text"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
      />
      <Field
        name="subdomain"
        label={LocaleStrings.events_form_label_subdomain}
        placeholder={LocaleStrings.events_form_ph_subdomain}
        type="text"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        onBlur={checkAvailability}
        disabled={toEdit}
      />
      <Field
        name="app"
        label={LocaleStrings.events_form_label_app}
        placeholder={LocaleStrings.events_form_ph_app}
        mandatory="true"
        component={renderSelect}
        labelposition={LABEL_POSITION_TOP}
        opts={eventapp}
      />
      <Field
        name="distributoridfk"
        label={LocaleStrings.events_form_label_distributor}
        placeholder={LocaleStrings.events_form_ph_distributor}
        mandatory="true"
        component={renderSelect}
        labelposition={LABEL_POSITION_TOP}
        opts={distributorArr}
      />
      <Field
        name="coachidfk"
        label={LocaleStrings.events_form_label_coach}
        placeholder={LocaleStrings.events_form_ph_coach}
        mandatory="false"
        component={renderSelectWithButton}
        onManageClick={() => onManageEmailRelated("coach")}
        labelposition={LABEL_POSITION_TOP}
        opts={coachArr}
      />
      <Field
        name="coachemailidfk"
        label={LocaleStrings.events_form_label_coach_mail}
        placeholder={LocaleStrings.events_form_ph_coach_email}
        mandatory="false"
        component={renderSelectWithButton}
        onManageClick={() => onManageEmailRelated("coachemail")}
        labelposition={LABEL_POSITION_TOP}
        opts={coachEmailsArr}
      />
      <Field
        name="systememailidfk"
        label={LocaleStrings.events_form_label_system_mail}
        placeholder={LocaleStrings.events_form_ph_system_email}
        mandatory="false"
        component={renderSelectWithButton}
        onManageClick={() => onManageEmailRelated("systememail")}
        labelposition={LABEL_POSITION_TOP}
        opts={syatemEmailsArr}
      />
      <Field
        name="targetentrants"
        label={LocaleStrings.events_form_label_entrants_num}
        placeholder={LocaleStrings.events_form_ph_entrants_num}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
      />
      <Field
        name="isactive"
        type="checkbox"
        label={LocaleStrings.events_form_label_active_event}
        component={renderFieldCheckbox}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { storeAddEventFormData, checkSubdomain, openEmailRelatedModal },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddEventStep1);
