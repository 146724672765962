import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useHistory } from "react-router";

import { Container, Card, CardHeader, CardBody, Row, Button } from "reactstrap";
import {
  fetchSelectedTeam,
  fetchTeamMembers,
  fetchTeamClientEntrantCount,
  openAddTeamMemberModal,
} from "../../../actions";

import Header from "../../../../Headers/Header";
import Loader from "../../../../Common/loader";

import LocaleStrings from "../../../../../languages";
import { CommonGoBack } from "components/Common/base-components";

const ClientTeamManage = (props) => {
  var {
    deviceSize,
    SelectedClient,

    SingleEventTeams,
  } = props;

  const eventid = props?.match?.params?.eventid
    ? props?.match?.params?.eventid
    : SelectedClient?.eventidfk
    ? SelectedClient?.eventidfk
    : null;

  const clientid = props?.match?.params?.clientid
    ? props?.match?.params?.clientid
    : SelectedClient?.id
    ? SelectedClient?.id
    : null;

  const [search, setSearch] = useState("");

  const history = useHistory();

  useEffect(() => {
    return () => {};
  }, [SingleEventTeams, SelectedClient]);

  var tempData = [];
  tempData =
    SingleEventTeams?.data && SingleEventTeams?.data.length > 0
      ? SingleEventTeams.data
      : [];

  if (!_.isEmpty(tempData)) {
    let myList = [],
      singleDetails = {};

    _.map(tempData, (singleEvCl, key) => {
      if (singleEvCl.clientidfk == clientid) {
        singleDetails = {
          id: key,
          fullDetails: singleEvCl,
          team_id: singleEvCl?.id,

          team_name: singleEvCl?.teamname,
          size: singleEvCl?.teammembers_by_teamidfk.length,
          points: singleEvCl?.teampoints,
        };
        myList.push(singleDetails);
      }
    });

    var data = myList;
  }
  var filteredData = !_.isEmpty(data)
    ? data.filter(
        (item) =>
          JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) !==
          -1
      )
    : [];

  const columns = [
    {
      name: LocaleStrings.events_team_th_id,
      selector: (row) => row.team_id,
      grow: 2,
      wrap: true,
    },
    // {
    //   name: LocaleStrings.events_team_th_client_name,
    //   selector: (row) => row.client_name,
    //   sortable: true,
    //   grow: 2,
    //   wrap: true,
    // },
    {
      name: LocaleStrings.events_team_th_name,
      selector: (row) => row.team_name,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_size,
      selector: (row) => row.size,
      sortable: true,
    },
    {
      name: LocaleStrings.events_team_th_points,
      selector: (row) => row.points,
      sortable: true,
      wrap: true,
    },
    // {
    //   name: LocaleStrings.events_team_th_confirmed_Date,
    //   selector: (row) => row.confirmed,
    //   // sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "",
    //   button: true,
    //   cell: (row) => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle
    //         className="btn-icon-only text-light"
    //         href="#pablo"
    //         role="button"
    //         size="sm"
    //         color=""
    //         onClick={(e) => handleOptions(e)}
    //       >
    //         <i className="fas fa-ellipsis-v" />
    //       </DropdownToggle>
    //       <DropdownMenu className="dropdown-menu-arrow" right>
    //         <DropdownItem onClick={() => openViewTeam(row)}>
    //           {LocaleStrings.button_view}
    //         </DropdownItem>
    //         {row.fullDetails?.withdrawndate ? (
    //           ""
    //         ) : (
    //           <DropdownItem onClick={() => openAddTeam(row)}>
    //             {LocaleStrings.button_edit}
    //           </DropdownItem>
    //         )}
    //         {row.fullDetails?.withdrawndate ? (
    //           ""
    //         ) : (
    //           <DropdownItem onClick={() => handleWithdraw(row)}>
    //             {LocaleStrings.button_withdraw}
    //           </DropdownItem>
    //         )}
    //         <DropdownItem onClick={() => handleTeamManage(row)}>
    //           {LocaleStrings.button_team_manage}
    //         </DropdownItem>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    // },
  ];

  const goBack = () => {
    setTimeout(() => {
      history.push({
        pathname: `/admin/event/${eventid}`,
      });
    }, 500);
  };

  const subHeaderComponent = useMemo(() => {
    return (
      <div className="custom-header pb-3 pull-right">
        <ul className="header-buttons">
          <li className="buttons-option">
            <input
              className="form-control inputsearch buttons-option"
              placeholder={LocaleStrings.search}
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </li>
        </ul>
      </div>
    );
  }, [search]);

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--5" fluid>
        <CommonGoBack />
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="">
                <div className="pull-left">
                  <h4>
                    {!_.isEmpty(SelectedClient)
                      ? `${SelectedClient?.clients_by_clientidfk?.name}`
                      : ""}
                    {/* {!_.isEmpty(SelectedTeam)
                      ? `${SelectedTeam?.event_by_eventidfk?.name ?? ""} > ${
                          SelectedTeam?.teamname
                        }`
                      : ""} */}
                  </h4>
                </div>
                <div className="pull-right">
                  <Button
                    className=""
                    color="primary"
                    size="sm"
                    onClick={goBack}
                  >
                    {LocaleStrings.button_go_back}
                  </Button>
                </div>
              </CardHeader>

              <CardBody style={{ height: deviceSize.height }}>
                {SingleEventTeams?.data ? (
                  <>
                    {SingleEventTeams?.count > 0 ? (
                      <DataTable
                        className="common-data-table"
                        columns={columns}
                        data={filteredData}
                        defaultSortField="team_name"
                        pagination
                        striped
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                      />
                    ) : (
                      <div className="no-content-message">
                        <div>
                          <div className="no-content-text">
                            {LocaleStrings.events_teammember_text_no_data}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    SelectedClient: state.SelectedClient,
    teamClientEntrantCount: state.teamClientEntrantCount,
    SelectedTeamMembers: state.SelectedTeamMembers,
    ViewTeamMembers: state.ViewTeamMembers,
    AddTeamMembers: state.AddTeamMembers,
    ActiveEventList: state.ActiveEventList,
    InactiveEventList: state.InactiveEventList,
    SingleEventTeams: state.SingleEventTeams,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSelectedTeam,
      fetchTeamMembers,
      fetchTeamClientEntrantCount,
      openAddTeamMemberModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClientTeamManage);
