import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HashLoader from "react-spinners/HashLoader";

const Loading = (props) => {
  return (
    <div className="loader-section">
      <HashLoader color="#11cdef" size={100} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
