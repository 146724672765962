import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, reset } from "redux-form";
import {
  Row,
  Col,
  Button,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _ from "lodash";

import { LABEL_POSITION_TOP, COMMON_FAIL_MESSAGE } from "../../Common/constant";
import {
  renderFieldText,
  validateEmail,
  renderSelect,
} from "../../Common/base-components";
import Loader from "../../Common/loader";
import LocaleStrings from "../../../languages";
import { showSuccess, showError } from "../../Common/alertmessage";

import {
  addActivity,
  fetchActivity,
  clearActivity,
  deleteActivity,
  editActivity,
  updateActivity,
} from "../actions";

var activityTableInitialOption;

const Activity = (props) => {
  let {
    session,
    handleSubmit,
    addActivity,
    fetchActivity,
    activityListReducer,
    clearActivity,
    deleteActivity,
    pristine,
    submitting,
    invalid,
    editActivity,
    editActivityDetails,
    toEdit,
    updateActivity,
  } = props;
  const [loading, setLoading] = useState(false);
  const [eventtype, setEventType] = useState("school");
  const [dropdownOpen, setOpen] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [editTrue, setEditTrue] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  const eventTypeArr = [
    { value: "team", text: "Team" },
    { value: "school", text: "School" },
    { value: "guardian", text: "Guardian" },
  ];

  useEffect(() => {
    activityTableInitialOption = { eventtypelist: eventtype };
    fetchActivity(session, eventtype);
  }, []);

  const onSubmitForm = (values, dispatch) => {
    setLoading(true);
    // console.log(values);

    let resource = [
      {
        name: values.name,
        eventtype: values.eventtype,
        low: values.low,
        medium: values.medium,
        high: values.high,
      },
    ];

    !toEdit
      ? addActivity(session, resource, (res) => {
          if (res.success) {
            dispatch(reset("ActivityForm"));
            activityTableInitialOption = {
              eventtypelist: eventtype,
            };
            setLoading(false);
            clearActivity();
            fetchActivity(session, eventtype);
            showSuccess(LocaleStrings.activity_add_success);
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        })
      : updateActivity(session, editActivityDetails.id, resource[0], (res) => {
          if (res.success) {
            dispatch(reset("ActivityForm"));
            activityTableInitialOption = {
              eventtypelist: editActivityDetails.eventtype,
            };
            setLoading(false);
            clearActivity();
            fetchActivity(session, editActivityDetails.eventtype);
            showSuccess(LocaleStrings.activity_upd_success);
            let obj = {};
            editActivity(obj);
            setEditTrue(false);
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        });
  };

  const handleDelete = (id) => {
    // console.log("delete id: ", id);

    deleteActivity(session, id, (res) => {
      if (res.success) {
        fetchActivity(session, eventtype);
        showSuccess(LocaleStrings.activity_del_success);
      } else {
        showError(COMMON_FAIL_MESSAGE);
      }
    });
  };

  const handleEdit = (activityObj) => {
    let obj = {
      id: activityObj.id,
      eventtype: activityObj.eventtype,
      high: activityObj.high,
      low: activityObj.low,
      medium: activityObj.medium,
      name: activityObj.name,
    };
    editActivity(obj);
    setEditTrue(true);
  };

  const handleSingleCheck = (e, id) => {
    const checked = e.target.checked;
    const arr = selectedActivities;
    // const arr = JSON.stringify(JSON.parse(selectedActivities));

    let newArr = [];
    _.map(arr, (item) => {
      if (!checked && item.id != id) {
        newArr.push(item);
      } else {
        newArr.push(item);
      }
    });
    if (checked) {
      newArr.push({ id: id });
    }

    setSelectedActivities(newArr);
  };

  const activityListRender = () => {
    return _.map(activityListReducer.data, (activity) => {
      let filter = _.filter(selectedActivities, { id: activity.id });
      filter = filter[0]?.id;
      const check = activity.id == filter;

      return (
        <tr>
          <td style={{ width: "7%" }}>
            <input
              type="checkbox"
              checked={check}
              onChange={(e) => handleSingleCheck(e, activity.id)}
            />
          </td>
          <td style={{ width: "43%" }}>{activity.name}</td>
          <td style={{ width: "12%" }}>{activity.low}</td>
          <td style={{ width: "12%" }}>{activity.medium}</td>
          <td style={{ width: "12%" }}>{activity.high}</td>
          <td style={{ width: "7%" }}>
            <i
              className="fa fa-pen"
              aria-hidden="true"
              onClick={() => handleEdit(activity)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
          <td style={{ width: "7%" }}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() => handleDelete(activity.id)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
        </tr>
      );
    });
  };

  const handleSelectEventType = (e) => {
    const type = e.target.value;
    setEventType(type);
    // console.log("handleSelectEventType:", type);

    if (type !== "") {
      clearActivity();
      fetchActivity(session, type);
    }
  };

  const selectAllActivity = (e) => {
    const checked = e.target.checked;
    const arr = [];
    if (checked) {
      _.map(activityListReducer.data, (item) => {
        arr.push({ id: item.id });
      });
    }
    setSelectedActivities(arr);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Row className="justify-content-around">
          <Col sm="12">
            {/* <div className='p-3'> {LocaleStrings.add_new_activity} </div> */}
            {!editTrue
              ? LocaleStrings.add_new_activity
              : LocaleStrings.edit_activity}
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col className="d-flex" sm="12">
            <Field
              name="name"
              placeholder={LocaleStrings.activity_form_ph_activity_name}
              type="text"
              component={renderFieldText}
            />
            <div className="ml-3" style={{ width: 135 }}>
              <Field
                name="eventtype"
                placeholder={LocaleStrings.activity_form_ph_type_of_event}
                component={renderSelect}
                opts={eventTypeArr}
              />
            </div>
            <div className="ml-3" style={{ width: 100 }}>
              <Field
                name="low"
                placeholder={LocaleStrings.activity_form_ph_low}
                type="number"
                component={renderFieldText}
              />
            </div>
            <div className="ml-3" style={{ width: 100 }}>
              <Field
                name="medium"
                placeholder={LocaleStrings.activity_form_ph_medium}
                type="number"
                component={renderFieldText}
              />
            </div>
            <div className="ml-3" style={{ width: 100 }}>
              <Field
                name="high"
                placeholder={LocaleStrings.activity_form_ph_high}
                type="number"
                component={renderFieldText}
              />
            </div>
            <Button
              type="submit"
              className="ml-4"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
              style={{
                height: 43,
                width: 140,
              }}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {!editTrue
                ? LocaleStrings.button_add_activity_with_plus
                : LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>

      <Row className="mt-4 justify-content-around">
        <Col className="d-flex" sm="12">
          <Field
            label={LocaleStrings.activity_form_label_type_of_event}
            name="eventtypelist"
            placeholder={LocaleStrings.activity_form_label_select_a_type}
            type="number"
            component={renderSelect}
            opts={eventTypeArr}
            labelposition={LABEL_POSITION_TOP}
            onChange={(e) => handleSelectEventType(e)}
          />
        </Col>
      </Row>

      <Row className="mt-4 mb-7 justify-content-around">
        <Col sm="12">
          {activityListReducer && activityListReducer.data ? (
            <div style={{ marginTop: 15 }}>
              {activityListReducer.data &&
              activityListReducer.data.length > 0 ? (
                <div className="activity-list">
                  <Table
                    className="tablelist align-items-center table-flush min-height-135"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: "7%" }}>
                          <input
                            type="checkbox"
                            onChange={(e) => selectAllActivity(e)}
                          />
                          {selectedActivities.length > 0 ? (
                            <ButtonDropdown
                              className="ml-2"
                              isOpen={dropdownOpen}
                              toggle={toggle}
                            >
                              <DropdownToggle caret size="sm"></DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem>Delete Selected</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          ) : (
                            ""
                          )}
                        </th>
                        <th scope="col" style={{ width: "43%" }}>
                          {LocaleStrings.th_activity}
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          {LocaleStrings.th_low}
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          {LocaleStrings.th_medium}
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          {LocaleStrings.th_high}
                        </th>
                        <th scope="col" style={{ width: "7%" }}></th>
                        <th scope="col" style={{ width: "7%" }}></th>
                      </tr>
                    </thead>

                    <tbody>{activityListRender()}</tbody>
                  </Table>
                </div>
              ) : (
                <div className="no-content-message">
                  {LocaleStrings.no_record_activity}
                </div>
              )}
            </div>
          ) : (
            <div className="loaderstyle">
              <Loader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const validate = (values) => {
  var errors = {};

  var name = values["name"];
  var eventtype = values["eventtype"];
  var low = values["low"];
  var medium = values["medium"];
  var high = values["high"];

  if (!name || name.trim() === "") {
    errors["name"] = LocaleStrings.requird;
  }

  if (!eventtype || eventtype.trim() === "") {
    errors["eventtype"] = LocaleStrings.requird;
  }

  if (!low || low === "") {
    errors["low"] = LocaleStrings.requird;
  }

  if (!medium || medium === "") {
    errors["medium"] = LocaleStrings.requird;
  }

  if (!high || high === "") {
    errors["high"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // console.log("activityListReducer: ", state.activityListReducer);

  var toEdit = !_.isEmpty(state?.editActivityDetails);
  var initial = activityTableInitialOption;

  if (toEdit) {
    initial = {
      eventtypelist: state.editActivityDetails.eventtype,
      name: state.editActivityDetails.name,
      eventtype: state.editActivityDetails.eventtype,
      low: state.editActivityDetails.low,
      medium: state.editActivityDetails.medium,
      high: state.editActivityDetails.high,
    };
  }

  return {
    session: state.session,
    activityListReducer: state.activityListReducer,
    editActivityDetails: state.editActivityDetails,
    initialValues: initial,
    toEdit,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addActivity,
      fetchActivity,
      clearActivity,
      deleteActivity,
      editActivity,
      updateActivity,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ActivityForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Activity)
);
