import Index from "./views/Index.js";

// import Profile from "./views/examples/Profile.js";
// import Maps from "./views/examples/Maps.js";
// import Register from "./views/examples/Register.js";
// import Tables from "./views/examples/Tables.js";
// import Icons from "./views/examples/Icons.js";
import Login from "./views/examples/Login.js";

import Events from "./components/events";
import EventManage from "./components/events/components/eventmanage";
import EventTeamManage from "./components/events/components/eventmanage/eventteammanage";
import ClientTeamManage from "./components/events/components/eventmanage/clientteammanage";
import Clients from "./components/clients";
import Settings from "./components/settings";
import EmailTemplates from "./components/emailtemplates";

// Languages
import LocaleStrings from "./languages";

// var routes = [
//   {
//     path: "/index",
//     name: "Dashboard",
//     icon: "ni ni-tv-2 text-primary",
//     component: Index,
//     layout: "/admin",
//   },
//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "ni ni-planet text-blue",
//     component: Icons,
//     layout: "/admin",
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "ni ni-pin-3 text-orange",
//     component: Maps,
//     layout: "/admin",
//   },
//   {
//     path: "/user-profile",
//     name: "User Profile",
//     icon: "ni ni-single-02 text-yellow",
//     component: Profile,
//     layout: "/admin",
//   },
//   {
//     path: "/tables",
//     name: "Tables",
//     icon: "ni ni-bullet-list-67 text-red",
//     component: Tables,
//     layout: "/admin",
//   },
//   {
//     path: "/login",
//     name: "Login",
//     icon: "ni ni-key-25 text-info",
//     component: Login,
//     layout: "/auth",
//   },
//   {
//     path: "/register",
//     name: "Register",
//     icon: "ni ni-circle-08 text-pink",
//     component: Register,
//     layout: "/auth",
//   },
// ];

var routes = [
  // {
  //   path: "/index",
  //   name: LocaleStrings.side_dashboard,
  //   icon: "ni ni-tv-2 text-blue",
  //   component: Index,
  //   display: true,
  //   layout: "/admin",
  // },
  {
    path: "/events",
    name: LocaleStrings.side_events,
    icon: "ni ni-user-run text-blue",
    component: Events,
    display: true,
    layout: "/admin",
  },
  {
    path: "/event/:eventid",
    name: "",
    icon: "",
    component: EventManage,
    display: false,
    layout: "/admin",
  },
  {
    path: "/event-team/:eventid/:teamid",
    name: "",
    icon: "",
    component: EventTeamManage,
    display: false,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: LocaleStrings.side_clients,
    icon: "ni ni-single-02 text-blue",
    component: Clients,
    display: true,
    layout: "/admin",
  },
  {
    path: "/client-team/:eventid/:clientid",
    name: "",
    icon: "",
    component: ClientTeamManage,
    display: false,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: LocaleStrings.side_master_settings,
    icon: "ni ni-settings-gear-65 text-blue",
    component: Settings,
    display: true,
    layout: "/admin",
  },
  // {
  //   path: "/templates",
  //   name: LocaleStrings.side_email_template,
  //   icon: "ni ni-email-83 text-blue",
  //   component: EmailTemplates,
  //   display: true,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-blue",
    component: Login,
    display: false,
    layout: "/auth",
  },
];
export default routes;
