import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  activateDeactivateEvent,
  clearSingleEvent,
  clearEventClients,
  clearEventTeams,
  clearEventRegistrationRequest,
  openEventModal,
  currentStep,
  storeAddEventFormData,
} from "../actions";
import {
  clearAllActiveClients,
  clearAllActiveSchoolClients,
  clearAllActiveCompanyClients,
} from "../../clients/actions";
import {
  COMMON_FAIL_MESSAGE,
  convertDateIntoLocal,
} from "../../Common/constant";
import { showSuccess, showError } from "../../Common/alertmessage";
import LocaleStrings from "../../../languages";

const EventList = (props) => {
  var { printList, currentPage, pageDataLength, session } = props;

  const handleOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const editEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var legsArr = [];
    _.map(printList?.eventleg_by_eventidfk, (obj, index) => {
      legsArr.push({
        key: `${index + 1}`,
        legkey: `Leg ${index + 1}`,
        isOpen: false,
        selected: obj?.materlegidfk ?? null,
        legtitle: obj?.locationtitle,
        eventlegid: obj?.eventlegid,
        isnew: false,
      });
    });
    // console.log("printList :- ", printList);

    props.openEventModal({ showModal: true, value: printList });
    props.currentStep({ step: 1, text: "Step 1" });
    props.storeAddEventFormData({
      eventType: printList?.type,
      courseOpenDate: moment(
        printList?.eventsettings_by_eventidfk?.[0]?.challengecoursestartdate
      ),
      courseCloseDate: moment(
        printList?.eventsettings_by_eventidfk?.[0]?.challengecourseclosedate
      ),
      legs: printList?.nooflegs ?? 0,
      bonuslegs: printList?.noofbonuslegs ?? 0,
      teamdailyavgtargetpointopen: printList?.teamavgdailypointstargetopen ?? 0,
      dailymaxindividualpointopen: printList?.dailymaxindividualopen ?? 0,
      teamdailyavgtargetpointadvance:
        printList?.teamavgdailypointstargetadv ?? 0,
      dailymaxindividualpointadvance: printList?.dailymaxindividualadv ?? 0,
      legsArr,
    });
  };

  const handleEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.clearSingleEvent();
    props.clearEventClients();
    props.clearEventTeams();
    props.clearEventRegistrationRequest();
    props.clearAllActiveClients();
    props.clearAllActiveSchoolClients();
    props.clearAllActiveCompanyClients();

    setTimeout(() => {
      props.history.push({
        pathname: "/admin/event/" + printList.eventid,
      });
    }, 500);
  };

  const activateDeactivate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    let values = {
      eventid: printList.eventid,
      isactive: printList.isactive ? 0 : 1,
    };

    confirmAlert({
      title: printList.isactive
        ? LocaleStrings.button_deactivate
        : LocaleStrings.button_activate,
      message: printList.isactive
        ? LocaleStrings.events_deactivated_subtitle
        : LocaleStrings.events_activated_subtitle,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.activateDeactivateEvent(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                props.pagination(pageNo);
                showSuccess(
                  printList.isactive
                    ? LocaleStrings.events_alert_event_deactivated
                    : LocaleStrings.events_alert_event_activated
                );
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <tr className="cursor-pointer" onClick={(e) => handleEvent(e)}>
      <td>{printList.eventid}</td>
      <td>{printList.name}</td>
      <td className="text-capitalize">{printList.type}</td>
      <td>{convertDateIntoLocal(printList.activestart)}</td>
      <td>{convertDateIntoLocal(printList.activeend)}</td>
      <td>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => handleOptions(e)}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem onClick={(e) => editEvent(e)}>
              {LocaleStrings.button_edit}
            </DropdownItem>
            <DropdownItem onClick={(e) => activateDeactivate(e)}>
              {printList?.isactive
                ? LocaleStrings.button_deactivate
                : LocaleStrings.button_activate}
            </DropdownItem>
            {/* <DropdownItem>{LocaleStrings.button_duplicate}</DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      activateDeactivateEvent,
      clearSingleEvent,
      clearEventClients,
      clearEventTeams,
      clearEventRegistrationRequest,
      clearAllActiveClients,
      clearAllActiveSchoolClients,
      clearAllActiveCompanyClients,
      openEventModal,
      currentStep,
      storeAddEventFormData,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventList)
);
