import axios from "axios";
import {
  getRequest,
  postRequest,
  patchRequest,
  uploadEncodedFile,
} from "../components/Common/network-call";
import {
  BASE_URL,
  APP_API_KEY,
  BASE_IMAGES_URL,
} from "../components/Common/constant";
import { getHeaders } from "../components/Common/common-utils";

export const SESSION_TOKEN = "SESSION_TOKEN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const DEVICE_SIZE = "DEVICE_SIZE";
export const TIMEZONE_LIST = "TIMEZONE_LIST";
export const COACH_LIST = "COACH_LIST";
export const COACH_EMAIL_LIST = "COACH_EMAIL_LIST";
export const SYSTEM_EMAIL_LIST = "SYSTEM_EMAIL_LIST";
export const MANAGE_EMAIL_RELATED_MODAL = "MANAGE_EMAIL_RELATED_MODAL";
export const DISTRIBUTOR_LIST = "DISTRIBUTOR_LIST";
export const CERTIFICATES_LIST = "CERTIFICATES_LIST";
export const LEG_LIST = "LEG_LIST";
export const CROPPER_IMAGE_MODAL = "CROPPER_IMAGE_MODAL";

export function login(session, values, callback) {
  var config = {
    headers: {
      "content-type": "application/json",
      "X-DreamFactory-Api-Key": APP_API_KEY,
    },
  };

  var data = {
    email: values.email,
    password: values.password,
    type: "email",
    //duration: 0
  };

  var url = `${BASE_URL}api/v2/user/session`;
  var response = axios.post(url, data, config);

  return (dispatch) => {
    response
      .then(({ data }) => {
        // console.log("data: ", data);

        if (data) {
          if (data.isactive) {
            dispatch({ type: SESSION_TOKEN, payload: data });
            dispatch({ type: LOGIN, payload: true });
            callback({ success: 1 });
          } else {
            callback({
              success: 2,
              message: "Profile Inactive",
            });
          }
        }
      })
      .catch((error) => {
        callback({ success: 0, message: error });
      });
  };
}

export function logout(session) {
  var url = `${BASE_URL}api/v2/user/session`;
  var response = axios.delete(url, getHeaders(session));

  return (dispatch) => {
    response
      .then(({ data }) => {
        dispatch({ type: LOGOUT, payload: false });
      })
      .catch((error) => {
        // nothing to do
        dispatch({ type: LOGOUT, payload: false });
      });
  };
}

export function deviceSize(obj) {
  return (dispatch) => {
    dispatch({ type: DEVICE_SIZE, payload: obj });
  };
}

export function fetchTimezones(session, callback) {
  var filter = encodeURI(`(isactive=1)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/timezones?fields=timezonesid,name&filter=(${filter})&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: TIMEZONE_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchCoachs(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/coach?fields=coachid,name&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: COACH_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchCoachEmails(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/coachemail?fields=coachemailid,email&include_count=true&order=email%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: COACH_EMAIL_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchSystemEmails(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/systememail?fields=systememailid,email&include_count=true&order=email%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: SYSTEM_EMAIL_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function openEmailRelatedModal(obj) {
  return (dispatch) => {
    dispatch({ type: MANAGE_EMAIL_RELATED_MODAL, payload: obj });
  };
}

export function validateEmailRelated(session, filter, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/${filter}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        if (response?.resource?.length == 0) {
          callback({ success: 1, data: response?.resource });
        } else {
          callback({ success: 2, data: response?.resource });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function addEmailRelated(session, table, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/${table}`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function updateEmailRelated(session, table, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/${table}`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchDistributors(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/masterdistributor?fields=distributorid,name&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: DISTRIBUTOR_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function fetchCertificates(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/mastercertificate?fields=id,name,content&include_count=true&order=createdon%20DESC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: CERTIFICATES_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export const fetchLegs = (session, callback = null) => {
  let filter = encodeURI(`(isactive=1)`);
  let fields = "id,name,legcode,startpoint,endpoint";
  let relateds =
    "masterleganimation_by_masterlegidfk,masterlegdetails_by_masterlegidfk,masterleggallery_by_masterlegidfk,masterlegmap_by_masterlegidfk";
  let orderBy = "createdon%20DESC";
  let url = `${BASE_URL}api/v2/teamchallenge/_table/masterleg?include_count=true&fields=${fields}&filter=(${filter})&related=${relateds}&order=${orderBy}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        const payloadObj = {
          data: response.resource,
          count: response.meta.count,
        };
        dispatch({
          type: LEG_LIST,
          payload: payloadObj,
        });
        callback && callback({ success: 1, ...payloadObj, error: null });
      },
      (error) => {
        callback && callback({ success: 0, data: null, error });
      }
    );
  };
};

export function handleCropperModal(obj) {
  return (dispatch) => {
    dispatch({
      type: CROPPER_IMAGE_MODAL,
      payload: obj,
    });
  };
}

export function validateEmailFromSystemDB(session, email, callback) {
  let filter = encodeURI(`email=${email}`);
  var url = `${BASE_URL}api/v2/system/user?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response : - ", response);
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export const commonFileUpload = (
  session,
  extra = { fileData: null, filePath: null },
  callback
) => {
  if (extra.fileData.search(";base64,") > 0) {
    let imageJson = {
      resource: [
        {
          name: extra.filePath, // path+filename
          type: "file",
          is_base64: true,
          content: extra.fileData.split(";base64,")[1],
        },
      ],
    };

    return (dispatch) => {
      uploadEncodedFile(
        `${BASE_IMAGES_URL}?check_exist=false`,
        session,
        imageJson,
        dispatch,
        (res) => {
          callback({ success: 1, data: res, error: null });
        },
        (error) => {
          callback({ success: 0, data: null, error });
        }
      );
    };
  } else {
    return (dispatch) => {
      callback({ success: 2 });
    };
  }
};

export const removeFileFromS3 = (session, path, callback = null) => {
  let deleteUrl = `${BASE_IMAGES_URL}/${path}?force=true`;

  return (dispatch) => {
    postRequest(
      deleteUrl,
      null,
      session,
      dispatch,
      (res) => {
        callback && callback({ success: 1, data: res, error: null });
      },
      (error) => {
        callback && callback({ success: 0, data: null, error });
      }
    );
  };
};
