import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Table } from "reactstrap";
import { fetchInactiveEvents } from "../actions";
import Loader from "../../Common/loader";
import RenderList from "./list-item";
import LocaleStrings from "../../../languages";

const InactiveEvents = (props) => {
  var { session, pageNumber, InactiveEventList } = props;

  useEffect(() => {
    props.fetchInactiveEvents(session);
  }, []);

  const paginationCallback = (no) => {
    props.operationsCallback(no);
  };

  const renderList = () => {
    let pageDataLength = InactiveEventList?.data
      ? InactiveEventList?.data?.length
      : 0;

    return _.map(InactiveEventList.data, (item, index) => {
      return (
        <RenderList
          key={`key_${index}`}
          printList={item}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
          pagination={paginationCallback}
        />
      );
    });
  };

  return (
    <>
      {InactiveEventList?.data ? (
        <>
          {InactiveEventList?.count > 0 ? (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">{LocaleStrings.events_th_eventid}</th>
                  <th scope="col">{LocaleStrings.events_th_event_name}</th>
                  <th scope="col">{LocaleStrings.events_th_event_type}</th>
                  <th scope="col">
                    {LocaleStrings.events_th_event_active_start}
                  </th>
                  <th scope="col">
                    {LocaleStrings.events_th_event_active_end}
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </Table>
          ) : (
            <div className="no-content-message">
              <div>
                <div className="no-content-text">
                  {LocaleStrings.events_text_no_active_data}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log("state.InactiveEventList :- ", state.InactiveEventList);

  return {
    session: state.session,
    InactiveEventList: state.InactiveEventList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchInactiveEvents }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InactiveEvents);
