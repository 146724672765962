import {
  CLIENT_LIST,
  ALL_ACTIVE_CLIENT_LIST,
  SCHOOL_ALL_ACTIVE_CLIENT_LIST,
  COMPANY_ALL_ACTIVE_CLIENT_LIST,
  ADD_CLIENT_MODAL,
  EDIT_CLIENT,
} from "../actions";

export function ClientList(state = {}, action) {
  if (action.type === CLIENT_LIST) {
    return action.payload;
  }
  return state;
}

export function AllActiveClientList(state = {}, action) {
  if (action.type === ALL_ACTIVE_CLIENT_LIST) {
    return action.payload;
  }
  return state;
}

export function AllActiveSchoolClientList(state = {}, action) {
  if (action.type === SCHOOL_ALL_ACTIVE_CLIENT_LIST) {
    return action.payload;
  }
  return state;
}

export function AllActiveCompanyClientList(state = {}, action) {
  if (action.type === COMPANY_ALL_ACTIVE_CLIENT_LIST) {
    return action.payload;
  }
  return state;
}

export function addClientModal(state = {}, action) {
  if (action.type === ADD_CLIENT_MODAL) {
    return action.payload;
  }
  return state;
}

export function editClient(state = {}, action) {
  if (action.type === EDIT_CLIENT) {
    return action.payload;
  }
  return state;
}
