import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmAlert } from "react-confirm-alert";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  openViewTeamMemberModal,
  openAddTeamMemberModal,
  withdrawTeamMember,
  fetchTeamClientEntrantCount,
  deleteTeamMemberSession,
} from "../../../actions";
import {
  COMMON_FAIL_MESSAGE,
  LOCAL_TO_GMT_TIME,
} from "../../../../Common/constant";
import { showSuccess, showError } from "../../../../Common/alertmessage";
import LocaleStrings from "../../../../../languages";

const TeamMemberList = (props) => {
  var { printList, currentPage, pageDataLength, teamid, session } = props;

  const handleOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const openViewTeam = () => {
    props.openViewTeamMemberModal({ showModal: true, data: printList });
  };

  const openAddTeam = () => {
    props.openAddTeamMemberModal({ showModal: true, value: printList });
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = {
      id: printList.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    confirmAlert({
      title: LocaleStrings.events_teammember_withdraw_confirm_title,
      message: `${LocaleStrings.events_teammember_withdraw_confirm_subtitle} ${printList.firstname} ${printList.lastname} ?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            props.withdrawTeamMember(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                props.pagination(currentPage);
                props.fetchTeamClientEntrantCount(session, teamid);
                props.deleteTeamMemberSession(session, printList?.dfid);
                showSuccess(
                  LocaleStrings.events_teammember_withdraw_alert_success
                );
              }
            });
          },
        },
      ],
    });
  };

  return (
    <tr className={`${printList?.withdrawndate ? "deactivated" : ""}`}>
      <td>{printList.id}</td>
      <td>{printList.firstname}</td>
      <td>
        <div className="d-flex alin-item-center">
          {printList.ismanager ? (
            <>
              {printList.lastname}{" "}
              <div className="ml-2 ismanager">{LocaleStrings.manager}</div>
            </>
          ) : (
            printList.lastname.charAt(0)
          )}
        </div>
      </td>
      <td>{printList.username}</td>
      <td>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => handleOptions(e)}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem onClick={(e) => openViewTeam(e)}>
              {LocaleStrings.button_view}
            </DropdownItem>
            {printList?.withdrawndate ? (
              ""
            ) : (
              <DropdownItem onClick={(e) => openAddTeam(e)}>
                {LocaleStrings.button_edit}
              </DropdownItem>
            )}
            {printList?.withdrawndate ? (
              ""
            ) : (
              <DropdownItem onClick={(e) => handleWithdraw(e)}>
                {LocaleStrings.button_withdraw}
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openViewTeamMemberModal,
      openAddTeamMemberModal,
      withdrawTeamMember,
      fetchTeamClientEntrantCount,
      deleteTeamMemberSession,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberList);
