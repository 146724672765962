import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { BASE_IMAGES_URL, TINYMCE_KEY } from "./constant";
import axiosFileupload from "axios-fileupload";
import { connect } from "react-redux";

class RichTextTinymceEditor extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(newProps) {}

  render() {
    const { meta, input, type, height, actionEvent } = this.props;
    let tooloptions = {};
    let fieldHeight = height ? height : 500;

    switch (type) {
      case "minimal":
        tooloptions = {
          selector: "textarea",
          // branding: false,
          // content_style:"@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400'); body {background:#ffffff05; color:#333; font-size:14px; font-family: Montserrat;}",
          // font_formats:"Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=Montserrat,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
          // content_style:"https://staging.teks.co.in/hfd/tiny/css.css",
          font_formats:
            "Comic Sans MS=Comic Sans MS,sans-serif; Garamond=Garamod,sans-serif; Georgia=Georgia,sans-serif; Lato=Lato,sans-serif; Montserrat=Montserrat,sans-serif; Roboto=Roboto,sans-serif; Roboto Mono=Roboto Mono,sans-serif; Tahoma=Tahoma,sans-serif; Times New Roman=Times New Roman,times; Trebuchet MS=Trebuchet MS,sans-serif; Verdana=Verdana,sans-serif",
          height: fieldHeight,
          plugins: "link code preview",
          menubar: "",
          toolbar: ["bold italic underline | link | code basic"],
        };
        break;

      case "maximal":
        tooloptions = {
          selector: "textarea",
          // branding: false,
          // content_style:"@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400'); body {background:#ffffff05; color:#333; font-size:14px; font-family: Montserrat;}",
          // font_formats:"Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=Montserrat,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
          // content_style:"https://staging.teks.co.in/hfd/tiny/css.css",
          font_formats:
            "Comic Sans MS=Comic Sans MS,sans-serif; Garamond=Garamod,sans-serif; Georgia=Georgia,sans-serif; Lato=Lato,sans-serif; Montserrat=Montserrat,sans-serif; Roboto=Roboto,sans-serif; Roboto Mono=Roboto Mono,sans-serif; Tahoma=Tahoma,sans-serif; Times New Roman=Times New Roman,times; Trebuchet MS=Trebuchet MS,sans-serif; Verdana=Verdana,sans-serif",
          height: fieldHeight,
          plugins:
            "searchreplace lists emoticons charmap visualchars hr colorpicker link imagetools code visualblocks toc textpattern textcolor preview insertdatetime imagetools",
          menubar: "file edit insert format tools",
          toolbar: [
            "undo redo | styleselect | bold italic underline | link | alignleft aligncenter alignright | fontselect fontsizeselect ",
            "searchreplace numlist bullist emoticons charmap visualchars | forecolor backcolor code basic ",
          ],
        };
        break;

      default:
        tooloptions = {
          branding: false,
          // content_style:"@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400'); body {background:#ffffff05; color:#333; font-size:14px; font-family: Montserrat;}",
          // font_formats:"Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Montserrat=Montserrat,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
          // content_style: "https://staging.teks.co.in/hfd/tiny/css.css",
          font_formats:
            "Comic Sans MS=Comic Sans MS,sans-serif; Garamond=Garamod,sans-serif; Georgia=Georgia,sans-serif; Lato=Lato,sans-serif; Montserrat=Montserrat,sans-serif; Roboto=Roboto,sans-serif; Roboto Mono=Roboto Mono,sans-serif; Tahoma=Tahoma,sans-serif; Times New Roman=Times New Roman,times; Trebuchet MS=Trebuchet MS,sans-serif; Verdana=Verdana,sans-serif",
          height: 700,
          plugins:
            "searchreplace lists emoticons charmap visualchars hr colorpicker link image imagetools code visualblocks toc textpattern textcolor preview insertdatetime imagetools table",
          menubar: "file edit insert format tools table ",
          toolbar: [
            "undo redo | styleselect | bold italic underline | link image | alignleft aligncenter alignright | fontselect fontsizeselect ",
            "searchreplace numlist bullist emoticons charmap visualchars | table forecolor backcolor code basic ",
          ],
          // image: {
          //   uploadCallback: uploadImageCallBack,
          //   alt: { present: true, mandatory: false },
          //   inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          //   urlEnabled: true,
          //   uploadEnabled: true,
          //   defaultSize: {
          //     height: "auto",
          //     width: "auto",
          //   },
          // },
        };
        break;
    }

    return (
      <div className="rdw-storybook-root">
        {/* this condition is used to handle eventd description as after switch of any event save button in general menu activted without any changes in description */}
        {actionEvent == "onBlur" ? (
          <Editor
            apiKey={TINYMCE_KEY}
            content={input.value}
            initialValue={meta.initial ? meta.initial : ""}
            // value={input.value}
            cloudChannel="5-dev"
            init={tooloptions}
            onBlur={(event) => {
              input.onBlur(event.target.getContent());
              // input.onChange(event.target.getContent()); // This is called to handle onChange event triggered and any changes in editor
            }}
          />
        ) : (
          <Editor
            apiKey={TINYMCE_KEY}
            content={input.value}
            initialValue={meta?.initial ? meta.initial : ""}
            // value={input.value}
            cloudChannel="5-dev"
            init={tooloptions}
            onChange={(event) => {
              input.onChange(event.target.getContent()); // This is called to handle onChange event triggered and any changes in editor
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

export default connect(mapStateToProps, { axiosFileupload })(
  RichTextTinymceEditor
);

// function uploadImageCallBack(file) {
//   return new Promise((resolve, reject) => {
//     axiosFileupload(BASE_INLINE_UPLOAD_BASE_URL, file)
//       .then(({ data }) => {
//         const response = `${BASE_INLINE_IMAGE_URL}/${data}`;
//         resolve({ data: { link: data } });
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }
