import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal, Button } from "reactstrap";
import { openEventUrl } from "../../actions";
import { DOMAIN } from "../../../Common/constant";
import LocaleStrings from "../../../../languages";

const EventUrls = (props) => {
  var { EventUrlModal, SingleEventDetails } = props;
  const [copied, setCopied] = useState("");

  const closeModal = () => {
    props.openEventUrl({ showModal: false });
  };

  const handleCopy = (type) => {
    setCopied(type);

    setTimeout(() => {
      setCopied("");
    }, 1000);
  };

  // console.log("SingleEventDetails :- ", SingleEventDetails);
  var webUrl = SingleEventDetails?.data?.subdomain
    ? `https://${SingleEventDetails?.data?.subdomain}.${DOMAIN}`
    : "";
  var registrationUrl = SingleEventDetails?.data?.subdomain
    ? `https://${SingleEventDetails?.data?.subdomain}.${DOMAIN}#/registration-form`
    : "";

  return (
    <>
      <Modal
        className="event-modal"
        isOpen={EventUrlModal?.showModal ? true : false}
        size="lg"
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_url_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="text-sm text-capitalize mb-4 text-center">
            {LocaleStrings.events_url_sub_title}
          </div>
          <div className="text-sm row mb-4">
            <div className="col-9">
              {LocaleStrings.events_url_text_portal} : {webUrl}
            </div>
            <div className="col-3">
              <a className="mr-4" href={webUrl} target="_blank">
                <Button color="primary" size="sm">
                  <i className="ni ni-curved-next"></i>
                </Button>
              </a>

              <CopyToClipboard text={webUrl} onCopy={() => handleCopy("web")}>
                <Button color="primary" size="sm">
                  <i className="ni ni-single-copy-04"></i>{" "}
                  {copied === "web"
                    ? LocaleStrings.button_copied
                    : LocaleStrings.button_copy_link}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="text-sm row">
            <div className="col-9">
              {LocaleStrings.events_url_text_registration} : {registrationUrl}
            </div>
            <div className="col-3">
              <a className="mr-4" href={registrationUrl} target="_blank">
                <Button color="primary" size="sm">
                  <i className="ni ni-curved-next"></i>
                </Button>
              </a>

              <CopyToClipboard
                text={registrationUrl}
                onCopy={() => handleCopy("registration")}
              >
                <Button color="primary" size="sm">
                  <i className="ni ni-single-copy-04"></i>{" "}
                  {copied === "registration"
                    ? LocaleStrings.button_copied
                    : LocaleStrings.button_copy_link}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    EventUrlModal: state.EventUrlModal,
    SingleEventDetails: state.SingleEventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openEventUrl }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventUrls);
