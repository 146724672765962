import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import {
  eventClientListModal,
  createTeam,
  updateIntrestedUsers,
  sendEmailtoAssignedIntrestedUsers,
} from "../../actions";
import {
  renderFieldText,
  renderAutoComplete,
} from "../../../Common/base-components";
import {
  COMMON_FAIL_MESSAGE,
  LOCAL_TO_GMT_TIME,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";

const AssignRequestToEventClient = (props) => {
  var {
    session,
    eventClientListModalStatus,
    singleEventAllClients,

    handleSubmit,
    pristine,
    invalid,
    submitting,
  } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.eventClientListModal({ show: false });
  };

  const onSubmitForm = (values) => {
    var data = [];
    var requestedids = [];
    var teamData = [];
    // console.log("values :- ", values);

    var selectedEC = _.filter(singleEventAllClients?.data, (obj) => {
      return obj.id === values?.eventclient?.value;
    });
    // console.log("selectedEC :- ", selectedEC);

    var firstLeg = _.filter(
      selectedEC?.[0]?.eventclientlegs_by_eventclientidfk,
      (obj) => {
        return obj?.order === 1;
      }
    );
    // console.log("firstLeg :- ", firstLeg);

    _.map(eventClientListModalStatus?.data, (item) => {
      var obj = {
        id: item.id,
        status: 2,
        eventclientidfk: values?.eventclient?.value,
        assigndate: LOCAL_TO_GMT_TIME,
      };
      data.push(obj);
      requestedids.push(item.id);

      var teamobj = {
        eventidfk: item?.eventidfk,
        eventclientidfk: values?.eventclient?.value,
        clientidfk: selectedEC?.[0]?.clientidfk,
        currentlegidfk: firstLeg?.[0]?.id,
        schoolidfk: item?.schoolid,
        teamname: "TBC",
      };
      teamData.push(teamobj);
    });

    // console.log("final values : - ", data);
    // console.log("teamData : - ", teamData);

    if (data.length > 0) {
      setLoading(true);

      props.createTeam(session, teamData, (respo) => {
        // console.log("respo :- ", respo);
        data.map((item, index) => {
          item.teamidfk = respo?.resource?.[index]?.id;
        });
        // console.log("data :- ", data);

        if (respo?.success) {
          props.updateIntrestedUsers(session, data, (response) => {
            // console.log('response',response);
            setLoading(false);
            if (response.success === 0) {
              showError(COMMON_FAIL_MESSAGE);
            } else {
              showSuccess(LocaleStrings.events_regi_request_form_add_success);
              props.finishOperationsCallback();
              closeModal();

              props.sendEmailtoAssignedIntrestedUsers(
                session,
                requestedids,
                (res) => {}
              );
            }
          });
        }
      });
    }
  };

  var clientArr = [];
  _.map(singleEventAllClients?.data, (item) => {
    var obj = {
      value: item?.id,
      label: item?.clients_by_clientidfk?.name,
    };
    clientArr.push(obj);
  });

  return (
    <>
      <Modal
        className=""
        isOpen={eventClientListModalStatus?.show ? true : false}
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_regi_request_assign_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <Field
              name="eventclient"
              label={LocaleStrings.events_regi_request_form_label_client}
              placeholder={LocaleStrings.events_regi_request_form_ph_client}
              component={renderAutoComplete}
              mandatory="true"
              isMulti={false}
              options={clientArr}
            />
          </div>

          <div className="modal-footer">
            <Button color="secondary" onClick={closeModal}>
              {LocaleStrings.button_close}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={pristine || invalid || submitting || loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ""}{" "}
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var eventclient = values["eventclient"];

  if (!eventclient) {
    errors["eventclient"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    eventClientListModalStatus: state.eventClientListModalStatus,
    singleEventAllClients: state.singleEventAllClients,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      eventClientListModal,
      createTeam,
      updateIntrestedUsers,
      sendEmailtoAssignedIntrestedUsers,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RequestedUserAssignForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AssignRequestToEventClient)
);
