import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { Modal } from "reactstrap";
import { handleCropperModal } from "../../actions";
import LocaleStrings from "../../languages";

function ImageCropperName(props) {
  var {
    imageData,
    imageType,
    labelKey,
    cropperSize,
    requiredSize,
    className,
    insideText,
    cropperModal,
  } = props;
  var [canvasRecource, setCanvasRecource] = useState(props.canvasRecource);
  var [fileData, setFileData] = useState({});
  var [imageUrl, setImagepath] = useState(imageData?.file);
  var [scale, setScale] = useState(1.2);
  var [imageErrorMsg, setImageErrorMsg] = useState("");

  var scaleStep = 0.05;
  var defaultScale = 1.2;
  var minScale = 1;
  var maxScale = 2;
  var editor = "";

  const setEditorRef = (edit) => (editor = edit);

  const onClickSave = () => {
    saveImage(null);
  };

  const saveImage = (callback) => {
    if (editor) {
      var imgType = imageType ? imageType : "jpg";

      var img;
      if (imgType === "png") img = editor.getImage().toDataURL();
      else img = editor.getImage().toDataURL("image/jpeg", 0.9);
      var arrayBuffer = Uint8Array.from(atob(img.split(";base64,")[1]), (c) =>
        c.charCodeAt(0)
      );

      var imageURL = "";
      fetch(img)
        .then((res) => res.blob())
        .then((blob) => {
          imageURL = window.URL.createObjectURL(blob);

          // console.log("imageURL........ :- ", imageURL);
          setImagepath(imageURL);
          props.onImageSave({ file: arrayBuffer, isnew: true, ...fileData });
          if (callback) {
            setTimeout(() => {
              callback();
            }, 2000);
          }
          closeModal(); // Modal handle to crop and preview
        });

      // Code for using base64 image data to display image
      // setImagepath(imageURL);
      // props.onImageSave(imageURL);
      // if (callback) {
      //   setTimeout(() => {
      //     callback();
      //   }, 2000);
      // }
    }
  };

  const resetImage = () => {
    setCanvasRecource("");
    setImagepath("");
    setScale(defaultScale);
    setImageErrorMsg("");

    props.onImageSave({});
    props.onImageChange(false);
    closeModal(); // Modal handle to crop and preview
  };

  const onDropFile = (files) => {
    // console.log("files :- ", files);
    resetImage(); // Reset the previous data if any before new upload
    let file = files[0];
    let requiredWidth = requiredSize.width;
    let requiredHeight = requiredSize.height;
    let reader = new FileReader();
    if (file && file.type.indexOf("image") != -1) {
      reader.onload = (e) => {
        let img = new Image();
        img.src = reader.result;
        img.onload = function (obj) {
          let width = this.width;
          let height = this.height;

          if (width < requiredWidth || height < requiredHeight) {
            setImageErrorMsg(LocaleStrings.invalid_profile_image_size);
            props.onImageChange(false);
          } else {
            setImageErrorMsg("");
            // setCanvasRecource(reader.result); // for base64 image url
            setCanvasRecource(URL.createObjectURL(file)); // For blob image url
            props.onImageChange(true);
            props.handleCropperModal({ showModal: true, source: labelKey }); // Modal handle to crop and preview
            setFileData({
              name: file.name,
              type: file.type,
            });
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const onZoomOut = () => {
    var scale1 = scale;
    scale1 = scale1 - scaleStep;
    if (scale1 < minScale) scale1 = minScale;
    setScale(scale1);
  };

  const onZoomIn = () => {
    var scale1 = scale;
    scale1 = scale1 + scaleStep;
    if (scale1 > maxScale) scale1 = maxScale;
    setScale(scale1);
  };

  const closeModal = () => {
    props.handleCropperModal({ showModal: false }); // Modal handle to crop and preview
  };

  var hideCropZoneClass = "d-none";
  var hideControlButton = "d-none";

  if (imageUrl && imageUrl !== "file object not found") {
    hideCropZoneClass = "d-none";
    hideControlButton = "";
  }

  if (canvasRecource && !imageUrl) {
    hideCropZoneClass = "";
  }
  if (imageErrorMsg != "") {
    hideControlButton = "";
  }

  // console.log("imageUrl :- ", imageUrl);
  // console.log("canvasRecource :- ", canvasRecource);
  // console.log("hideCropZoneClass :- ", hideCropZoneClass);
  // console.log("hideControlButton :- ", hideControlButton);
  // console.log("imageData :- ", imageData);
  // console.log("cropperModal :- ", cropperModal);

  return (
    <>
      {imageErrorMsg == "" ? (
        <div className={`input-type-div cursor-pointer`}>
          <Dropzone onDrop={onDropFile} accept="image/*" multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={`${className}`}>
                <input {...getInputProps()} />
                <div className="text-display">
                  {!_.isEmpty(imageData) && imageData?.name
                    ? imageData?.name
                    : insideText}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      ) : (
        <>
          <div
            className={`alert-danger p-2 mt-2 mb-2 rounded font-weight-normal`}
          >
            {imageErrorMsg}
          </div>

          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={resetImage}
          >
            {LocaleStrings.button_upload_new_image}
          </button>
        </>
      )}

      <Modal
        size="xl"
        className="image-cropper-modal"
        isOpen={
          cropperModal?.showModal && cropperModal.source === labelKey
            ? true
            : false
        }
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            <span className="modal-title text-sm">{`Crop the image`}</span>
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className={`uploaded-image-section ${hideControlButton}`}>
            <div className={`cropper-remove-button-position text-right`}>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={resetImage}
              >
                {LocaleStrings.button_remove}
              </button>
            </div>
            <img src={imageUrl} alt="" className={`img-responsive`} />
          </div>

          <div className={`${hideCropZoneClass} text-center`}>
            <AvatarEditor
              image={canvasRecource}
              color={[0, 0, 0, 0.5]} // RGBA
              scale={scale}
              rotate={0}
              ref={setEditorRef}
              disabledrop="true"
              width={cropperSize.width}
              height={cropperSize.height}
              className="editor-canvas"
              style={{ background: "#777" }}
            />
            <div className="img-cropper-control-button">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onZoomIn}
              >
                <i className="fa fa-search-plus"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onZoomOut}
              >
                <i className="fa fa-search-minus"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={onClickSave}
              >
                {LocaleStrings.button_crop}
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={resetImage}
              >
                x
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    cropperModal: state.cropperModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ handleCropperModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropperName);
