import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Button,
  Table,
} from "reactstrap";
import {
  fetchSelectedTeam,
  fetchTeamMembers,
  fetchTeamClientEntrantCount,
  openAddTeamMemberModal,
} from "../../../actions";
import { CUSTOM_ITEM_COUNT } from "../../../../Common/constant";
import Header from "../../../../Headers/Header";
import Loader from "../../../../Common/loader";
import Pagination from "../../../../Common/pagination";
import RenderList from "./item-list";
import ViewTeamMember from "./teammember-view";
import AddTeamMember from "./teammember-add";
import LocaleStrings from "../../../../../languages";
import { CommonGoBack } from "components/Common/base-components";

const EventTeamManage = (props) => {
  var {
    session,
    deviceSize,
    SelectedTeam,
    teamClientEntrantCount,
    SelectedTeamMembers,
    ViewTeamMembers,
    AddTeamMembers,
  } = props;
  const eventid = props?.match?.params?.eventid
    ? props?.match?.params?.eventid
    : SelectedTeam?.eventidfk
    ? SelectedTeam?.eventidfk
    : null;
  const teamid = props?.match?.params?.teamid
    ? props?.match?.params?.teamid
    : SelectedTeam?.id
    ? SelectedTeam?.id
    : null;
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    props.fetchTeamMembers(session, eventid, teamid);
    props.fetchTeamClientEntrantCount(session, teamid, (res) => {
      props.fetchSelectedTeam(session, teamid);
    });
  }, [teamid]);

  const openAddTeam = () => {
    props.openAddTeamMemberModal({ showModal: true });
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
    props.fetchTeamClientEntrantCount(session, teamid);
  };

  const paginationCallback = (pageNum) => {
    props.fetchTeamMembers(session, eventid, teamid, pageNum, (response) => {});

    setPageNumber(pageNum);
  };

  const renderList = () => {
    let pageDataLength = SelectedTeamMembers?.data
      ? SelectedTeamMembers?.data?.length
      : 0;

    return _.map(SelectedTeamMembers.data, (item, index) => {
      return (
        <RenderList
          key={`key_${index}`}
          printList={item}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
          pagination={paginationCallback}
          teamid={teamid}
        />
      );
    });
  };

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--5" fluid>
        <CommonGoBack />
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="">
                <div className="pull-left">
                  <h4>
                    {!_.isEmpty(SelectedTeam)
                      ? `${SelectedTeam?.event_by_eventidfk?.name ?? ""} > ${
                          SelectedTeam?.teamname
                        }`
                      : ""}
                  </h4>
                </div>
                <div className="pull-right">
                  {/* Add Entrant */}
                  {SelectedTeam?.withdrawndate ? (
                    ""
                  ) : SelectedTeam?.eventclient_by_eventclientidfk
                      ?.maxteammember >
                      teamClientEntrantCount?.data?.teamentrantcount &&
                    SelectedTeam?.eventclient_by_eventclientidfk?.totalmembers >
                      teamClientEntrantCount?.data?.cliententrantcount ? (
                    <Button
                      className=""
                      color="primary"
                      size="sm"
                      onClick={openAddTeam}
                    >
                      {LocaleStrings.button_add_team_member}
                    </Button>
                  ) : (
                    <Button
                      className=""
                      color="primary"
                      size="sm"
                      disabled={true}
                    >
                      {LocaleStrings.events_teammember_text_team_size_full}
                    </Button>
                  )}
                  {/* {SelectedTeam?.withdrawndate ? (
                    ""
                  ) : (
                    <Button
                      className=""
                      color="primary"
                      size="sm"
                      onClick={openAddTeam}
                    >
                      {LocaleStrings.button_add_team_member}
                    </Button>
                  )} */}
                  {/* <Button className="" color="primary" size="sm">
                    {LocaleStrings.button_copy_parent_link}
                  </Button>
                  <Button className="" color="primary" size="sm">
                    {LocaleStrings.button_export}
                  </Button> */}
                </div>
              </CardHeader>

              <CardBody style={{ height: deviceSize.height }}>
                {SelectedTeamMembers?.data ? (
                  <>
                    {SelectedTeamMembers?.count > 0 ? (
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">
                              {LocaleStrings.events_teammember_th_id}
                            </th>
                            <th scope="col">
                              {LocaleStrings.events_teammember_th_fname}
                            </th>
                            <th scope="col">
                              {LocaleStrings.events_teammember_th_lname}
                            </th>
                            <th scope="col">
                              {LocaleStrings.events_teammember_th_uname}
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>{renderList()}</tbody>
                      </Table>
                    ) : (
                      <div className="no-content-message">
                        <div>
                          <div className="no-content-text">
                            {LocaleStrings.events_teammember_text_no_data}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}

                {SelectedTeamMembers?.count > CUSTOM_ITEM_COUNT ? (
                  <CardFooter className="">
                    <Pagination
                      activePage={pageNumber}
                      itemsCountPerPage={CUSTOM_ITEM_COUNT}
                      totalItemsCount={SelectedTeamMembers?.count}
                      pageRangeDisplayed={3}
                      onChange={paginationCallback}
                    />
                  </CardFooter>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      {ViewTeamMembers?.showModal ? <ViewTeamMember /> : ""}
      {AddTeamMembers?.showModal ? (
        <AddTeamMember
          finishOperationsCallback={additionCallback}
          teamid={teamid}
        />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    SelectedTeam: state.SelectedTeam,
    teamClientEntrantCount: state.teamClientEntrantCount,
    SelectedTeamMembers: state.SelectedTeamMembers,
    ViewTeamMembers: state.ViewTeamMembers,
    AddTeamMembers: state.AddTeamMembers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSelectedTeam,
      fetchTeamMembers,
      fetchTeamClientEntrantCount,
      openAddTeamMemberModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventTeamManage);
