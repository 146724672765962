import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import {
  renderSelect,
  renderAutoCompleteAsync,
  renderFieldText,
} from "../../Common/base-components";
import { LABEL_POSITION_TOP, COMMON_FAIL_MESSAGE } from "../../Common/constant";
import { showSuccess, showError } from "../../Common/alertmessage";
import LocaleStrings from "../../../languages";
import {
  openClientModal,
  addClient,
  validateEmailForClient,
  updateClient,
  editClientData,
  searchClientInfo,
} from "../actions";

const AddClient = (props) => {
  var {
    session,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    addClientModal,
    activeTab,
    editMode,
    initialValues,
  } = props;
  const [loading, setLoading] = useState(false);
  var [selectedClientType, setSelectedClientType] = useState("");

  const closeModal = () => {
    props.editClientData({});
    props.openClientModal({ showModal: false });
  };

  const handleClientType = (e) => {
    // console.log("e :- ", e.target.value);

    props.autofill("referencelist", "");
    setSelectedClientType(e?.target?.value);
  };

  const handleClientInfo = (e, callback) => {
    // console.log("handleClientInfo e : - ", e);
    if (editMode && selectedClientType === "") {
      selectedClientType = initialValues?.type;
    }
    // console.log("selectedClientType : - ", selectedClientType);

    var val = e;

    if (selectedClientType !== "") {
      props.searchClientInfo(session, val, selectedClientType, (response) => {
        var listArr = [];
        if (response?.success) {
          _.map(response?.data, (item) => {
            var obj = { value: item?.id, label: item?.orgname };
            listArr.push(obj);
          });
          callback(listArr);
        } else {
          callback(listArr);
        }
      });
    } else {
      callback([]);
    }
  };

  const onSubmitForm = (values, dispatch) => {
    // console.log("values :- ", values);
    // console.log("initialValues :- ", initialValues);

    var data = { ...values };
    data.referenceid = values?.referencelist?.value;

    // setLoading(true);
    if (editMode) {
      delete data["client_info_by_referenceid"]; // Removing unwanted elememts which is not required to update
      // console.log("final data.... :- ", data);

      props.updateClient(session, data, initialValues, (response) => {
        setLoading(false);
        if (response.success === 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else {
          props.finishOperationsCallback();
          dispatch(reset("ClientForm"));
          showSuccess(LocaleStrings.clients_update_success);
          closeModal();
        }
      });
    } else {
      data.email = data.adminname;
      // console.log("data.. :- ", data);

      props.validateEmailForClient(session, data.email, (feedback) => {
        if (feedback.success == 2) {
          setLoading(false);
          showError(LocaleStrings.clients_email_exist);
        } else if (feedback.success == 1) {
          props.addClient(session, data, (response) => {
            //console.log("response", response);
            setLoading(false);
            if (response.success == 1) {
              props.finishOperationsCallback();
              dispatch(reset("ClientForm"));
              showSuccess(LocaleStrings.clients_add_success);
              closeModal();
            } else {
              showError(COMMON_FAIL_MESSAGE);
            }
          });
        } else {
          setLoading(false);
          showError(COMMON_FAIL_MESSAGE);
        }
      });
    }
  };

  let eventtype = [
    { text: LocaleStrings.events_form_type_option1, value: "school" },
    { text: LocaleStrings.events_form_type_option2, value: "company" },
  ];

  let clientArra = [];

  return (
    <>
      <Modal
        className="event-modal"
        isOpen={addClientModal?.showModal ? true : false}
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {editMode ? LocaleStrings.edit_client : LocaleStrings.add_client}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <Field
              name="type"
              label={LocaleStrings.type_of_client}
              placeholder={LocaleStrings.type_of_client}
              mandatory="true"
              component={renderSelect}
              labelposition={LABEL_POSITION_TOP}
              opts={eventtype}
              onChange={handleClientType}
            />
            <Field
              name="referencelist"
              label={LocaleStrings.client_form_label_client}
              placeholder={LocaleStrings.client_form_ph_client}
              component={renderAutoCompleteAsync}
              mandatory="true"
              loadOptions={handleClientInfo}
            />
            <Field
              name="name"
              label={LocaleStrings.display_name_client}
              placeholder={LocaleStrings.display_name_client}
              type="text"
              mandatory="true"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            {/* {!editMode ? ( */}
            <>
              <Field
                name="adminname"
                label={LocaleStrings.admin_name_client}
                placeholder={LocaleStrings.admin_name_client}
                type="email"
                mandatory="true"
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />

              <Field
                name="password"
                label={LocaleStrings.client_password}
                placeholder={LocaleStrings.client_password}
                type="password"
                mandatory="true"
                component={renderFieldText}
                labelposition={LABEL_POSITION_TOP}
              />
            </>
            {/* ) : (
              ""
            )} */}
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  var type = values["type"];
  var referencelist = values["referencelist"];
  var name = values["name"];
  var adminname = values["adminname"];
  var password = values["password"];
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;

  if (!type) {
    errors["type"] = LocaleStrings.requird;
  }
  if (!referencelist) {
    errors["referencelist"] = LocaleStrings.requird;
  }
  if (!name || name.trim() === "") {
    errors["name"] = LocaleStrings.requird;
  }
  if (!adminname || adminname.trim() === "") {
    errors["adminname"] = LocaleStrings.requird;
  } else if (adminname && !re.test(adminname)) {
    errors["adminname"] = LocaleStrings.invalid_email;
  }
  if (!password || password.trim() === "") {
    errors["password"] = LocaleStrings.requird;
  } else if (password && password.length < 6) {
    errors["password"] = LocaleStrings.password_min_length;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state.editClient);
  var initVals = {};

  if (toEdit) {
    initVals = state.editClient;

    if (state?.editClient?.client_info_by_referenceid)
      initVals.referencelist = {
        value: state?.editClient?.client_info_by_referenceid?.id,
        label: state?.editClient?.client_info_by_referenceid?.orgname,
      };
  }

  return {
    session: state.session,
    addClientModal: state.addClientModal,
    initialValues: initVals,
    editMode: !_.isEmpty(state.editClient),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openClientModal,
      addClient,
      validateEmailForClient,
      updateClient,
      editClientData,
      searchClientInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "ClientForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddClient)
);
