import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, FieldArray, reset } from "redux-form";
import {
  Row,
  Col,
  Button,
  Table,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import _ from "lodash";
import {
  LABEL_POSITION_TOP,
  itemCount,
  COMMON_FAIL_MESSAGE,
} from "../../Common/constant";
import {
  renderFieldText,
  renderHiddenFieldText,
  renderSelect,
} from "../../Common/base-components";
import { showSuccess, showError } from "../../Common/alertmessage";
import { confirmAlert } from "react-confirm-alert";
import LocaleStrings from "../../../languages";
import plus from "../../../assets/img/icons/common/plus-activity-points.svg";
import minus from "../../../assets/img/icons/common/minus-danger-rounded.svg";
import Loader from "../../Common/loader";
import Pagination from "../../Common/pagination";
import {
  fetchBonusPoints,
  addBonusPoints,
  deleteBonusPoints,
  revisedBonus,
  updateBonusPoints,
} from "../actions";
import axiosFileupload from "axios-fileupload";

const BonusPoints = (props) => {
  let {
    session,
    handleSubmit,
    fetchBonusPoints,
    bonusPointsList,
    pristine,
    submitting,
    invalid,
    initialValues,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [bonustype, setBonustype] = useState("sleep");
  const [eventtype, setEventtype] = useState("school");
  const [error, setError] = useState([]);

  useEffect(() => {
    props.revisedBonus(session, []);
    props.fetchBonusPoints(session, eventtype, bonustype, (res) => {});
  }, []);

  const getBonusPoints = () => {
    if (eventtype && bonustype) {
      props.revisedBonus(session, []);
      props.fetchBonusPoints(session, eventtype, bonustype);
    }
  };

  const toggle = () => setOpen(!dropdownOpen);
  const eventTypeArr = [
    { value: "school", text: "School" },
    { value: "team", text: "Team" },
    { value: "guardian", text: "Guardian" },
  ];

  const bonusTypeArr = [
    { value: "sleep", text: "Sleep" },
    { value: "screentime", text: "Screentime" },
    { value: "water", text: "Water" },
    { value: "fruit", text: "Fruit" },
    { value: "veges", text: "Veges" },
    { value: "foods", text: "Foods" },
    { value: "sugar", text: "Sugar" },
    { value: "mindfulness", text: "Mindfulness" },
  ];

  const startTimeArr = ["sleep", "screentime"];
  const endTimeArr = ["sleep", "screentime"];
  const quantityArr = [
    "water",
    "fruit",
    "veges",
    "foods",
    "sugar",
    "mindfulness",
  ];

  const paginationCallback = (pageNum) => {
    props.fetchBonusPoints(session, pageNum, (response) => {});
    setPageNumber(pageNum);
  };

  const onSubmitForm = (values, dispatch) => {
    let error = [];
    let reWhiteSpace = new RegExp(/^\s+$/);

    values.bonustype = bonustype;
    values.eventtype = eventtype;
    values.bonusdata.forEach((act, actIndex) => {
      let objErr = {};
      let c = 0;
      if (
        reWhiteSpace.test(act.starttime) &&
        _.includes(startTimeArr, values.bonustype)
      ) {
        objErr.starttime = LocaleStrings.requird;
        c++;
      }
      if (
        reWhiteSpace.test(act.endtime) &&
        _.includes(endTimeArr, values.bonustype)
      ) {
        objErr.endtime = LocaleStrings.requird;
        c++;
      }
      if (reWhiteSpace.test(act.points)) {
        objErr.points = LocaleStrings.requird;
        c++;
      }
      if (
        reWhiteSpace.test(act.quantity) &&
        _.includes(quantityArr, values.bonustype)
      ) {
        objErr.quantity = LocaleStrings.requird;
        c++;
      }
      if (c > 0) {
        error.push(objErr);
      }
    });

    if (error.length > 0) {
      //console.log("error", error);
      setError(error);
      showError(LocaleStrings.bonuspoints_error);
    } else {
      setLoading(true);
      let insertResource = [];
      let updateResource = [];
      values.bonusdata.forEach((act, actIndex) => {
        if (act.id && act.id != "") {
          let obj = {
            id: act.id,
            eventtype: values.eventtype,
            bonustype: values.bonustype,
            starttime: _.includes(startTimeArr, values.bonustype)
              ? act.starttime
              : null,
            endtime: _.includes(endTimeArr, values.bonustype)
              ? act.endtime
              : null,
            points: act.points,
            quantity: _.includes(quantityArr, values.bonustype)
              ? act.quantity
              : null,
          };
          updateResource.push(obj);
        } else {
          let obj = {
            eventtype: values.eventtype,
            bonustype: values.bonustype,
            starttime: _.includes(startTimeArr, values.bonustype)
              ? act.starttime
              : null,
            endtime: _.includes(endTimeArr, values.bonustype)
              ? act.endtime
              : null,
            points: act.points,
            quantity: _.includes(quantityArr, values.bonustype)
              ? act.quantity
              : null,
          };
          insertResource.push(obj);
        }
      });
      if (insertResource.length > 0 && updateResource.length > 0) {
        props.addBonusPoints(session, insertResource, (res) => {
          if (res.success) {
            props.updateBonusPoints(session, updateResource, (res) => {
              if (res.success) {
                dispatch(reset("BonusForm"));
                setLoading(false);
                getBonusPoints();
                showSuccess(LocaleStrings.bonuspoints_add_success);
              } else {
                setLoading(false);
                showError(COMMON_FAIL_MESSAGE);
              }
            });
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        });
      } else if (insertResource.length > 0 && updateResource.length == 0) {
        props.addBonusPoints(session, insertResource, (res) => {
          if (res.success) {
            dispatch(reset("BonusForm"));
            setLoading(false);
            getBonusPoints();
            showSuccess(LocaleStrings.bonuspoints_add_success);
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        });
      } else if (insertResource.length == 0 && updateResource.length > 0) {
        props.updateBonusPoints(session, updateResource, (res) => {
          if (res.success) {
            dispatch(reset("BonusForm"));
            setLoading(false);
            getBonusPoints();
            showSuccess(LocaleStrings.bonuspoints_add_success);
          } else {
            setLoading(false);
            showError(COMMON_FAIL_MESSAGE);
          }
        });
      }
    }
  };

  const bonusPointsListRender = () => {
    return _.map(bonusPointsList.data, (faq) => {
      return (
        <tr>
          <td style={{ whiteSpace: "normal" }}>{faq.starttime}</td>
          <td style={{ whiteSpace: "normal" }}>{faq.endtime}</td>
          <td>{faq.points}</td>
          <td>{faq.quantity}</td>
          <td>{faq.bonustype}</td>
          <td>{faq.eventtype}</td>
          <td>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() => handleDelete(faq.id)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
        </tr>
      );
    });
  };

  const removeBonus = (id, fields, index) => {
    //console.log("fields");
    if (id) {
      confirmAlert({
        title: LocaleStrings.bonuspoints_delete_title,
        message: LocaleStrings.bonuspoints_delete_messgae,
        buttons: [
          {
            label: LocaleStrings.button_yes,
            onClick: () => {
              props.deleteBonusPoints(session, id, (res) => {
                if (res.success) {
                  let filter = _.filter(bonusPointsList.data, function (list) {
                    return list.id != id;
                  });
                  props.revisedBonus(session, filter);
                  //fields.remove(index);
                } else {
                  //showError(COMMON_FAIL_MESSAGE);
                }
              });
              //
            },
          },
          {
            label: LocaleStrings.button_no,
            onClick: () => {},
          },
        ],
      });
    }
  };

  const renderBonus = ({ fields, meta: { error, submitFailed } }) => {
    //console.log("fields", fields);
    return (
      <ul style={{ paddingLeft: 0 }}>
        {fields.map((bonusdata, index) => (
          <li key={index} style={{ listStyle: "none" }}>
            <Field
              name={`${bonusdata}.id`}
              type="text"
              component={renderHiddenFieldText}
            />

            <Row>
              {_.includes(startTimeArr, bonustype) ? (
                <Col sm="3">
                  <Field
                    name={`${bonusdata}.starttime`}
                    placeholder={LocaleStrings.start_time}
                    type="text"
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                  {error && error.length > 0 && error[index].starttime ? (
                    <div className="text-help label-text-help">
                      {error[index].starttime}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              ) : bonustype == "" ? (
                <Col sm="3">
                  <Field
                    name={`${bonusdata}.starttime`}
                    placeholder={LocaleStrings.start_time}
                    type="text"
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                </Col>
              ) : (
                ""
              )}

              {_.includes(endTimeArr, bonustype) ? (
                <Col sm="3">
                  <Field
                    name={`${bonusdata}.endtime`}
                    placeholder={LocaleStrings.end_time}
                    type="text"
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                  {error && error.length > 0 && error[index].endtime ? (
                    <div className="text-help label-text-help">
                      {error[index].endtime}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              ) : bonustype == "" ? (
                <Col sm="3">
                  <Field
                    name={`${bonusdata}.endtime`}
                    placeholder={LocaleStrings.end_time}
                    type="text"
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                </Col>
              ) : (
                ""
              )}

              <Col sm="3">
                <Field
                  name={`${bonusdata}.points`}
                  placeholder={LocaleStrings.points}
                  component={renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                />
                {error && error.length > 0 && error[index].points ? (
                  <div className="text-help label-text-help">
                    {error[index].points}
                  </div>
                ) : (
                  ""
                )}
              </Col>

              {_.includes(quantityArr, bonustype) ? (
                <Col sm="2">
                  <Field
                    name={`${bonusdata}.quantity`}
                    placeholder={
                      bonustype == "sugar"
                        ? LocaleStrings.teaspoon
                        : LocaleStrings.quantity
                    }
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                  {error && error.length > 0 && error[index].quantity ? (
                    <div className="text-help label-text-help">
                      {error[index].quantity}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              ) : bonustype == "" ? (
                <Col sm="2">
                  <Field
                    name={`${bonusdata}.quantity`}
                    placeholder={LocaleStrings.quantity}
                    component={renderFieldText}
                    labelposition={LABEL_POSITION_TOP}
                  />
                </Col>
              ) : (
                ""
              )}

              <Col sm="1" className="justify-content-end align-items-center">
                <img
                  className="ml-2 cursor-pointer"
                  src={minus}
                  title="Remove Points"
                  onClick={() =>
                    initialValues.bonusdata &&
                    initialValues.bonusdata[index] &&
                    initialValues.bonusdata[index].id
                      ? removeBonus(
                          initialValues.bonusdata[index].id,
                          fields,
                          index
                        )
                      : fields.remove(index)
                  }
                />
              </Col>
            </Row>
          </li>
        ))}
        {submitFailed && error && <span>{error}</span>}
        <li style={{ listStyle: "none" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                padding: "6px 8px",
                borderRadius: 4,
                background: "#5e72e4",
                cursor: "pointer",
              }}
              onClick={() =>
                fields.push({
                  id: "",
                  starttime: "",
                  endtime: "",
                  points: "",
                  quantity: "",
                })
              }
            >
              <img src={plus} />
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const handleDelete = (id) => {
    props.deleteBonusPoints(session, id, (res) => {
      if (res.success) {
        fetchBonusPoints(session);
        showSuccess(LocaleStrings.bonuspoints_delete_success);
      } else {
        showError(COMMON_FAIL_MESSAGE);
      }
    });
  };

  const bonusTypeChange = (e) => {
    //console.log("val", e.target.value);
    setBonustype(e.target.value);
    props.revisedBonus(session, []);
    props.fetchBonusPoints(session, eventtype, e.target.value);
  };

  const eventTypeChange = (e) => {
    //console.log("val", e.target.value);
    setEventtype(e.target.value);
    props.revisedBonus(session, []);
    props.fetchBonusPoints(session, e.target.value, bonustype);
  };
  let bonusOptions = [];
  _.map(bonusTypeArr, (item, index) => {
    bonusOptions.push(
      <option key={index.toString()} value={item.value}>
        {item.text}
      </option>
    );
  });

  let eventOptions = [];
  _.map(eventTypeArr, (item, index) => {
    eventOptions.push(
      <option key={index.toString()} value={item.value}>
        {item.text}
      </option>
    );
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Row>
          <Col sm="12">
            <div className="p-3"> {LocaleStrings.bonus_points} </div>
          </Col>
        </Row>
        <Row className="justify-content-center px-6">
          <Col sm="6">
            <div className="form-group row">
              <label className="control-label-top col-sm-12">
                {LocaleStrings.activity_form_ph_type_of_event}
                <span className="label-mandatory"></span>
              </label>
              <div className="col-sm-12 col-sm-offset-0">
                <select
                  className="form-control input-field"
                  name="eventtype"
                  onChange={(e) => eventTypeChange(e)}
                >
                  {eventOptions}
                </select>
              </div>
            </div>
          </Col>

          <Col sm="6">
            <div className="form-group row">
              <label className="control-label-top col-sm-12">
                {LocaleStrings.bonus_type}
                <span className="label-mandatory"></span>
              </label>
              <div className="col-sm-12 col-sm-offset-0">
                <select
                  className="form-control input-field"
                  name="bonustype"
                  onChange={(e) => bonusTypeChange(e)}
                >
                  {bonusOptions}
                </select>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-center px-6"
          style={{ marginBottom: 10 }}
        >
          <Col sm="12">{LocaleStrings.bonus_points_data}</Col>
        </Row>
        <Row
          className="justify-content-center px-6"
          style={{ marginBottom: 4 }}
        >
          <Col sm="12">
            <ul style={{ paddingLeft: 0 }}>
              <li style={{ listStyle: "none" }}>
                <Row>
                  {_.includes(startTimeArr, bonustype) ? (
                    <Col sm="3">{LocaleStrings.start_time}</Col>
                  ) : bonustype == "" ? (
                    <Col sm="3">{LocaleStrings.start_time}</Col>
                  ) : (
                    ""
                  )}

                  {_.includes(endTimeArr, bonustype) ? (
                    <Col sm="3">{LocaleStrings.end_time}</Col>
                  ) : bonustype == "" ? (
                    <Col sm="3">{LocaleStrings.end_time}</Col>
                  ) : (
                    ""
                  )}

                  <Col sm="3">{LocaleStrings.points}</Col>

                  {_.includes(quantityArr, bonustype) ? (
                    <Col sm="3">
                      {bonustype == "sugar"
                        ? LocaleStrings.teaspoon
                        : LocaleStrings.quantity}
                    </Col>
                  ) : bonustype == "" ? (
                    <Col sm="3">{LocaleStrings.quantity}</Col>
                  ) : (
                    ""
                  )}

                  <Col sm="1"></Col>
                </Row>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center px-6">
          <Col sm="12">
            <FieldArray name="bonusdata" component={renderBonus} />
          </Col>
        </Row>

        <Row className="justify-content-around my-3">
          <Col sm="6">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>
      {/*<hr className="m-3" />
       <Row className="mt-4 mb-7">
        <Col sm="12">
          {bonusPointsList && bonusPointsList.data ? (
            <div style={{ marginTop: 15 }}>
              {bonusPointsList.data && bonusPointsList.data.length > 0 ? (
                <div className="faq-list">
                  <Table
                    className="tablelist align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{LocaleStrings.start_time}</th>
                        <th scope="col">{LocaleStrings.end_time}</th>
                        <th scope="col">{LocaleStrings.points}</th>
                        <th scope="col">{LocaleStrings.quantity}</th>
                        <th scope="col">{LocaleStrings.bonus_type}</th>
                        <th scope="col">
                          {LocaleStrings.activity_form_ph_type_of_event}
                        </th>
                        <th scope="col" style={{ width: "5%" }}></th>
                      </tr>
                    </thead>

                    <tbody>{bonusPointsListRender()}</tbody>
                  </Table>
                </div>
              ) : (
                <div className="no-content-message">
                  {LocaleStrings.no_record_activity}
                </div>
              )}
            </div>
          ) : (
            <div className="loaderstyle">
              <Loader />
            </div>
          )}

          {bonusPointsList && bonusPointsList.count > itemCount ? (
            <div>
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={itemCount}
                totalItemsCount={bonusPointsList?.count}
                pageRangeDisplayed={3}
                onChange={paginationCallback}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row> */}
    </div>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  const startTimeArr = ["sleep", "screentime"];
  const endTimeArr = ["sleep", "screentime"];
  const quantityArr = [
    "water",
    "fruit",
    "veges",
    "foods",
    "sugar",
    "mindfulness",
  ];
  var eventtype = values["eventtype"];
  var bonustype = values["bonustype"];
  var bonusdata = values["bonusdata"];
  let reWhiteSpace = new RegExp(/^\s+$/);
  if (!eventtype || eventtype.trim() === "") {
    errors["eventtype"] = LocaleStrings.requird;
  }
  if (!bonustype || bonustype.trim() === "") {
    errors["bonustype"] = LocaleStrings.requird;
  }
  let activityArrayErrors = [];
  if (bonusdata && bonusdata.length > 0) {
    bonusdata.forEach((act, actIndex) => {
      let objErr = {};
      if (
        reWhiteSpace.test(act.starttime) &&
        _.includes(startTimeArr, bonustype)
      ) {
        objErr.starttime = LocaleStrings.requird;
      }
      if (reWhiteSpace.test(act.endtime) && _.includes(endTimeArr, bonustype)) {
        objErr.endtime = LocaleStrings.requird;
      }
      if (reWhiteSpace.test(act.points)) {
        objErr.points = LocaleStrings.requird;
      }
      if (
        reWhiteSpace.test(act.quantity) &&
        _.includes(quantityArr, bonustype)
      ) {
        objErr.quantity = LocaleStrings.requird;
      }
      activityArrayErrors.push(objErr);
    });
  }

  if (activityArrayErrors.length) {
    errors.bonusdata = activityArrayErrors;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // console.log("settingsReducer: ", state.settingsReducer);

  var initial = [];
  if (
    state.bonusPointsList &&
    state.bonusPointsList.data &&
    state.bonusPointsList.data.length > 0
  ) {
    state.bonusPointsList.data.forEach((act, actIndex) => {
      let obj = {
        id: act.id,
        starttime: act.starttime,
        endtime: act.endtime,
        points: act.points,
        quantity: act.quantity,
      };
      initial.push(obj);
    });
  } else {
    let obj = {
      starttime: "",
      endtime: "",
      points: "",
      quantity: "",
    };
    initial.push(obj);
  }
  return {
    session: state.session,
    bonusPointsList: state.bonusPointsList,
    initialValues: {
      eventtype: "",
      bonustype: "",
      bonusdata: initial,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBonusPoints,
      addBonusPoints,
      deleteBonusPoints,
      revisedBonus,
      updateBonusPoints,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "BonusForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(BonusPoints)
);
