import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// import { Container } from "reactstrap";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { deviceSize } from "../actions";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
// import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  React.useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  function updateSize() {
    if (window.innerWidth < 576) {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: true,
      });
    } else {
      props.deviceSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: false,
      });
    }
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "DETAILS";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/events",
          imgSrc: require("../assets/img/brand/teamchallenge_logo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <ReactNotification className="custom-notification" />

        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          pathname={props.location.pathname}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/events" />
        </Switch>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deviceSize }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
