import {
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest,
} from "../../Common/network-call";
import { BASE_URL, itemCount } from "../../Common/constant";
import _ from "lodash";

export const SETTINGS = "SETTINGS";
export const ACTIVITY_LIST = "ACTIVITY_LIST";
export const FAQ_LIST = "FAQ_LIST";
export const BONUS_POINTS_LIST = "BONUS_POINTS_LIST";
export const FAQ_EDIT = "FAQ_EDIT";
export const ACTIVITY_EDIT = "ACTIVITY_EDIT";
export const TEMPLATE_LIST = "TEMPLATE_LIST";
export const TEMPLATE_EDIT = "TEMPLATE_EDIT";
export const REGION_LIST = "REGION_LIST";
export const REGION_EDIT = "REGION_EDIT";
export const LEGMAP_HOTZONE_LIST = "LEGMAP_HOTZONE_LIST";

export function fetchSettings(session, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/settings`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({ type: SETTINGS, payload: data.resource });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function updateSettings(session, id, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/settings/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export const addActivity = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export const updateActivity = (session, id, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function fetchActivity(session, eventtype, callback) {
  var filter = encodeURI(`(isdeleted=0) and (eventtype=${eventtype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ACTIVITY_LIST,
          payload: { data: data.resource, count: data.resource.length },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function clearActivity() {
  return (dispatch) => {
    dispatch({ type: ACTIVITY_LIST, payload: {} });
  };
}

// export function deleteActivity(session, id, callback) {
//   var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist/${id}`;

//   return (dispatch) => {
//     deleteRequest(
//       url,
//       session,
//       dispatch,
//       (response) => {
//         callback({ success: 1 });
//       },
//       (error) => {
//         callback({ success: 0 });
//       }
//     );
//   };
// }

export function deleteActivity(session, id, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/activitylist/${id}`;
  var resource = { isdeleted: 1 };

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function fetchFaq(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${BASE_URL}api/v2/teamchallenge/_table/faq?include_count=true&order=id%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: FAQ_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export const addFaq = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/faq`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export const updateFaq = (session, id, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/faq/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function deleteFaq(session, id, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/faq/${id}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function fetchBonusPoints(session, eventtype, bonustype, callback) {
  // var perPage = itemCount;
  // var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  // var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = encodeURI(`(eventtype=${eventtype})AND(bonustype=${bonustype})`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints?filter=(${filter})`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: BONUS_POINTS_LIST,
          payload: { data: data.resource },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export function revisedBonus(session, array) {
  return (dispatch) => {
    dispatch({
      type: BONUS_POINTS_LIST,
      payload: { data: array },
    });
  };
}

export const addBonusPoints = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function deleteBonusPoints(session, id, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints/${id}`;
  return (dispatch) => {
    deleteRequest(
      url,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export function updateBonusPoints(session, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/bonuspoints`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

// export function revisedBonus(resource) {
//   console.log("resource: ", resource);
//   return (dispatch) => {
//     dispatch({
//       type: FAQ_EDIT,
//       payload: { data: resource },
//     });
//   };
// }

export const editFaq = (resource = {}) => {
  return (dispatch) => {
    dispatch({
      type: FAQ_EDIT,
      payload: resource,
    });
  };
};

export const editActivity = (resource = {}) => {
  return (dispatch) => {
    dispatch({
      type: ACTIVITY_EDIT,
      payload: resource,
    });
  };
};

export function fetchTemplates(session, pageCount = 0, callback) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;

  var url = `${BASE_URL}api/v2/teamchallenge/_table/emailtemplates?include_count=true&order=id%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: TEMPLATE_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export const addEmailTemplate = (session, resource, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/emailtemplates`;
  var body = {
    resource: resource,
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response });
      },
      (error) => {
        callback(error);
      }
    );
  };
};

export function updateTemplate(session, id, resource, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/emailtemplates/${id}`;

  return (dispatch) => {
    patchRequest(
      url,
      resource,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, response: response });
      },
      (error) => {
        callback({ success: 0, error: error });
      }
    );
  };
}

export const editTemplate = (resource = {}) => {
  return (dispatch) => {
    dispatch({
      type: TEMPLATE_EDIT,
      payload: resource,
    });
  };
};

export function fetchRegion(
  session,
  pageCount = 0,
  countPerPage = 0,
  callback
) {
  var perPage = itemCount;
  if (countPerPage > 0) perPage = countPerPage;

  var filter = encodeURI(`isdeleted=0`);
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var url = `${BASE_URL}api/v2/teamchallenge/_table/masterregion?filter=(${filter})&include_count=true&order=id%20DESC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: REGION_LIST,
          payload: { data: data.resource, count: data.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {}
    );
  };
}

export const addRegion = (session, values, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/masterregion`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
};

export const editRegion = (obj = {}) => {
  return (dispatch) => {
    dispatch({
      type: REGION_EDIT,
      payload: obj,
    });
  };
};

export const updateRegion = (session, values, callback) => {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/masterregion`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1, data: response });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
};

export const fetchHotzones = (
  session,
  extra = { masterlegId: null },
  callback
) => {
  if (!extra?.masterlegId) return (dispatch) => {};

  let filter = encodeURI(`(masterlegidfk=${extra?.masterlegId})`);
  let fields =
    "masterlegidfk,hotzoneid,hotzonename,hotzonedetails,isactive,locationimage";
  let fetchFilter = `${BASE_URL}api/v2/teamchallenge/_table/legmap_hotzones?include_count=true&fields=${fields}&filter=${filter}`;

  return (dispatch) => {
    dispatch({ type: LEGMAP_HOTZONE_LIST, payload: {} });

    getRequest(
      fetchFilter,
      session,
      dispatch,
      (res) => {
        const payloadObj = {
          data: res?.resource,
          count: res?.meta?.count,
          selectedMasterleg: _.toInteger(extra?.masterlegId),
        };
        dispatch({ type: LEGMAP_HOTZONE_LIST, payload: payloadObj });
        callback && callback({ success: 1, ...payloadObj, error: null });
      },
      (error) => {
        callback && callback({ success: 0, data: null, error });
      }
    );
  };
};

export const updateHotzone = (
  session,
  extra = { hotzoneId: null, payload: null },
  callback
) => {
  if (_.isNull(extra?.hotzoneId) || _.isEmpty(extra?.payload))
    return (dispatch) => {};

  let patchUrl = `${BASE_URL}api/v2/teamchallenge/_table/legmap_hotzones/${extra?.hotzoneId}`;

  return (dispatch) => {
    patchRequest(
      patchUrl,
      extra?.payload,
      session,
      dispatch,
      (res) => {
        callback({ success: 1, data: null, error: null });
      },
      (error) => {
        callback({ success: 0, data: null, error });
      }
    );
  };
};
