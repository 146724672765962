import React, { useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { bindActionCreators } from "redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { storeAddEventFormData } from "../actions";
import {
  renderFieldText,
  renderFieldCheckbox,
} from "../../Common/base-components";
import { LABEL_POSITION_TOP } from "../../Common/constant";
import LocaleStrings from "../../../languages";

const AddEventStep3 = (props) => {
  var { EventFormData, settingsReducer, toDisabled } = props;
  const [activeTab, setActiveTab] = useState("1");
  // console.log("settingsReducer : - ", settingsReducer);

  const _toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const haldelLegs = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      legs: value,
    });
  };

  const haldelBonusLegs = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      bonuslegs: value,
    });
  };

  const haldelTeamDailyAvgPointsTargetOpen = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      teamdailyavgtargetpointopen: value,
    });
  };

  const haldelDailyMaxIndividualPointsOpen = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      dailymaxindividualpointopen: value,
    });
  };

  const haldelTeamDailyAvgPointsTargetAdvance = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      teamdailyavgtargetpointadvance: value,
    });
  };

  const haldelDailyMaxIndividualPointsAdvance = (e) => {
    var value = e.target.value;
    props.storeAddEventFormData({
      ...EventFormData,
      dailymaxindividualpointadvance: value,
    });
  };

  // console.log("EventFormData :- ", EventFormData);

  var challengeCoursePeriod =
    EventFormData?.courseOpenDate && EventFormData?.courseCloseDate
      ? EventFormData?.courseCloseDate.diff(
          EventFormData?.courseOpenDate,
          "days"
        )
      : 0;

  // Open section calculation
  var metersreqtofinishalllegsopen =
    settingsReducer?.[0]?.defaultlegopendivision && EventFormData?.legs
      ? settingsReducer[0].defaultlegopendivision * EventFormData?.legs
      : 0;
  var travelratioopen =
    EventFormData?.teamdailyavgtargetpointopen &&
    EventFormData?.teamdailyavgtargetpointopen != 0 &&
    metersreqtofinishalllegsopen != 0 &&
    challengeCoursePeriod != 0
      ? (
          metersreqtofinishalllegsopen /
          challengeCoursePeriod /
          EventFormData?.teamdailyavgtargetpointopen
        ).toFixed(6)
      : (0).toFixed(6);
  var teamavgptreqtofinisheachlegopen =
    settingsReducer?.[0]?.defaultlegopendivision && travelratioopen != 0
      ? (settingsReducer[0].defaultlegopendivision / travelratioopen).toFixed(6)
      : (0).toFixed(6);
  var teamavgpttofinishalllegsopen = EventFormData?.legs
    ? (teamavgptreqtofinisheachlegopen * EventFormData?.legs).toFixed(6)
    : (0).toFixed(6);

  // Advanced section calculation
  var metersreqtofinishalllegsadv =
    settingsReducer?.[0]?.defaultlegadvanceddivision && EventFormData?.legs
      ? settingsReducer[0].defaultlegadvanceddivision * EventFormData?.legs
      : 0;
  var travelratioadv =
    EventFormData?.teamdailyavgtargetpointadvance &&
    EventFormData?.teamdailyavgtargetpointadvance != 0 &&
    metersreqtofinishalllegsadv != 0 &&
    challengeCoursePeriod != 0
      ? (
          metersreqtofinishalllegsadv /
          challengeCoursePeriod /
          EventFormData?.teamdailyavgtargetpointadvance
        ).toFixed(6)
      : (0).toFixed(6);
  var teamavgptreqtofinisheachlegadv =
    settingsReducer?.[0]?.defaultlegadvanceddivision && travelratioadv != 0
      ? (
          settingsReducer[0].defaultlegadvanceddivision / travelratioadv
        ).toFixed(6)
      : (0).toFixed(6);
  var teamavgpttofinishalllegsadv = EventFormData?.legs
    ? (teamavgptreqtofinisheachlegadv * EventFormData?.legs).toFixed(6)
    : (0).toFixed(6);

  return (
    <>
      <div className="form-group row">
        <label className="control-label-top col-12">
          {LocaleStrings.events_form_ph_challenge_course_open_period}:{" "}
          {challengeCoursePeriod}
        </label>
      </div>
      <Field
        name="nooflegs"
        label={LocaleStrings.events_form_label_legs}
        placeholder={LocaleStrings.events_form_ph_legs}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        onChange={haldelLegs}
        // disabled={toDisabled}
      />
      <Field
        name="noofbonuslegs"
        label={LocaleStrings.events_form_label_bonus_legs}
        placeholder={LocaleStrings.events_form_ph_bonus_legs}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        onChange={haldelBonusLegs}
        // disabled={toDisabled}
      />
      <div className="form-group row">
        <label className="control-label-top col-12">
          {LocaleStrings.events_form_label_divisions}
        </label>
        <div className="col-6">
          <Field
            name="isopendivisions"
            type="checkbox"
            label={LocaleStrings.events_form_label_divisions_open}
            component={renderFieldCheckbox}
            disabled={true}
          />
        </div>
        {/* {EventFormData?.eventType === "company" ? ( */}
        <div className="col-6">
          <Field
            name="isadvdivisions"
            type="checkbox"
            label={LocaleStrings.events_form_label_divisions_advance}
            component={renderFieldCheckbox}
            // disabled={toDisabled}
          />
        </div>
        {/* ) : (
          ""
        )} */}
      </div>

      <Nav tabs className="">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              _toggle("1");
            }}
          >
            {LocaleStrings.events_form_label_divisions_open}
          </NavLink>
        </NavItem>
        {EventFormData?.eventType === "company" ? (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                _toggle("2");
              }}
            >
              {LocaleStrings.events_form_label_divisions_advance}
            </NavLink>
          </NavItem>
        ) : (
          ""
        )}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Field
            name="teamavgdailypointstargetopen"
            label={LocaleStrings.events_form_label_open_points_target}
            placeholder={LocaleStrings.events_form_ph_open_points_target}
            type="number"
            mandatory="true"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
            onChange={haldelTeamDailyAvgPointsTargetOpen}
            // disabled={toDisabled}
          />
          <Field
            name="dailymaxindividualopen"
            label={LocaleStrings.events_form_label_open_maximum_points}
            placeholder={LocaleStrings.events_form_ph_open_maximum_points}
            type="number"
            mandatory="true"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
            onChange={haldelDailyMaxIndividualPointsOpen}
            // disabled={toDisabled}
          />
          <div className="form-group">
            <label className="control-label-top">
              {LocaleStrings.events_form_label_open_meter_to_finish_all_legs}
            </label>
            <div className="input-type-div">{metersreqtofinishalllegsopen}</div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {LocaleStrings.events_form_label_open_travel_ratio_points}
            </label>
            <div className="input-type-div">{travelratioopen}</div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {
                LocaleStrings.events_form_label_open_team_averege_points_for_each_leg
              }
            </label>
            <div className="input-type-div">
              {teamavgptreqtofinisheachlegopen}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {
                LocaleStrings.events_form_label_open_team_averege_points_for_all_leg
              }
            </label>
            <div className="input-type-div">{teamavgpttofinishalllegsopen}</div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <Field
            name="teamavgdailypointstargetadv"
            label={LocaleStrings.events_form_label_advance_points_target}
            placeholder={LocaleStrings.events_form_ph_advance_points_target}
            type="number"
            mandatory="true"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
            onChange={haldelTeamDailyAvgPointsTargetAdvance}
            // disabled={toDisabled}
          />
          <Field
            name="dailymaxindividualadv"
            label={LocaleStrings.events_form_label_advance_maximum_points}
            placeholder={LocaleStrings.events_form_ph_advance_maximum_points}
            type="number"
            mandatory="true"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
            onChange={haldelDailyMaxIndividualPointsAdvance}
            // disabled={toDisabled}
          />
          <div className="form-group">
            <label className="control-label-top">
              {LocaleStrings.events_form_label_advance_meter_to_finish_all_legs}
            </label>
            <div className="input-type-div">{metersreqtofinishalllegsadv}</div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {LocaleStrings.events_form_label_advance_travel_ratio_points}
            </label>
            <div className="input-type-div">{travelratioadv}</div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {
                LocaleStrings.events_form_label_advance_team_averege_points_for_each_leg
              }
            </label>
            <div className="input-type-div">
              {teamavgptreqtofinisheachlegadv}
            </div>
          </div>
          <div className="form-group">
            <label className="control-label-top">
              {
                LocaleStrings.events_form_label_advance_team_averege_points_for_all_leg
              }
            </label>
            <div className="input-type-div">{teamavgpttofinishalllegsadv}</div>
          </div>
        </TabPane>
      </TabContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeAddEventFormData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEventStep3);
