import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import DataTable from "react-data-table-component";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";

// constants
import {
  COMMON_FAIL_MESSAGE,
  LOCAL_TO_GMT_TIME,
  convertDateTimeIntoLocal,
  DOMAIN,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import Loader from "../../../Common/loader";
import LocaleStrings from "../../../../languages";

// actions
import {
  fetchEventTeams,
  clearEventTeams,
  searchEventTeams,
  deleteEventClient,
  openViewTeamModal,
  openAddTeamModal,
  updateTeam,
  withdrawAllTeamMember,
  sendWithdrawEmail,
  deleteTeamMemberSession,
  clearSelectedTeam,
  selectedTeam,
} from "../../actions";

// components
import ViewEventTeams from "./event-team-view";
import AddEventTeams from "./event-team-add";

const EventTeams = (props) => {
  const {
    session,
    eventid,
    SingleEventTeams,
    AddTeamModal,
    ViewTeamModal,
    openViewTeamModal,
    updateTeam,
    withdrawAllTeamMember,
    sendWithdrawEmail,
    deleteTeamMemberSession,
    clearSelectedTeam,
    selectedTeam,
    openAddTeamModal,
    SingleEventDetails,
    finishOperationsCallback,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.clearEventTeams();
    props.fetchEventTeams(session, eventid, (response) => {});
  }, []);

  const handleCopy = () => {};

  const handleTeamSize = (teamArray) => {
    // console.log("teamArray: ", teamArray);
    let size = 0;
    _.map(teamArray, (member, key) => {
      // console.log(`member${key}: `, member);
      if (member?.withdrawndate == null) size += 1;
    });
    return size;
  };

  var tempData =
    SingleEventTeams?.data && SingleEventTeams?.data.length > 0
      ? SingleEventTeams.data
      : [];
  let data = [];

  if (!_.isEmpty(tempData)) {
    let myList = [],
      singleDetails = {};

    _.map(tempData, (singleEvCl, key) => {
      var teacher = _.filter(singleEvCl?.teammembers_by_teamidfk, (obj) => {
        return obj?.ismanager && !obj?.isremoved;
      });
      var regiReqId =
        singleEvCl?.registrationrequests_by_teamidfk?.[0]?.id ?? 0;

      let teamAvgPoints = 0;
      if (
        singleEvCl.teampoints &&
        handleTeamSize(singleEvCl?.teammembers_by_teamidfk)
      ) {
        teamAvgPoints = _.round(
          singleEvCl.teampoints /
            handleTeamSize(singleEvCl?.teammembers_by_teamidfk)
        );
      }

      singleDetails = {
        id: key,
        fullDetails: singleEvCl,
        team_id: singleEvCl?.id,
        client_name:
          singleEvCl?.eventclient_by_eventclientidfk?.clients_by_clientidfk
            ?.name,
        team_name: singleEvCl?.teamname,
        teacher: `${teacher?.[0]?.firstname ?? ""} ${
          teacher?.[0]?.lastname ?? ""
        }`,
        teacher_email: `${teacher?.[0]?.username ?? ""}`,
        size: handleTeamSize(singleEvCl?.teammembers_by_teamidfk),
        points: teamAvgPoints,
        confirmed: singleEvCl?.confirmeddate
          ? convertDateTimeIntoLocal(singleEvCl?.confirmeddate)
          : "",
        withdrawned: singleEvCl?.withdrawndate
          ? convertDateTimeIntoLocal(singleEvCl?.withdrawndate)
          : "",
        registrationUrl: SingleEventDetails?.data?.subdomain
          ? `https://${SingleEventDetails?.data?.subdomain}.${DOMAIN}#/registration-series/${regiReqId}`
          : "",
      };
      myList.push(singleDetails);
    });

    data = myList;
  }

  var filteredData = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(search.toLowerCase()) !== -1
  );

  const columns = [
    {
      name: LocaleStrings.events_team_th_id,
      selector: (row) => row.team_id,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_client_name,
      selector: (row) => row.client_name,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_name,
      selector: (row) => row.team_name,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_teacher,
      selector: (row) => row.teacher,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_teacher_email,
      selector: (row) => row.teacher_email,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_size,
      selector: (row) => row.size,
      sortable: true,
    },
    {
      name: LocaleStrings.events_team_th_points,
      selector: (row) => row.points,
      sortable: true,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_confirmed_Date,
      selector: (row) => row.confirmed,
      // sortable: true,
      wrap: true,
    },
    {
      name: LocaleStrings.events_team_th_withdrawn_Date,
      selector: (row) => row.withdrawned,
      // sortable: true,
      wrap: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <UncontrolledDropdown right>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => handleOptions(e)}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            style={{ height: "auto", top: -7, left: -48, overflow: "auto" }}
          >
            <DropdownItem onClick={() => openViewTeam(row)}>
              {LocaleStrings.button_view}
            </DropdownItem>
            {row.fullDetails?.withdrawndate ? (
              ""
            ) : (
              <DropdownItem onClick={() => openAddTeam(row)}>
                {LocaleStrings.button_edit}
              </DropdownItem>
            )}
            {row.fullDetails?.withdrawndate ? (
              ""
            ) : (
              <DropdownItem onClick={() => handleWithdraw(row)}>
                {LocaleStrings.button_withdraw}
              </DropdownItem>
            )}
            <DropdownItem onClick={() => handleTeamManage(row)}>
              {LocaleStrings.button_team_manage}
            </DropdownItem>
            <CopyToClipboard
              text={row.registrationUrl}
              onCopy={() => handleCopy()}
            >
              <DropdownItem>{LocaleStrings.button_copy_link}</DropdownItem>
            </CopyToClipboard>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const handleOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const openAddTeam = (row) => {
    openAddTeamModal({
      showModal: true,
      value: row.fullDetails,
    });
  };

  const openViewTeam = (row) => {
    openViewTeamModal({ showModal: true, teamdata: row.fullDetails });
  };

  const handleWithdraw = (row) => {
    let values = {
      id: row.fullDetails?.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    if (
      row?.fullDetails?.registrationrequests_by_teamidfk?.[0]?.status == 2 ||
      row?.fullDetails?.registrationrequests_by_teamidfk?.[0]?.status == 3
    ) {
      values.registrationrequests_by_teamidfk = [
        {
          id: row?.fullDetails?.registrationrequests_by_teamidfk?.[0]?.id,
          email: `expired_${row?.fullDetails?.registrationrequests_by_teamidfk?.[0]?.email}`,
          status: 4,
        },
      ];
    }

    // for delete df users session
    var activeMembers = _.filter(
      row.fullDetails?.teammembers_by_teamidfk,
      (obj) => {
        return !obj?.withdrawndate;
      }
    );
    var dfids = _.map(activeMembers, "dfid");

    confirmAlert({
      title: LocaleStrings.events_team_withdraw_confirm_title,
      message: `${LocaleStrings.events_team_withdraw_confirm_subtitle} ${row.fullDetails.teamname}?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            updateTeam(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                withdrawAllTeamMember(
                  session,
                  row.fullDetails?.id,
                  LOCAL_TO_GMT_TIME,
                  (res) => {
                    finishOperationsCallback();
                    sendWithdrawEmail(
                      session,
                      row?.fullDetails?.registrationrequests_by_teamidfk?.[0]
                        ?.id,
                      (res) => {}
                    );
                    if (dfids?.length > 0) {
                      deleteTeamMemberSession(session, dfids.join(","));
                    }
                    showSuccess(
                      LocaleStrings.events_team_withdraw_alert_success
                    );
                  }
                );
              }
            });
          },
        },
      ],
      childrenElement: () => (
        <div className="mt-5 mb-5 font-weight-bold">
          {LocaleStrings.events_team_withdraw_confirm_warning}
        </div>
      ),
    });
  };

  const handleTeamManage = (row) => {
    clearSelectedTeam({}); // To clear the team first, if redux have any
    selectedTeam(row);

    setTimeout(() => {
      history.push({
        pathname: `/admin/event-team/${row.fullDetails?.eventidfk}/${row.fullDetails?.id}`,
      });
    }, 500);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      setTimeout(() => {
        props.fetchEventTeams(session, eventid, (response) => {});
      }, 1000);
    } else {
      props.searchEventTeams(session, search, eventid, pageNum);
    }
    setPageNumber(pageNum);
  };

  const subHeaderComponent = useMemo(() => {
    return (
      <div className="custom-header pb-3 pull-right">
        <ul className="header-buttons">
          <li className="buttons-option">
            <input
              className="form-control inputsearch buttons-option"
              placeholder={LocaleStrings.search}
              type="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </li>
        </ul>
      </div>
    );
  }, [search]);

  return (
    <>
      {SingleEventTeams?.data ? (
        <>
          {SingleEventTeams?.count > 0 ? (
            <DataTable
              className="common-data-table"
              columns={columns}
              data={filteredData}
              defaultSortField="client_name"
              pagination
              striped
              subHeader
              subHeaderComponent={subHeaderComponent}
            />
          ) : (
            <div className="no-content-message">
              <div>
                <div className="no-content-text">
                  {LocaleStrings.events_team_text_no_data}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      {AddTeamModal?.showModal ? (
        <AddEventTeams finishOperationsCallback={additionCallback} />
      ) : (
        ""
      )}
      {ViewTeamModal?.showModal ? <ViewEventTeams /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    SingleEventTeams: state.SingleEventTeams,
    AddTeamModal: state.AddTeamModal,
    ViewTeamModal: state.ViewTeamModal,
    SingleEventDetails: state.SingleEventDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEventTeams,
      clearEventTeams,
      searchEventTeams,
      deleteEventClient,
      openAddTeamModal,
      openViewTeamModal,
      updateTeam,
      withdrawAllTeamMember,
      sendWithdrawEmail,
      deleteTeamMemberSession,
      clearSelectedTeam,
      selectedTeam,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventTeams);
