import {
  SETTINGS,
  ACTIVITY_LIST,
  FAQ_LIST,
  BONUS_POINTS_LIST,
  FAQ_EDIT,
  ACTIVITY_EDIT,
  TEMPLATE_LIST,
  TEMPLATE_EDIT,
  REGION_LIST,
  REGION_EDIT,
  LEGMAP_HOTZONE_LIST,
} from "../actions";

export function settingsReducer(state = {}, action) {
  switch (action.type) {
    case SETTINGS:
      return action.payload;
    default:
      return state;
  }
}

export function activityListReducer(state = {}, action) {
  switch (action.type) {
    case ACTIVITY_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function faqList(state = [], action) {
  switch (action.type) {
    case FAQ_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function bonusPointsList(state = [], action) {
  switch (action.type) {
    case BONUS_POINTS_LIST:
      return action.payload;
    default:
      return state;
  }
}

export const editFaqDetails = (state = {}, action) => {
  if (action.type == FAQ_EDIT) {
    return action.payload;
  }
  return state;
};

export const editActivityDetails = (state = {}, action) => {
  if (action.type == ACTIVITY_EDIT) {
    return action.payload;
  }
  return state;
};

export function emailTemplateList(state = [], action) {
  switch (action.type) {
    case TEMPLATE_LIST:
      return action.payload;
    default:
      return state;
  }
}

export const editEmailTemplate = (state = {}, action) => {
  if (action.type == TEMPLATE_EDIT) {
    return action.payload;
  }
  return state;
};

export const regionList = (state = {}, action) => {
  if (action.type == REGION_LIST) {
    return action.payload;
  }
  return state;
};

export const editRegionInfo = (state = {}, action) => {
  if (action.type == REGION_EDIT) {
    return action.payload;
  }
  return state;
};

export const legmapHotezonesReducer = (state = {}, action) => {
  if (action.type === LEGMAP_HOTZONE_LIST) return action.payload;
  return state;
};
