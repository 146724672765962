import {
  ACTIVE_EVENT_LIST,
  INACTIVE_EVENT_LIST,
  ADD_EVENT_MODAL,
  EVENT_CURRENT_STEP,
  EVENT_FORM_DATA,
  SINGLE_EVENT_DETAILS,
  EVENT_URL_MODAL,
  SINGLE_EVENT_ALL_CLIENT_LIST,
  SINGLE_EVENT_CLIENTS,
  SELECTED_CLIENT,
  SINGLE_EVENT_REGISTRATION_REQUEST,
  REQUESTED_USER_EMAIL_UPPDATE_MODAL,
  EVENT_CLIENT_LIST_MODAL,
  SINGLE_EVENT_TEAMS,
  ADD_EVENT_CLIENT_MODAL,
  ADD_TEAM_MODAL,
  VIEW_TEAM_MODAL,
  SELECTED_TEAM,
  TEAM_CLIENT_ENTRANT_COUNT,
  SELECTED_TEAM_MEMBERS,
  VIEW_TEAM_MEMBER_MODAL,
  ADD_TEAM_MEMBER_MODAL,
  EVENT_TEMPLATE_LIST,
  EVENT_TEMPLATE_EDIT,
} from "../actions";

export function ActiveEventList(state = {}, action) {
  if (action.type === ACTIVE_EVENT_LIST) {
    return action.payload;
  }
  return state;
}

export function InactiveEventList(state = {}, action) {
  if (action.type === INACTIVE_EVENT_LIST) {
    return action.payload;
  }
  return state;
}

export function AddEventModal(state = {}, action) {
  if (action.type === ADD_EVENT_MODAL) {
    return action.payload;
  }
  return state;
}

export function EventCurrentStep(state = {}, action) {
  if (action.type === EVENT_CURRENT_STEP) {
    return action.payload;
  }
  return state;
}

export function EventFormData(state = {}, action) {
  if (action.type === EVENT_FORM_DATA) {
    return action.payload;
  }
  return state;
}

export function SingleEventDetails(state = {}, action) {
  if (action.type === SINGLE_EVENT_DETAILS) {
    return action.payload;
  }
  return state;
}

export function EventUrlModal(state = {}, action) {
  if (action.type === EVENT_URL_MODAL) {
    return action.payload;
  }
  return state;
}

export function singleEventAllClients(state = {}, action) {
  if (action.type === SINGLE_EVENT_ALL_CLIENT_LIST) {
    return action.payload;
  }
  return state;
}

export function SingleEventClients(state = {}, action) {
  if (
    action.type === SINGLE_EVENT_CLIENTS &&
    (state.currentSearch === action.payload.lastSearch ||
      action.payload?.currentSearch)
  ) {
    return action.payload;
  }
  return state;
}

export function SingleEventRegistrationRequest(state = {}, action) {
  if (action.type === SINGLE_EVENT_REGISTRATION_REQUEST) {
    return action.payload;
  }
  return state;
}

export function requestedUserEmailUpdateModal(state = {}, action) {
  if (action.type === REQUESTED_USER_EMAIL_UPPDATE_MODAL) {
    return action.payload;
  }
  return state;
}

export function eventClientListModalStatus(state = {}, action) {
  if (action.type === EVENT_CLIENT_LIST_MODAL) {
    return action.payload;
  }
  return state;
}

export function SingleEventTeams(state = {}, action) {
  if (
    action.type === SINGLE_EVENT_TEAMS &&
    (state.currentSearch === action.payload.lastSearch ||
      action.payload?.currentSearch)
  ) {
    return action.payload;
  }
  return state;
}

export function AddEventClientModal(state = {}, action) {
  if (action.type === ADD_EVENT_CLIENT_MODAL) {
    return action.payload;
  }
  return state;
}

export const SelectedClient = (state = {}, action) => {
  if (action.type === SELECTED_CLIENT) {
    return action.payload;
  }
  return state;
};

export function AddTeamModal(state = {}, action) {
  if (action.type === ADD_TEAM_MODAL) {
    return action.payload;
  }
  return state;
}

export function ViewTeamModal(state = {}, action) {
  if (action.type === VIEW_TEAM_MODAL) {
    return action.payload;
  }
  return state;
}

export function SelectedTeam(state = {}, action) {
  if (action.type === SELECTED_TEAM) {
    return action.payload;
  }
  return state;
}

export function teamClientEntrantCount(state = {}, action) {
  if (action.type === TEAM_CLIENT_ENTRANT_COUNT) {
    return action.payload;
  }
  return state;
}

export function SelectedTeamMembers(state = {}, action) {
  if (action.type === SELECTED_TEAM_MEMBERS) {
    return action.payload;
  }
  return state;
}

export function ViewTeamMembers(state = {}, action) {
  if (action.type === VIEW_TEAM_MEMBER_MODAL) {
    return action.payload;
  }
  return state;
}

export function AddTeamMembers(state = {}, action) {
  if (action.type === ADD_TEAM_MEMBER_MODAL) {
    return action.payload;
  }
  return state;
}

export function eventEmailTemplateList(state = {}, action) {
  if (action.type === EVENT_TEMPLATE_LIST) {
    return action.payload;
  }
  return state;
}

export function editEventEmailTemplate(state = {}, action) {
  if (action.type === EVENT_TEMPLATE_EDIT) {
    return action.payload;
  }
  return state;
}
