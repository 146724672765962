import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button, Table } from "reactstrap";
import {
  openEmailRelatedModal,
  validateEmailRelated,
  addEmailRelated,
  updateEmailRelated,
  fetchCoachs,
  fetchCoachEmails,
  fetchSystemEmails,
} from "../../../../actions";
import {
  renderHiddenFieldText,
  validateEmail,
} from "../../../Common/base-components";
import { COMMON_FAIL_MESSAGE } from "../../../Common/constant";
import {
  showSuccess,
  showError,
  showWarning,
} from "../../../Common/alertmessage";
import Loader from "../../../Common/loader";
import LocaleStrings from "../../../../languages";

const AddEmailOptions = (props) => {
  var {
    session,
    ManageEmailRelatedModal,
    toEdit,
    coachList,
    coachEmails,
    systemEmails,

    handleSubmit,
    pristine,
    invalid,
    submitting,
  } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.openEmailRelatedModal({ showModal: false });
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);
    // console.log("toEdit :- ", toEdit);

    var table = ManageEmailRelatedModal?.type ?? "";
    var filter = "";
    if (table === "coach") {
      filter = `coach?filter=(name=${values.name.trim()})`;
      values.name = values.name.trim();
    } else if (table === "coachemail") {
      filter = `coachemail?filter=(email=${values.email.trim()})`;
      values.email = values.email.trim();
    } else if (table === "systememail") {
      filter = `systememail?filter=(email=${values.email.trim()})`;
      values.email = values.email.trim();
    }

    // console.log("final values :- ", values);
    setLoading(true);
    props.validateEmailRelated(session, filter, (res) => {
      // console.log("res :- ", res);
      if (res.success == 1) {
        if (toEdit) {
          props.updateEmailRelated(session, table, values, (response) => {
            // console.log('response',response);
            setLoading(false);
            if (response.success === 0) {
              showError(COMMON_FAIL_MESSAGE);
            } else {
              showSuccess(LocaleStrings.common_success_message);
              props.reset();
              if (table === "coach") {
                props.fetchCoachs(session);
              } else if (table === "coachemail") {
                props.fetchCoachEmails(session);
              } else if (table === "systememail") {
                props.fetchSystemEmails(session);
              }
            }
          });
        } else {
          props.addEmailRelated(session, table, values, (response) => {
            // console.log('response',response);
            setLoading(false);
            if (response.success === 0) {
              showError(COMMON_FAIL_MESSAGE);
            } else {
              showSuccess(LocaleStrings.common_success_message);
              props.reset();
              if (table === "coach") {
                props.fetchCoachs(session);
              } else if (table === "coachemail") {
                props.fetchCoachEmails(session);
              } else if (table === "systememail") {
                props.fetchSystemEmails(session);
              }
            }
          });
        }
      } else if (res.success == 2) {
        setLoading(false);
        var message =
          table === "coach"
            ? LocaleStrings.email_settings_form_validate_coach_already_exist
            : table === "coachemail"
            ? LocaleStrings.email_settings_form_validate_coach_email_already_exist
            : table === "systememail"
            ? LocaleStrings.email_settings_form_validate_system_email_already_exist
            : "";
        showWarning(message);
      } else {
        setLoading(false);
        showError(COMMON_FAIL_MESSAGE);
      }
    });
  };

  const editEmailOption = (item) => {
    props.reset();
    props.openEmailRelatedModal({ ...ManageEmailRelatedModal });
    // console.log("item :- ", item);

    setTimeout(() => {
      props.openEmailRelatedModal({ ...ManageEmailRelatedModal, value: item });
    }, 500);
  };

  const renderCustomFieldText = (field) => {
    var { meta, placeholder, type } = field;
    var divClassName = `col-12 ${
      meta.touched && meta.error ? "has-danger" : ""
    }`;
    var inputClasses = "d-flex";

    return (
      <div className={divClassName}>
        <div className={inputClasses}>
          <input
            className="form-control input-field"
            type={type}
            placeholder={placeholder}
            {...field.input}
            maxLength={field.maxlength ? field.maxlength : ""}
          />

          <Button
            className="ml-2"
            color="primary"
            type="submit"
            disabled={pristine || invalid || submitting || loading}
          >
            {loading ? (
              <i className="fa fa-refresh fa-spin"></i>
            ) : (
              LocaleStrings.button_save
            )}
          </Button>
        </div>
        <div className="text-help">{meta.touched ? meta.error : ""}</div>
      </div>
    );
  };

  const renderList = () => {
    var listData =
      ManageEmailRelatedModal?.type === "coach"
        ? coachList.data
        : ManageEmailRelatedModal?.type === "coachemail"
        ? coachEmails.data
        : ManageEmailRelatedModal?.type === "systememail"
        ? systemEmails.data
        : [];

    return _.map(listData, (item, index) => {
      return (
        <tr
          className="cursor-pointer"
          key={`key_${index}`}
          // onDoubleClick={() => editEmailOption(item)}
          onClick={() => editEmailOption(item)}
        >
          <td>
            {ManageEmailRelatedModal?.type === "coach"
              ? item.name
              : ManageEmailRelatedModal?.type === "coachemail"
              ? item.email
              : ManageEmailRelatedModal?.type === "systememail"
              ? item.email
              : ""}
          </td>
        </tr>
      );
    });
  };

  var listData =
    ManageEmailRelatedModal?.type === "coach"
      ? coachList.data
      : ManageEmailRelatedModal?.type === "coachemail"
      ? coachEmails.data
      : ManageEmailRelatedModal?.type === "systememail"
      ? systemEmails.data
      : [];

  var listDataCount =
    ManageEmailRelatedModal?.type === "coach"
      ? coachList?.count
      : ManageEmailRelatedModal?.type === "coachemail"
      ? coachEmails?.count
      : ManageEmailRelatedModal?.type === "systememail"
      ? systemEmails?.count
      : 0;

  return (
    <>
      <Modal
        className=""
        isOpen={ManageEmailRelatedModal?.showModal ? true : false}
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {ManageEmailRelatedModal?.type === "coach"
              ? LocaleStrings.email_settings_coach_add_title
              : ManageEmailRelatedModal?.type === "coachemail"
              ? LocaleStrings.email_settings_coach_email_add_title
              : ManageEmailRelatedModal?.type === "systememail"
              ? LocaleStrings.email_settings_system_email_add_title
              : ""}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="form-group row">
              <label className="control-label-top col-12">
                {LocaleStrings.email_settings_form_label_text}:{" "}
                <span className="label-mandatory">*</span>
              </label>

              <Field
                name={
                  ManageEmailRelatedModal?.type === "coach"
                    ? "name"
                    : ManageEmailRelatedModal?.type === "coachemail"
                    ? "email"
                    : ManageEmailRelatedModal?.type === "systememail"
                    ? "email"
                    : ""
                }
                placeholder={LocaleStrings.email_settings_form_ph_text}
                type="text"
                component={renderCustomFieldText}
              />
              <Field
                name={
                  ManageEmailRelatedModal?.type === "coach"
                    ? "coachid"
                    : ManageEmailRelatedModal?.type === "coachemail"
                    ? "coachemailid"
                    : ManageEmailRelatedModal?.type === "systememail"
                    ? "systememailid"
                    : ""
                }
                type="text"
                component={renderHiddenFieldText}
              />
            </div>
          </form>

          <hr />

          {listData ? (
            <>
              {listDataCount > 0 ? (
                <Table className="table-bordered table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">
                        {LocaleStrings.email_settings_th_text}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderList()}</tbody>
                </Table>
              ) : (
                <div className="no-content-message">
                  <div>
                    <div className="no-content-text">
                      {LocaleStrings.events_client_text_no_data}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>

        <div className="modal-footer">
          <Button color="secondary" onClick={closeModal}>
            {LocaleStrings.button_close}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const validate = (values, ownProps) => {
  var errors = {};
  var field = "";
  var type = ownProps?.ManageEmailRelatedModal?.type ?? "";

  if (type === "coach") {
    field = "name";
  } else if (type === "coachemail") {
    field = "email";
  } else if (type === "systememail") {
    field = "email";
  }

  if (!values[field] || values[field].trim() === "") {
    errors[field] = LocaleStrings.requird;
  }
  if (values[field] && field === "email" && !validateEmail(values[field])) {
    errors[field] = LocaleStrings.invalid_email;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // console.log(
  //   "state.ManageEmailRelatedModal :- ",
  //   state.ManageEmailRelatedModal
  // );

  var toEdit = !_.isEmpty(state.ManageEmailRelatedModal?.value);
  var initVals = {};

  if (toEdit) {
    initVals = state.ManageEmailRelatedModal?.value;
  }

  return {
    session: state.session,
    initialValues: initVals,
    toEdit,
    ManageEmailRelatedModal: state.ManageEmailRelatedModal,
    coachList: state.coachList,
    coachEmails: state.coachEmails,
    systemEmails: state.systemEmails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openEmailRelatedModal,
      validateEmailRelated,
      addEmailRelated,
      updateEmailRelated,
      fetchCoachs,
      fetchCoachEmails,
      fetchSystemEmails,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EmailOptionsForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddEmailOptions)
);
