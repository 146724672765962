import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button } from "reactstrap";
import {
  requestedUserEmailModal,
  fetchSingleRequestedUser,
  updateIntrestedUsers,
} from "../../actions";
import { validateEmailFromSystemDB } from "../../../../actions";
import { renderFieldText } from "../../../Common/base-components";
import { COMMON_FAIL_MESSAGE } from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";

const RequestedUserEmailUpdate = (props) => {
  var {
    session,
    requestedUserEmailUpdateModal,

    handleSubmit,
    pristine,
    invalid,
    submitting,
  } = props;
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    props.requestedUserEmailModal({ show: false });
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);

    setLoading(true);
    props.fetchSingleRequestedUser(session, values?.id, (respo) => {
      // console.log("respo :- ", respo);
      if (respo?.success === 0) {
        setLoading(false);
        showError(COMMON_FAIL_MESSAGE);
      } else {
        if (respo?.data?.resource?.[0]?.status > 2) {
          setLoading(false);
          showError(LocaleStrings.events_regi_request_email_form_updated_error);
        } else {
          props.validateEmailFromSystemDB(session, values?.email, (res) => {
            // console.log("res :- ", res);
            if (res?.success === 0) {
              setLoading(false);
              showError(COMMON_FAIL_MESSAGE);
            } else {
              if (res?.data?.resource?.length > 0) {
                setLoading(false);
                showError(LocaleStrings.common_email_exist_in_system_message);
              } else {
                var data = { id: values?.id, email: values?.email };
                props.updateIntrestedUsers(session, data, (response) => {
                  // console.log('response',response);
                  setLoading(false);
                  if (response.success === 0) {
                    showError(COMMON_FAIL_MESSAGE);
                  } else {
                    showSuccess(
                      LocaleStrings.events_regi_request_email_form_updated_success
                    );
                    props.finishOperationsCallback();
                    closeModal();
                  }
                });
              }
            }
          });
        }
      }
    });
  };

  return (
    <>
      <Modal
        className=""
        isOpen={requestedUserEmailUpdateModal?.show ? true : false}
      >
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_regi_request_email_form_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="modal-body">
            <Field
              name="email"
              label={LocaleStrings.events_regi_request_email_form_label_email}
              placeholder={
                LocaleStrings.events_regi_request_email_form_ph_email
              }
              type="email"
              mandatory="true"
              component={renderFieldText}
            />
          </div>

          <div className="modal-footer">
            <Button color="secondary" onClick={closeModal}>
              {LocaleStrings.button_close}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={pristine || invalid || submitting || loading}
            >
              {loading ? <i className="fa fa-refresh fa-spin"></i> : ""}{" "}
              {LocaleStrings.button_save}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const validate = (values) => {
  var errors = {};
  var email = values["email"];

  if (!email) {
    errors["email"] = LocaleStrings.requird;
  }

  return errors;
};

const mapStateToProps = (state) => {
  var initVals = state?.requestedUserEmailUpdateModal?.data;

  return {
    session: state.session,
    initialValues: initVals,
    requestedUserEmailUpdateModal: state.requestedUserEmailUpdateModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestedUserEmailModal,
      fetchSingleRequestedUser,
      updateIntrestedUsers,
      validateEmailFromSystemDB,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "RequestedUserEmailUpdateForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RequestedUserEmailUpdate)
);
