import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Row } from "reactstrap";
import { openViewTeamMemberModal } from "../../../actions";
import { convertDateTimeIntoLocal } from "../../../../Common/constant";
import LocaleStrings from "../../../../../languages";

const ViewTeamMember = (props) => {
  var { ViewTeamMembers } = props;

  const closeModal = () => {
    props.openViewTeamMemberModal({ showModal: false });
  };

  return (
    <>
      <Modal className="" isOpen={ViewTeamMembers?.showModal ? true : false}>
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_teammember_view_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body text-sm">
          <h4 className="text-center mb-2">{`${ViewTeamMembers?.data?.firstname} ${ViewTeamMembers?.data?.lastname}`}</h4>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_id}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.id}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_teamid}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.teamidfk}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_fname}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.firstname}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_lname}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.lastname}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_uname}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.username}</div>
          </Row>
          {/* <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_password}
            </div>
            <div className="col-7">: {ViewTeamMembers?.data?.password}</div>
          </Row> */}
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_manager}
            </div>
            <div className="col-7">
              <div className="d-flex align-items-center">
                :{" "}
                {ViewTeamMembers?.data?.ismanager ? (
                  <i className="ni ni-check-bold text-lg ml-1"></i>
                ) : (
                  <i className="ni ni-fat-remove text-lg ml-1"></i>
                )}
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_last_web_login}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.lastloginwebdate
                ? convertDateTimeIntoLocal(
                    ViewTeamMembers?.data?.lastloginwebdate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_last_app_login}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.lastloginappdate
                ? convertDateTimeIntoLocal(
                    ViewTeamMembers?.data?.lastloginappdate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_withdrawn}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.withdrawndate
                ? convertDateTimeIntoLocal(ViewTeamMembers?.data?.withdrawndate)
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_invite_expired}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.inviteexpireddate
                ? convertDateTimeIntoLocal(
                    ViewTeamMembers?.data?.inviteexpireddate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_invite_declined}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.invitedeclineddate
                ? convertDateTimeIntoLocal(
                    ViewTeamMembers?.data?.invitedeclineddate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_created}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.createdon
                ? convertDateTimeIntoLocal(ViewTeamMembers?.data?.createdon)
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_virtual}
            </div>
            <div className="col-7">
              <div className="d-flex align-items-center">
                :{" "}
                {ViewTeamMembers?.data?.isvirtual ? (
                  <i className="ni ni-check-bold text-lg ml-1"></i>
                ) : (
                  <i className="ni ni-fat-remove text-lg ml-1"></i>
                )}
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_removed}
            </div>
            <div className="col-7">
              <div className="d-flex align-items-center">
                :{" "}
                {ViewTeamMembers?.data?.isremoved ? (
                  <i className="ni ni-check-bold text-lg ml-1"></i>
                ) : (
                  <i className="ni ni-fat-remove text-lg ml-1"></i>
                )}
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_started}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.starteddate
                ? convertDateTimeIntoLocal(ViewTeamMembers?.data?.starteddate)
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_teammember_view_text_finished}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamMembers?.data?.finisheddate
                ? convertDateTimeIntoLocal(ViewTeamMembers?.data?.finisheddate)
                : ""}
            </div>
          </Row>
        </div>

        <div className="modal-footer">
          <Button color="secondary" onClick={closeModal}>
            {LocaleStrings.button_close}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    ViewTeamMembers: state.ViewTeamMembers,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openViewTeamMemberModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewTeamMember);
