import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { bindActionCreators } from "redux";
import moment from "moment";
import { storeAddEventFormData } from "../actions";
import {
  renderFieldText,
  renderDatePickerWithLabel,
} from "../../Common/base-components";
import { LABEL_POSITION_TOP } from "../../Common/constant";
import LocaleStrings from "../../../languages";

const AddEventStep2 = (props) => {
  var { EventFormData, toDisabled } = props;

  const handleCourseOpenPeriod = (e) => {
    props.storeAddEventFormData({
      ...EventFormData,
      courseOpenDate: moment(e),
    });
  };

  const handleCourseClosePeriod = (e) => {
    props.storeAddEventFormData({
      ...EventFormData,
      courseCloseDate: moment(e),
    });
  };

  var challengeCoursePeriod =
    EventFormData?.courseOpenDate && EventFormData?.courseCloseDate
      ? EventFormData?.courseCloseDate.diff(
          EventFormData?.courseOpenDate,
          "days"
        )
      : 0;

  return (
    <>
      <Field
        name="siteopendate"
        label={LocaleStrings.events_form_label_site_start_date}
        placeholder={LocaleStrings.events_form_ph_site_start_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="siteclosedate"
        label={LocaleStrings.events_form_label_site_end_date}
        placeholder={LocaleStrings.events_form_ph_site_end_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="dataentryclosedate"
        label={LocaleStrings.events_form_data_entry_close_data}
        placeholder={LocaleStrings.events_form_ph_data_entry_close_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="regopendate"
        label={LocaleStrings.events_form_label_reg_start_date}
        placeholder={LocaleStrings.events_form_ph_reg_start_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="regclosedate"
        label={LocaleStrings.events_form_label_reg_end_date}
        placeholder={LocaleStrings.events_form_ph_reg_end_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="invitationexpires"
        label={LocaleStrings.events_form_label_invitation_expires_days}
        placeholder={LocaleStrings.events_form_ph_invitation_expires_days}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="autoreminderinterval"
        label={LocaleStrings.events_form_label_auto_reminder_days}
        placeholder={LocaleStrings.events_form_ph_auto_reminder_days}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="withdrawninterval"
        label={LocaleStrings.events_form_label_withdrawn_interval}
        placeholder={LocaleStrings.events_form_ph_withdrawn_interval}
        type="number"
        mandatory="true"
        component={renderFieldText}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="indregcutoffdate"
        label={LocaleStrings.events_form_label_indi_reg_cutoff_date}
        placeholder={LocaleStrings.events_form_ph_indi_reg_cutoff_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        // disabled={toDisabled}
      />
      <Field
        name="challengecoursestartdate"
        label={LocaleStrings.events_form_label_challenge_course_start_date}
        placeholder={LocaleStrings.events_form_ph_challenge_course_start_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        onChange={handleCourseOpenPeriod}
        // disabled={toDisabled}
      />
      <Field
        name="challengecourseclosedate"
        label={LocaleStrings.events_form_label_challenge_course_end_date}
        placeholder={LocaleStrings.events_form_ph_challenge_course_end_date}
        mandatory="true"
        component={renderDatePickerWithLabel}
        labelposition={LABEL_POSITION_TOP}
        onChange={handleCourseClosePeriod}
        // disabled={toDisabled}
      />
      <div className="form-group row">
        <label className="control-label-top col-12">
          {LocaleStrings.events_form_ph_challenge_course_open_period}:{" "}
          {challengeCoursePeriod}
        </label>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ storeAddEventFormData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEventStep2);
