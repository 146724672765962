import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import classnames from "classnames";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import {
  clearActiveEvents,
  clearInactiveEvents,
  fetchActiveEvents,
  fetchInactiveEvents,
  searchActiveEvents,
  searchInactiveEvents,
  openEventModal,
  currentStep,
  storeAddEventFormData,
} from "./actions";
import Header from "../../components/Headers/Header";
import { itemCount } from "../Common/constant";
import Pagination from "../Common/pagination";
import Active from "./components/events-active";
import Inactive from "./components/events-inactive";
import AddEvent from "./components/add-event";
import AddEmailOptions from "./components/eventmanage/email-options-add";
import LocaleStrings from "../../languages";

const Events = (props) => {
  var {
    session,
    deviceSize,
    ActiveEventList,
    InactiveEventList,
    AddEventModal,
    ManageEmailRelatedModal,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    props.clearActiveEvents();
    props.clearInactiveEvents();
    props.storeAddEventFormData({});
  }, []);

  const _toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setPageNumber(1);
      setSearch("");
    }
  };

  const openModal = () => {
    props.openEventModal({ showModal: true });
    props.currentStep({ step: 1, text: "Step 1" });
  };

  const handleSearch = (e) => {
    var search = e.target.value;
    let pageNumber = 1;

    if (search === "") {
      if (activeTab == 1) {
        props.fetchActiveEvents(session, pageNumber, (response) => {});
      } else {
        props.fetchInactiveEvents(session, pageNumber, (response) => {});
      }
    } else {
      if (activeTab == 1) {
        props.searchActiveEvents(session, search, pageNumber, (response) => {});
      } else {
        props.searchInactiveEvents(
          session,
          search,
          pageNumber,
          (response) => {}
        );
      }
    }

    setSearch(search);
    setPageNumber(pageNumber);
  };

  const additionCallback = () => {
    paginationCallback(pageNumber);
  };

  const paginationCallback = (pageNum) => {
    if (search === "") {
      if (activeTab == 1) {
        props.fetchActiveEvents(session, pageNum, (response) => {});
      } else {
        props.fetchInactiveEvents(session, pageNum, (response) => {});
      }
    } else {
      if (activeTab == 1) {
        props.searchActiveEvents(session, search, pageNum, (response) => {});
      } else {
        props.searchInactiveEvents(session, search, pageNum, (response) => {});
      }
    }

    setPageNumber(pageNum);
  };

  var eventListCount =
    activeTab == 1
      ? ActiveEventList?.count ?? 0
      : InactiveEventList?.count ?? 0;

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--5" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="">
                <div className="pull-left">
                  <h3 className="mb-0">{LocaleStrings.events_list_header}</h3>
                </div>
                <div className="pull-right">
                  <ul className="header-buttons">
                    <li className="buttons-option">
                      <input
                        className="form-control inputsearch buttons-option"
                        placeholder={LocaleStrings.search}
                        type="search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </li>
                    <li className="buttons-option">
                      <Button
                        className="buttons-option"
                        color="primary"
                        size="md"
                        onClick={openModal}
                      >
                        {LocaleStrings.button_add_event}
                      </Button>
                    </li>
                  </ul>
                </div>
              </CardHeader>

              <CardBody>
                <Nav tabs className="">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        _toggle("1");
                      }}
                    >
                      {LocaleStrings.events_tabs_active}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        _toggle("2");
                      }}
                    >
                      {LocaleStrings.events_tabs_inactive}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" style={{ height: deviceSize.height }}>
                    {activeTab == 1 ? (
                      <Active
                        pageNumber={pageNumber}
                        operationsCallback={paginationCallback}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="2" style={{ height: deviceSize.height }}>
                    {activeTab == 2 ? (
                      <Inactive
                        pageNumber={pageNumber}
                        operationsCallback={paginationCallback}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </CardBody>

              {eventListCount > itemCount ? (
                <CardFooter className="">
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemCount}
                    totalItemsCount={eventListCount}
                    pageRangeDisplayed={3}
                    onChange={paginationCallback}
                  />
                </CardFooter>
              ) : (
                ""
              )}
            </Card>
          </div>
        </Row>
      </Container>

      {AddEventModal?.showModal ? (
        <AddEvent finishOperationsCallback={additionCallback} />
      ) : (
        ""
      )}
      {ManageEmailRelatedModal?.showModal ? <AddEmailOptions /> : ""}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    deviceSize: state.deviceSize,
    ActiveEventList: state.ActiveEventList,
    InactiveEventList: state.InactiveEventList,
    AddEventModal: state.AddEventModal,
    ManageEmailRelatedModal: state.ManageEmailRelatedModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearActiveEvents,
      clearInactiveEvents,
      fetchActiveEvents,
      fetchInactiveEvents,
      searchActiveEvents,
      searchInactiveEvents,
      openEventModal,
      currentStep,
      storeAddEventFormData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Events);
