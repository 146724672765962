import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { CardFooter, Table, Button } from "reactstrap";

// constants
import { CUSTOM_ITEM_COUNT } from "../../../Common/constant";
import Loader from "../../../Common/loader";
import LocaleStrings from "../../../../languages";

// actions
import {
  fetchEventRegistrationRequest,
  clearEventRegistrationRequest,
  eventClientListModal,
  fetchEventAllClients,
} from "../../actions";

// components
import AssignRequestToEventClient from "./assign-request-event-client";
import RequestedUserEmailUpdate from "./requeted-user-email-update";
import RenderList from "./list-item";
import Pagination from "../../../Common/pagination";

const EventRegistrationRequest = (props) => {
  const {
    session,
    eventid,
    SingleEventRegistrationRequest,
    eventClientListModalStatus,
    requestedUserEmailUpdateModal,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    props.clearEventRegistrationRequest();
    props.fetchEventRegistrationRequest(session, eventid);
  }, []);

  const additionCallback = () => {
    paginationCallback(pageNumber);
    setSelectedUsers([]);
  };

  const paginationCallback = (pageNum) => {
    props.fetchEventRegistrationRequest(
      session,
      eventid,
      pageNum,
      (response) => {}
    );

    setPageNumber(pageNum);
  };

  // Selecting one one user
  const updateSelectedUser = (user) => {
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.id === user.id;
    });
    if (index >= 0) {
      var newSelectedUsers = _.filter(selectedUsers, function (selusers) {
        return selusers.id !== user.id;
      });

      setSelectedUsers(newSelectedUsers);
    } else {
      var newSelectUser = { ...user, checked: true };
      var newselectedUsers = [...selectedUsers, newSelectUser];

      setSelectedUsers(newselectedUsers);
    }
  };

  const openEventClientListModal = () => {
    props.eventClientListModal({ show: true, data: selectedUsers });

    var related = `eventclientlegs_by_eventclientidfk`;
    props.fetchEventAllClients(session, eventid, related);
  };

  const renderList = () => {
    let pageDataLength = SingleEventRegistrationRequest?.data
      ? SingleEventRegistrationRequest?.data?.length
      : 0;

    return _.map(SingleEventRegistrationRequest.data, (item, index) => {
      return (
        <RenderList
          key={`key_${index}`}
          printList={item}
          pageDataLength={pageDataLength}
          currentPage={pageNumber}
          pagination={paginationCallback}
          checkAndUncheckUsers={updateSelectedUser}
          selectedUsers={selectedUsers}
          source="event-registration-request"
        />
      );
    });
  };

  return (
    <>
      {SingleEventRegistrationRequest?.data ? (
        <>
          {SingleEventRegistrationRequest?.count > 0 ? (
            <>
              {selectedUsers?.length > 0 ? (
                <Button
                  className="float-right mb-3"
                  color="primary"
                  size="md"
                  onClick={openEventClientListModal}
                >
                  {LocaleStrings.button_assign_client}
                </Button>
              ) : (
                ""
              )}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_id}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_eventid}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_fname}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_lname}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_email}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_school}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_entrants}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_region}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_mobile}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_year}
                    </th>
                    <th scope="col">
                      {LocaleStrings.events_regi_request_th_status}
                    </th>
                  </tr>
                </thead>
                <tbody>{renderList()}</tbody>
              </Table>
            </>
          ) : (
            <div className="no-content-message">
              <div>
                <div className="no-content-text">
                  {LocaleStrings.events_regi_request_text_no_data}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}

      {SingleEventRegistrationRequest?.count > CUSTOM_ITEM_COUNT ? (
        <CardFooter className="">
          <Pagination
            activePage={pageNumber}
            itemsCountPerPage={CUSTOM_ITEM_COUNT}
            totalItemsCount={SingleEventRegistrationRequest?.count}
            pageRangeDisplayed={3}
            onChange={paginationCallback}
          />
        </CardFooter>
      ) : (
        ""
      )}

      {eventClientListModalStatus?.show ? (
        <AssignRequestToEventClient
          finishOperationsCallback={additionCallback}
        />
      ) : (
        ""
      )}
      {requestedUserEmailUpdateModal?.show ? (
        <RequestedUserEmailUpdate finishOperationsCallback={additionCallback} />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    SingleEventRegistrationRequest: state.SingleEventRegistrationRequest,
    eventClientListModalStatus: state.eventClientListModalStatus,
    requestedUserEmailUpdateModal: state.requestedUserEmailUpdateModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchEventRegistrationRequest,
      clearEventRegistrationRequest,
      eventClientListModal,
      fetchEventAllClients,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventRegistrationRequest);
