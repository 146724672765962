import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter,
  // BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
// import "assets/scss/argon-dashboard-react.scss";
import "assets/css/structure.scss";
import "react-datepicker/dist/react-datepicker.css";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/auth/login" />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);
