import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Modal, Button, Row } from "reactstrap";
import { openViewTeamModal } from "../../actions";
import { convertDateTimeIntoLocal } from "../../../Common/constant";
import LocaleStrings from "../../../../languages";

const ViewEventTeams = (props) => {
  var { ViewTeamModal } = props;

  const closeModal = () => {
    props.openViewTeamModal({ showModal: false });
  };

  let teamSize = _.filter(
    ViewTeamModal?.teamdata?.teammembers_by_teamidfk,
    (obj) => {
      return obj?.isremoved == false && !obj?.withdrawndate;
    }
  );

  return (
    <>
      <Modal className="" isOpen={ViewTeamModal?.showModal ? true : false}>
        <div className="modal-header">
          <h3 className="modal-title" id="modal-title-default">
            {LocaleStrings.events_team_view_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="modal-body text-sm">
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_id}
            </div>
            <div className="col-7">: {ViewTeamModal?.teamdata?.id}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_client}
            </div>
            <div className="col-7">
              :{" "}
              {
                ViewTeamModal?.teamdata?.eventclient_by_eventclientidfk
                  ?.clients_by_clientidfk?.name
              }
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_team}
            </div>
            <div className="col-7">: {ViewTeamModal?.teamdata?.teamname}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_size}
            </div>
            <div className="col-7">: {teamSize?.length}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_steps}
            </div>
            <div className="col-7">: {ViewTeamModal?.teamdata?.teamsteps}</div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_confirmed}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamModal?.teamdata?.confirmeddate
                ? convertDateTimeIntoLocal(
                    ViewTeamModal?.teamdata?.confirmeddate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_withdrawn}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamModal?.teamdata?.withdrawndate
                ? convertDateTimeIntoLocal(
                    ViewTeamModal?.teamdata?.withdrawndate
                  )
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_created}
            </div>
            <div className="col-7">
              :{" "}
              {ViewTeamModal?.teamdata?.createdon
                ? convertDateTimeIntoLocal(ViewTeamModal?.teamdata?.createdon)
                : ""}
            </div>
          </Row>
          <Row className="mb-3">
            <div className="col-4">
              {LocaleStrings.events_team_view_text_finished}
            </div>
            <div className="col-7">
              : {ViewTeamModal?.teamdata?.finishedpoint}
            </div>
          </Row>
        </div>

        <div className="modal-footer">
          <Button color="secondary" onClick={closeModal}>
            {LocaleStrings.button_close}
          </Button>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    ViewTeamModal: state.ViewTeamModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openViewTeamModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewEventTeams);
