import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { BASE_URL, itemCount } from "../../Common/constant";
import AdminNavbar from "components/Navbars/AdminNavbar";

export const CLIENT_LIST = "CLIENT_LIST";
export const ALL_ACTIVE_CLIENT_LIST = "ALL_ACTIVE_CLIENT_LIST";
export const SCHOOL_ALL_ACTIVE_CLIENT_LIST = "SCHOOL_ALL_ACTIVE_CLIENT_LIST";
export const COMPANY_ALL_ACTIVE_CLIENT_LIST = "COMPANY_ALL_ACTIVE_CLIENT_LIST";
export const ADD_CLIENT_MODAL = "ADD_CLIENT_MODAL";
export const EDIT_CLIENT = "EDIT_CLIENT";

export function fetchClients(
  session,
  pageCount = 0,
  isactive,
  type = "",
  callback
) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var filter = `(isdeleted=0)AND(isactive=${isactive})`;
  if (type) {
    filter +=
      type === "school"
        ? `AND(referenceid IS NOT null)AND(type=${type})`
        : `AND(type=${type})`;
  }
  filter = encodeURI(filter);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients?related=client_info_by_referenceid&filter=(${filter})&include_count=true&order=name%20ASC&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function emptyClient() {
  return (dispatch) => {
    dispatch({
      type: CLIENT_LIST,
      payload: {},
    });
  };
}

export function searchClients(
  session,
  search,
  pageCount = 0,
  isactive,
  type = "",
  callback
) {
  var perPage = itemCount;
  var offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  var offSetQuery = `limit=${perPage}&offset=${offset}`;
  var searchQuery = `(name like %${search}%)OR(type like %${search}%)OR(adminname like %${search}%)`;
  var filter1 = `(isdeleted=0)AND(isactive=${isactive})AND(${searchQuery})`;
  if (type) {
    filter1 +=
      type === "school"
        ? `AND(referenceid IS NOT null)AND(type=${type})`
        : `AND(type=${type})`;
  }

  var filter = encodeURI(`${filter1}`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients?related=client_info_by_referenceid&filter=(${filter})&include_count=true&order=name%20ASC&&${offSetQuery}`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function searchClientInfo(session, search, type = "", callback) {
  var searchQuery = `(orgname like %${search}%)OR(phone like %${search}%)`;
  var filter1 = `(${searchQuery})`;
  if (type !== "") {
    filter1 += `AND(orgtype=${type})`;
  }
  var filter = encodeURI(`${filter1}`);

  var url = `${BASE_URL}api/v2/teamchallenge/_table/client_info?filter=(${filter})&include_count=true&order=orgname%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log("response : - ", response);
        // dispatch({
        //   type: CLIENT_LIST,
        //   payload: { data: response.resource, count: response.meta.count },
        // });
        callback({
          success: 1,
          data: response.resource,
          count: response.meta.count,
        });
      },
      (error) => {
        callback({ success: 0, data: error });
      }
    );
  };
}

export function activateDeactivateClient(session, values, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients`;
  var body = { resource: { ...values } };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        callback({ success: 1 });
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export function fetchAllActiveClients(session, callback) {
  var filter = encodeURI(`(isactive=1)AND(isdeleted=0)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients?fields=clientid,name,type&filter=(${filter})&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: ALL_ACTIVE_CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function clearAllActiveClients() {
  return (dispatch) => {
    dispatch({
      type: ALL_ACTIVE_CLIENT_LIST,
      payload: {},
    });
  };
}

export function fetchAllActiveSchoolClients(session, callback) {
  var filter = encodeURI(
    `(type='school')AND(isactive=1)AND(isdeleted=0)AND(referenceid IS NOT null)`
  );
  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients?fields=clientid,name,type&filter=(${filter})&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: SCHOOL_ALL_ACTIVE_CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function clearAllActiveSchoolClients() {
  return (dispatch) => {
    dispatch({
      type: SCHOOL_ALL_ACTIVE_CLIENT_LIST,
      payload: {},
    });
  };
}

export function fetchAllActiveCompanyClients(session, callback) {
  var filter = encodeURI(`(type='company')AND(isactive=1)AND(isdeleted=0)`);
  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients?fields=clientid,name,type&filter=(${filter})&include_count=true&order=name%20ASC`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (response) => {
        // console.log('response : - ', response)
        dispatch({
          type: COMPANY_ALL_ACTIVE_CLIENT_LIST,
          payload: { data: response.resource, count: response.meta.count },
        });
        callback({ success: 1 });
      },
      (error) => {
        // callback({success: 0, error: error});
      }
    );
  };
}

export function clearAllActiveCompanyClients() {
  return (dispatch) => {
    dispatch({
      type: COMPANY_ALL_ACTIVE_CLIENT_LIST,
      payload: {},
    });
  };
}

export function openClientModal(obj) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIENT_MODAL,
      payload: obj,
    });
  };
}

export function editClientData(item) {
  return (dispatch) => {
    dispatch({ type: EDIT_CLIENT, payload: item });
  };
}

export function addClient(session, values, callback) {
  var url = `${BASE_URL}/api/v2/teamchallenge/_table/clients`;

  var body = {
    resource: [{ ...values }],
  };

  return (dispatch) => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (
          response.resource &&
          response.resource.length > 0 &&
          response.resource[0].clientid
        ) {
          callback({ success: 1 });
        } else {
          callback({ success: 0 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function validateEmailForClient(session, email, callback) {
  var url = `${BASE_URL}/api/v2/teamchallenge/_table/clients?filter=(adminname=${email})`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        if (data.resource.length > 0) {
          callback({
            success: 2,
          });
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
}

export function updateClient(session, values, initialValues, callback) {
  var url = `${BASE_URL}api/v2/teamchallenge/_table/clients`;
  var body = { resource: [{ ...values }] };

  return (dispatch) => {
    patchRequest(
      url,
      body,
      session,
      dispatch,
      (response) => {
        if (
          values?.adminname !== initialValues?.adminname ||
          values?.password !== initialValues?.password
        ) {
          var urlPass = `${BASE_URL}api/v2/system/user/${values.dfid}`;
          var bodyPass = {
            ...(values?.adminname !== initialValues?.adminname
              ? { email: values?.adminname }
              : {}),
            ...(values?.password !== initialValues?.password
              ? { password: values.password }
              : {}),
          };

          patchRequest(
            urlPass,
            bodyPass,
            session,
            dispatch,
            (response) => {
              callback({ success: 1 });
            },
            (error) => {
              callback({ success: 0, message: error });
            }
          );
        } else {
          callback({ success: 1 });
        }
      },
      (error) => {
        callback({ success: 0 });
      }
    );
  };
}

export const refreshCleintInfo = (session, callback) => {
  let url = `${BASE_URL}api/v2/schoollist`;

  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (res) => {
        callback({ success: 1, data: res, error: null });
      },
      (error) => {
        callback({ success: 0, data: null, error });
      }
    );
  };
};
