import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { SketchPicker } from "react-color";

function ColorTheme(props) {
  var { selected } = props;
  const [selectedColor, setSelectedColor] = useState("");
  const [pickerVisible, setPickerVisible] = useState(false);

  useEffect(() => {
    if (selected?.color) setSelectedColor(selected?.color ?? "");
  }, [selected]);

  const openColorPicker = () => {
    setPickerVisible(!pickerVisible);
  };

  const handleColorChange = ({ hex }) => {
    setSelectedColor(hex);
    props.onColorChnage({ color: hex, isnew: true });
  };

  const reset = () => {
    setSelectedColor("");
    props.onColorChnage("");
  };

  // console.log("selectedColor :- ", selectedColor);

  return (
    <div>
      <div
        className="swatch-picker"
        style={{ backgroundColor: selectedColor }}
        onClick={openColorPicker}
      >
        <i className="fa fa-eyedropper"></i>
      </div>
      {pickerVisible && (
        <div className="picker-container">
          <SketchPicker
            color={selectedColor}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ColorTheme);
