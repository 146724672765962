import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { loadState, reset } from "./localStorage";
import { APP_API_KEY } from "../components/Common/constant";
import {
  LOGIN,
  LOGOUT,
  SESSION_TOKEN,
  DEVICE_SIZE,
  TIMEZONE_LIST,
  COACH_LIST,
  COACH_EMAIL_LIST,
  SYSTEM_EMAIL_LIST,
  MANAGE_EMAIL_RELATED_MODAL,
  DISTRIBUTOR_LIST,
  CERTIFICATES_LIST,
  LEG_LIST,
  CROPPER_IMAGE_MODAL,
} from "../actions";
import {
  ActiveEventList,
  InactiveEventList,
  AddEventModal,
  EventCurrentStep,
  EventFormData,
  SingleEventDetails,
  EventUrlModal,
  singleEventAllClients,
  SingleEventClients,
  SelectedClient,
  SingleEventRegistrationRequest,
  requestedUserEmailUpdateModal,
  eventClientListModalStatus,
  SingleEventTeams,
  AddEventClientModal,
  AddTeamModal,
  ViewTeamModal,
  SelectedTeam,
  teamClientEntrantCount,
  SelectedTeamMembers,
  ViewTeamMembers,
  AddTeamMembers,
  eventEmailTemplateList,
  editEventEmailTemplate,
} from "../components/events/reducers";
import {
  ClientList,
  AllActiveClientList,
  AllActiveSchoolClientList,
  AllActiveCompanyClientList,
  addClientModal,
  editClient,
} from "../components/clients/reducers";

import {
  settingsReducer,
  activityListReducer,
  faqList,
  bonusPointsList,
  editFaqDetails,
  editActivityDetails,
  emailTemplateList,
  editEmailTemplate,
  regionList,
  editRegionInfo,
  legmapHotezonesReducer,
} from "../components/settings/reducers";

var LoginReducer = (state = true, action) => {
  // need to change it to false
  state = loadState();

  if (state === undefined) {
    state = {
      isLoggedIn: false,
    };
  }

  if (action.type === LOGIN) {
    state.isLoggedIn = action.payload;
  }

  if (action.type === LOGOUT) {
    state.isLoggedIn = action.payload;
  }

  return state.isLoggedIn;
};

// stores Data for session also on localstorage
var TokensReducer = (state = {}, action) => {
  state = loadState();

  if (state === undefined || state.session === undefined) {
    state = {
      session: {
        apiKey: APP_API_KEY,
        sessionToken: "",
        emailid: "",
        userid: "",
        usertype: "",
      },
    };
  }

  if (action.type === SESSION_TOKEN) {
    if (action.payload.session_token) {
      state.session.sessionToken = action.payload.session_token;
    }
    state.session.emailid = action.payload.email;
    state.session.usertype = action.payload.type;
    state.session.userid = action.payload.userid;
  }

  if (action.type === LOGOUT) {
    state.session.sessionToken = "";
    state.session.emailid = "";
    state.session.userid = "";
    state.session.usertype = "";
    reset();
  }

  return state.session;
};

var deviceSize = (state = {}, action) => {
  if (action.type === DEVICE_SIZE) {
    return action.payload;
  }
  return state;
};

var timezoneList = (state = {}, action) => {
  if (action.type === TIMEZONE_LIST) {
    return action.payload;
  }
  return state;
};

var coachList = (state = {}, action) => {
  if (action.type === COACH_LIST) {
    return action.payload;
  }
  return state;
};

var coachEmails = (state = {}, action) => {
  if (action.type === COACH_EMAIL_LIST) {
    return action.payload;
  }
  return state;
};

var systemEmails = (state = {}, action) => {
  if (action.type === SYSTEM_EMAIL_LIST) {
    return action.payload;
  }
  return state;
};

var ManageEmailRelatedModal = (state = {}, action) => {
  if (action.type === MANAGE_EMAIL_RELATED_MODAL) {
    return action.payload;
  }
  return state;
};

var distributorsList = (state = {}, action) => {
  if (action.type === DISTRIBUTOR_LIST) {
    return action.payload;
  }
  return state;
};

var certificatesList = (state = {}, action) => {
  if (action.type === CERTIFICATES_LIST) {
    return action.payload;
  }
  return state;
};

var legsList = (state = {}, action) => {
  if (action.type === LEG_LIST) {
    return action.payload;
  }
  return state;
};

var cropperModal = (state = {}, action) => {
  if (action.type === CROPPER_IMAGE_MODAL) {
    return action.payload;
  }
  return state;
};

const rootReducer = combineReducers({
  form: formReducer,
  isLoggedIn: LoginReducer,
  session: TokensReducer,
  deviceSize,
  timezoneList,
  coachList,
  coachEmails,
  systemEmails,
  ManageEmailRelatedModal,
  distributorsList,
  certificatesList,
  legsList,
  cropperModal,

  // Events
  ActiveEventList,
  InactiveEventList,
  AddEventModal,
  EventCurrentStep,
  EventFormData,
  SingleEventDetails,
  EventUrlModal,
  singleEventAllClients,
  SingleEventClients,
  SingleEventRegistrationRequest,
  requestedUserEmailUpdateModal,
  eventClientListModalStatus,
  SingleEventTeams,
  AddEventClientModal,
  AddTeamModal,
  ViewTeamModal,
  SelectedTeam,
  teamClientEntrantCount,
  SelectedTeamMembers,
  ViewTeamMembers,
  AddTeamMembers,
  eventEmailTemplateList,
  editEventEmailTemplate,

  // Clients
  ClientList,
  AllActiveClientList,
  AllActiveSchoolClientList,
  AllActiveCompanyClientList,
  addClientModal,
  editClient,
  SelectedClient,

  // Settings
  settingsReducer: settingsReducer,
  activityListReducer: activityListReducer,
  faqList: faqList,
  bonusPointsList: bonusPointsList,
  editFaqDetails: editFaqDetails,
  editActivityDetails,
  emailTemplateList,
  editEmailTemplate,
  regionList,
  editRegionInfo,
  legmapHotezonesReducer,
});

export default rootReducer;
