import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  deleteEventClient,
  openAddTeamModal,
  openViewTeamModal,
  clearSelectedTeam,
  updateTeam,
  withdrawAllTeamMember,
  deleteTeamMemberSession,
  selectedTeam,
  requestedUserEmailModal,
} from "../../actions";
import {
  COMMON_FAIL_MESSAGE,
  convertDateTimeIntoLocal,
  LOCAL_TO_GMT_TIME,
} from "../../../Common/constant";
import { showSuccess, showError } from "../../../Common/alertmessage";
import LocaleStrings from "../../../../languages";

const TableList = (props) => {
  var {
    printList,
    source = "",
    currentPage,
    pageDataLength,
    selectedUsers,
    session,
  } = props;

  const handleOptions = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClientRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let pageNo = currentPage;
    if (pageDataLength == 1) {
      pageNo = pageNo - 1;
    }

    let values = {
      id: printList.id,
      isdeleted: 1,
    };

    confirmAlert({
      title: LocaleStrings.button_remove,
      message: LocaleStrings.events_client_remove_subtitle,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            props.deleteEventClient(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                props.pagination(pageNo);
                showSuccess(LocaleStrings.events_client_alert_delete_success);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  };

  const openAddTeam = () => {
    props.openAddTeamModal({ showModal: true, value: printList });
  };

  const openViewTeam = () => {
    props.openViewTeamModal({ showModal: true, teamdata: printList });
  };

  const handleWithdraw = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let values = {
      id: printList.id,
      withdrawndate: LOCAL_TO_GMT_TIME,
    };

    // for delete df users session
    var activeMembers = _.filter(printList?.teammembers_by_teamidfk, (obj) => {
      return !obj?.withdrawndate;
    });
    var dfids = _.map(activeMembers, "dfid");

    confirmAlert({
      title: LocaleStrings.events_team_withdraw_confirm_title,
      message: `${LocaleStrings.events_team_withdraw_confirm_subtitle} ${printList.teamname}?`,
      buttons: [
        {
          label: LocaleStrings.button_cancel,
          onClick: () => {},
        },
        {
          label: LocaleStrings.button_withdraw,
          onClick: () => {
            props.updateTeam(session, values, (response) => {
              if (response.success === 0) {
                showError(COMMON_FAIL_MESSAGE);
              } else {
                props.withdrawAllTeamMember(
                  session,
                  printList.id,
                  LOCAL_TO_GMT_TIME,
                  (res) => {
                    props.pagination(currentPage);
                    if (dfids?.length > 0) {
                      props.deleteTeamMemberSession(session, dfids.join(","));
                    }
                    showSuccess(
                      LocaleStrings.events_team_withdraw_alert_success
                    );
                  }
                );
              }
            });
          },
        },
      ],
      childrenElement: () => (
        <div className="mt-5 mb-5 font-weight-bold">
          {LocaleStrings.events_team_withdraw_confirm_warning}
        </div>
      ),
    });
  };

  const handleTeamManage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    props.clearSelectedTeam({}); // To clear the team first, if redux have any
    props.selectedTeam(printList);

    setTimeout(() => {
      props.history.push({
        pathname: `/admin/event-team/${printList.eventidfk}/${printList.id}`,
      });
    }, 500);
  };

  const selectionCallback = (user) => {
    // console.log("user :- ", user);

    props.checkAndUncheckUsers(user);
  };

  const editEmail = (user) => {
    // console.log("user :- ", user);

    props.requestedUserEmailModal({ show: true, data: user });
  };

  let teamSize = _.filter(printList?.teammembers_by_teamidfk, (obj) => {
    return obj?.isremoved == false && !obj?.withdrawndate;
  });

  var index = _.findIndex(selectedUsers, function (curuser) {
    return curuser.id === printList.id;
  });

  var checked = false;
  if (index >= 0) {
    checked = true;
  }
  return (
    <>
      {source === "event-client" ? (
        <tr>
          <td>{printList.clientidfk}</td>
          <td>{printList?.clients_by_clientidfk?.name}</td>
          <td>{printList.totalmembers}</td>
          <td>{printList.maxteammember}</td>
          <td>{printList.confirmedmembers}</td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => handleOptions(e)}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={(e) => handleClientRemove(e)}>
                  {LocaleStrings.button_remove}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ) : source === "event-registration-request" ? (
        <tr>
          <td>
            {printList.status == 1 ? (
              <label
                className="custom-container-checkbox"
                style={{ marginTop: -9 }}
              >
                <input
                  type="checkbox"
                  onChange={() => selectionCallback(printList)}
                  checked={checked}
                />
                <span className="checkmark" />
              </label>
            ) : (
              "-"
            )}
          </td>
          <td>{printList.id}</td>
          <td>{printList.eventidfk}</td>
          <td>{printList.firstname}</td>
          <td>{printList.lastname}</td>
          <td>
            {printList.email} &ensp;
            {printList.status > 2 ? (
              ""
            ) : (
              <i
                className="fa fa-pencil-square-o cursor-pointer"
                aria-hidden="true"
                onClick={() => editEmail(printList)}
              ></i>
            )}
          </td>
          <td>{printList?.client_info_by_schoolid?.orgname ?? "-"}</td>
          <td>{printList.noofmembers}</td>
          <td>{printList.region}</td>
          <td>{printList.mobile}</td>
          <td>{printList.year}</td>
          <td>{printList.status}</td>
        </tr>
      ) : source === "event-team" ? (
        <tr className={`${printList?.withdrawndate ? "deactivated" : ""}`}>
          <td>{printList.id}</td>
          <td>
            {
              printList?.eventclient_by_eventclientidfk?.clients_by_clientidfk
                ?.name
            }
          </td>
          <td>{printList.teamname}</td>
          <td>{teamSize?.length}</td>
          <td>{printList.teampoints}</td>
          <td>
            {printList.confirmeddate
              ? convertDateTimeIntoLocal(printList.confirmeddate)
              : ""}
          </td>
          <td>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => handleOptions(e)}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={(e) => openViewTeam(e)}>
                  {LocaleStrings.button_view}
                </DropdownItem>
                {printList?.withdrawndate ? (
                  ""
                ) : (
                  <DropdownItem onClick={(e) => openAddTeam(e)}>
                    {LocaleStrings.button_edit}
                  </DropdownItem>
                )}
                {printList?.withdrawndate ? (
                  ""
                ) : (
                  <DropdownItem onClick={(e) => handleWithdraw(e)}>
                    {LocaleStrings.button_withdraw}
                  </DropdownItem>
                )}
                <DropdownItem onClick={(e) => handleTeamManage(e)}>
                  {LocaleStrings.button_team_manage}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteEventClient,
      openAddTeamModal,
      openViewTeamModal,
      clearSelectedTeam,
      updateTeam,
      withdrawAllTeamMember,
      deleteTeamMemberSession,
      selectedTeam,
      requestedUserEmailModal,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableList)
);
