import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Button } from "reactstrap";

import { LABEL_POSITION_TOP } from "../../Common/constant";
import { renderFieldText, validateEmail } from "../../Common/base-components";
import LocaleStrings from "../../../languages";

import { fetchSettings, updateSettings } from "../actions";

const Settings = (props) => {
  let {
    session,
    handleSubmit,
    fetchSettings,
    settingsReducer,
    updateSettings,
    pristine,
    submitting,
    invalid,
  } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSettings(session);
  }, []);

  const onSubmitForm = (values) => {
    setLoading(true);
    //console.log(values);

    let resourceObj = {
      defaultlegopendivision: values.defaultlegopendivision,
      defaultlegadvanceddivision: values.defaultlegadvanceddivision,
      //emailsmtp: values.smtp,
      //emailfrom: values.from,
    };

    updateSettings(session, settingsReducer[0].id, resourceObj, (res) => {
      if (res.success) {
        // console.log("res: ", res);
        fetchSettings(session, (res1) => {
          setLoading(false);
          //   console.log('res1: ', res1);
        });
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Row>
          <Col sm="12">
            <div className="p-3"> {LocaleStrings.default_leg} </div>
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col sm="6">
            {/* <Field
              name="defaultstridelength"
              label={LocaleStrings.setting_form_label_default_stride_length}
              placeholder={LocaleStrings.setting_form_ph_default_stride_length}
              type="number"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="maxstepsopendiv"
              label={LocaleStrings.setting_form_label_max_steps_open_div}
              placeholder={LocaleStrings.setting_form_ph_max_steps_open_div}
              type="number"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="maxstepsadvdiv"
              label={LocaleStrings.setting_form_label_max_steps_adv_div}
              placeholder={LocaleStrings.setting_form_ph_max_steps_adv_div}
              type="number"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            /> */}

            <Field
              name="defaultlegopendivision"
              label={LocaleStrings.setting_form_label_default_leg_open_division}
              placeholder={
                LocaleStrings.setting_form_label_default_leg_open_division
              }
              type="number"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="defaultlegadvanceddivision"
              label={
                LocaleStrings.setting_form_label_default_leg_advanced_division
              }
              placeholder={
                LocaleStrings.setting_form_label_default_leg_advanced_division
              }
              type="number"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
          </Col>
        </Row>

        {/*<hr className="m-3" />

         <Row>
          <Col sm="12">
            <div className="p-3"> {LocaleStrings.email_settings} </div>
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col sm="6">
            <Field
              name="smtp"
              label={LocaleStrings.setting_form_label_smtp}
              placeholder={LocaleStrings.setting_form_ph_smtp}
              type="text"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
            <Field
              name="from"
              label={LocaleStrings.setting_form_label_from}
              placeholder={LocaleStrings.setting_form_ph_from}
              type="email"
              component={renderFieldText}
              labelposition={LABEL_POSITION_TOP}
            />
          </Col>
        </Row> */}

        <Row className="justify-content-around my-3">
          <Col sm="6">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

const validate = (values) => {
  var errors = {};
  var defaultstridelength = values["defaultstridelength"];
  var maxstepsopendiv = values["maxstepsopendiv"];
  var maxstepsadvdiv = values["maxstepsadvdiv"];
  var smtp = values["smtp"];
  var from = values["from"];

  if (!defaultstridelength || defaultstridelength === "") {
    errors["defaultstridelength"] = LocaleStrings.requird;
  }

  if (!maxstepsopendiv || maxstepsopendiv === "") {
    errors["maxstepsopendiv"] = LocaleStrings.requird;
  }

  if (!maxstepsadvdiv || maxstepsadvdiv === "") {
    errors["maxstepsadvdiv"] = LocaleStrings.requird;
  }

  if (!smtp || smtp.trim() === "") {
    errors["smtp"] = LocaleStrings.requird;
  }

  if (!from || from.trim() === "") {
    errors["from"] = LocaleStrings.requird;
  } else if (from && !validateEmail(from)) {
    errors["email"] = LocaleStrings.invalid_email;
  }

  return errors;
};

const mapStateToProps = (state) => {
  // console.log("settingsReducer: ", state.settingsReducer);

  var initial = {};
  if (state.settingsReducer && state.settingsReducer.length > 0) {
    initial = {
      defaultlegopendivision: state.settingsReducer[0].defaultlegopendivision,
      defaultlegadvanceddivision:
        state.settingsReducer[0].defaultlegadvanceddivision,
      smtp: state.settingsReducer[0].emailsmtp,
      from: state.settingsReducer[0].emailfrom,
    };
  }

  return {
    session: state.session,
    settingsReducer: state.settingsReducer,
    initialValues: initial,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchSettings, updateSettings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "SettingForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Settings)
);
