import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { Modal, Button } from "reactstrap";
import {
  openEventModal,
  currentStep,
  storeAddEventFormData,
  checkSubdomain,
  createEvent,
  uploadImagesWithFormData,
  updateEvent,
  deleteMultipleEventLegs,
  deleteAllEventLegsGallery,
  updateEventClientLegFromEvent,
} from "../actions";
import {
  openEmailRelatedModal,
  fetchCoachs,
  fetchCoachEmails,
  fetchSystemEmails,
  fetchDistributors,
  fetchCertificates,
  fetchLegs,
} from "../../../actions";
import { fetchSettings } from "../../settings/actions";
import {
  DB_DATE_FORMAT_WITH_TIME,
  COMMON_FAIL_MESSAGE,
  DEFAULT_BGCOLOR,
} from "../../Common/constant";
import { showSuccess, showError } from "../../Common/alertmessage";
import StepOne from "./add-event-step1";
import StepTwo from "./add-event-step2";
import StepThree from "./add-event-step3";
import StepFour from "./add-event-step4";
import LocaleStrings from "../../../languages";

const AddEvent = (props) => {
  var {
    session,
    toEdit,
    AddEventModal,
    EventCurrentStep,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    submit,
    dispatch,
  } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.fetchCoachs(session);
    props.fetchCoachEmails(session);
    props.fetchSystemEmails(session);
    props.fetchDistributors(session);
    props.fetchCertificates(session);
    props.fetchLegs(session);
    props.fetchSettings(session);
  }, []);

  const closeModal = () => {
    props.openEventModal({ showModal: false });
    props.storeAddEventFormData({});
  };

  const _handelNext = () => {
    props.currentStep({
      step: EventCurrentStep?.step + 1,
      text: `Step ${EventCurrentStep?.step + 1}`,
    });
  };

  const _handelPrevious = () => {
    props.currentStep({
      step: EventCurrentStep?.step - 1,
      text: `Step ${EventCurrentStep?.step - 1}`,
    });
  };

  return (
    <>
      <Modal
        className="event-modal"
        isOpen={AddEventModal?.showModal ? true : false}
      >
        <div className="modal-header">
          <span className="modal-title text-sm">{EventCurrentStep?.text}</span>
          <h3 className="modal-title" id="modal-title-default">
            {toEdit
              ? LocaleStrings.events_edit_title
              : LocaleStrings.events_add_title}
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            {EventCurrentStep?.step == 1 ? (
              <StepOne {...props} />
            ) : EventCurrentStep?.step == 2 ? (
              <StepTwo {...props} />
            ) : EventCurrentStep?.step == 3 ? (
              <StepThree {...props} />
            ) : EventCurrentStep?.step == 4 ? (
              <StepFour {...props} />
            ) : (
              ""
            )}
          </div>

          <div
            className={`modal-footer ${
              EventCurrentStep?.step > 1 ? "justify-content-between" : ""
            }`}
          >
            {EventCurrentStep?.step > 1 ? (
              <Button color="primary" type="button" onClick={_handelPrevious}>
                {LocaleStrings.button_previous}
              </Button>
            ) : (
              ""
            )}

            {EventCurrentStep?.step == 4 ? (
              <Button
                color="primary"
                type="button"
                onClick={() => dispatch(submit("EventForm"))}
                disabled={pristine || invalid || submitting || loading}
              >
                {LocaleStrings.button_save}
              </Button>
            ) : (
              <Button
                color="primary"
                type="button"
                onClick={_handelNext}
                disabled={invalid}
              >
                {LocaleStrings.button_next}
              </Button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

const onSubmitForm = (values, dispatch, props) => {
  var { session, settingsReducer, EventFormData, toEdit, toDisabled } = props;

  // console.log("values :- ", values);
  // console.log("dispatch :- ", dispatch);
  // console.log("props :- ", props);
  var data = {
    // ...values,
    name: values?.name ?? null,
    ...(toEdit ? { eventid: values?.eventid } : {}),
    ...(toEdit ? {} : { type: values?.type ?? "school" }),
    ...(toDisabled
      ? {
          // this is added as clint wants to change his dates, else it will be blank object is toDisable is true for all sections
          activestart: moment(values.siteopendate).format(
            DB_DATE_FORMAT_WITH_TIME
          ),
          activeend: moment(values.siteclosedate).format(
            DB_DATE_FORMAT_WITH_TIME
          ),
        }
      : {
          activestart: moment(values.siteopendate).format(
            DB_DATE_FORMAT_WITH_TIME
          ),
          activeend: moment(values.siteclosedate).format(
            DB_DATE_FORMAT_WITH_TIME
          ),
        }),

    ...(toEdit ? {} : { subdomain: values?.subdomain ?? null }),
    app: values?.app ?? null,
    distributoridfk: values?.distributoridfk ?? null,
    ...(values?.coachidfk ? { coachidfk: values.coachidfk } : {}),
    ...(values?.coachemailidfk
      ? { coachemailidfk: values.coachemailidfk }
      : {}),
    ...(values?.systememailidfk
      ? { systememailidfk: values.systememailidfk }
      : {}),
    targetentrants: values?.targetentrants ?? null,
    nooflegs: values?.nooflegs ?? null,
    noofbonuslegs: values?.noofbonuslegs ?? null,
    teamavgdailypointstargetopen: values?.teamavgdailypointstargetopen ?? null,
    dailymaxindividualopen: values?.dailymaxindividualopen ?? null,

    // ...(values?.type === "company" && values?.isadvdivisions
    ...(values?.isadvdivisions
      ? {
          isadvdivisions: values.isadvdivisions,
          teamavgdailypointstargetadv:
            values?.teamavgdailypointstargetadv ?? null,
          dailymaxindividualadv: values?.dailymaxindividualadv ?? null,
        }
      : {}),

    bgtype: values.bgtype,
    ...(values?.bgtype === "color"
      ? { bgvalue: values?.bgvalue?.color ?? DEFAULT_BGCOLOR }
      : {}),

    teamcertificateidfk: values?.teamcertificateidfk ?? null,
    individualcertificateidfk: values?.individualcertificateidfk ?? null,
    isactive: values?.isactive,

    // This todisable condition is disabled as clint wants to edit his dates after event starts too.
    // ...(toDisabled
    //   ? {}
    //   : {
    eventsettings_by_eventidfk: [
      {
        ...(toEdit
          ? {
              eventsettingid:
                values?.eventsettings_by_eventidfk?.[0]?.eventsettingid,
              eventidfk: values?.eventid,
            }
          : {}),
        siteopendate: moment(values.siteopendate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        siteclosedate: moment(values.siteclosedate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        regopendate: moment(values.regopendate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        regclosedate: moment(values.regclosedate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        dataentryclosedate: moment(values.dataentryclosedate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        invitationexpires: values.invitationexpires,
        autoreminderinterval: values.autoreminderinterval,
        withdrawninterval: values.withdrawninterval,
        indregcutoffdate: moment(values.indregcutoffdate).format(
          DB_DATE_FORMAT_WITH_TIME
        ),
        challengecoursestartdate: moment(
          values.challengecoursestartdate
        ).format(DB_DATE_FORMAT_WITH_TIME),
        challengecourseclosedate: moment(
          values.challengecourseclosedate
        ).format(DB_DATE_FORMAT_WITH_TIME),
      },
    ],
    // }),
  };

  var challengeCoursePeriod =
    values?.challengecourseclosedate && values?.challengecoursestartdate
      ? moment(values.challengecourseclosedate).diff(
          moment(values.challengecoursestartdate),
          "days"
        )
      : 0;
  // console.log("challengeCoursePeriod :- ", challengeCoursePeriod);

  // Open section calculation
  var metersreqtofinishalllegsopen =
    settingsReducer?.[0]?.defaultlegopendivision && values?.nooflegs
      ? settingsReducer[0].defaultlegopendivision * values.nooflegs
      : 0;
  var travelratioopen =
    values?.teamavgdailypointstargetopen &&
    values?.teamavgdailypointstargetopen != 0 &&
    metersreqtofinishalllegsopen != 0 &&
    challengeCoursePeriod != 0
      ? (
          metersreqtofinishalllegsopen /
          challengeCoursePeriod /
          values.teamavgdailypointstargetopen
        ).toFixed(6)
      : (0).toFixed(6);
  var teamavgptreqtofinisheachlegopen =
    settingsReducer?.[0]?.defaultlegopendivision && travelratioopen != 0
      ? (settingsReducer[0].defaultlegopendivision / travelratioopen).toFixed(6)
      : (0).toFixed(6);
  var teamavgpttofinishalllegsopen = values?.nooflegs
    ? (teamavgptreqtofinisheachlegopen * values.nooflegs).toFixed(6)
    : (0).toFixed(6);

  data.metersreqtofinishalllegsopen = metersreqtofinishalllegsopen;
  data.teamavgptreqtofinisheachlegopen = teamavgptreqtofinisheachlegopen;
  data.teamavgpttofinishalllegsopen = teamavgpttofinishalllegsopen;
  data.travelratioopen = travelratioopen;

  // Advanced section calculation
  // if (values?.type === "company" && values?.isadvdivisions) {
  if (values?.isadvdivisions) {
    var metersreqtofinishalllegsadv =
      settingsReducer?.[0]?.defaultlegadvanceddivision && values?.nooflegs
        ? settingsReducer[0].defaultlegadvanceddivision * values.nooflegs
        : 0;
    var travelratioadv =
      values?.teamavgdailypointstargetadv &&
      values?.teamavgdailypointstargetadv != 0 &&
      metersreqtofinishalllegsadv != 0 &&
      challengeCoursePeriod != 0
        ? (
            metersreqtofinishalllegsadv /
            challengeCoursePeriod /
            values.teamavgdailypointstargetadv
          ).toFixed(6)
        : (0).toFixed(6);
    var teamavgptreqtofinisheachlegadv =
      settingsReducer?.[0]?.defaultlegadvanceddivision && travelratioadv != 0
        ? (
            settingsReducer[0].defaultlegadvanceddivision / travelratioadv
          ).toFixed(6)
        : (0).toFixed(6);
    var teamavgpttofinishalllegsadv = values?.nooflegs
      ? (teamavgptreqtofinisheachlegadv * values.nooflegs).toFixed(6)
      : (0).toFixed(6);

    // Advance
    data.metersreqtofinishalllegsadv = metersreqtofinishalllegsadv;
    data.teamavgptreqtofinisheachlegadv = teamavgptreqtofinisheachlegadv;
    data.teamavgpttofinishalllegsadv = teamavgpttofinishalllegsadv;
    data.travelratioadv = travelratioadv;
  }

  var imagesArr = [];
  if (
    values?.bgtype === "image" &&
    !_.isEmpty(values?.bgvalue) &&
    typeof values?.bgvalue === "object"
  ) {
    imagesArr.push({ key: "bgvalue", ...values?.bgvalue });
  }
  if (!_.isEmpty(values?.logo) && typeof values?.logo === "object") {
    imagesArr.push({ key: "logo", ...values?.logo });
  }
  if (
    !_.isEmpty(values?.loginbgimage) &&
    typeof values?.loginbgimage === "object"
  ) {
    imagesArr.push({ key: "loginbgimage", ...values?.loginbgimage });
  }
  if (
    !_.isEmpty(values?.loginbgimage1) &&
    typeof values?.loginbgimage1 === "object"
  ) {
    imagesArr.push({ key: "loginbgimage1", ...values?.loginbgimage1 });
  }
  if (
    !_.isEmpty(values?.loginbgimage2) &&
    typeof values?.loginbgimage2 === "object"
  ) {
    imagesArr.push({ key: "loginbgimage2", ...values?.loginbgimage2 });
  }
  if (
    !_.isEmpty(values?.loginbgimage3) &&
    typeof values?.loginbgimage3 === "object"
  ) {
    imagesArr.push({ key: "loginbgimage3", ...values?.loginbgimage3 });
  }
  if (
    !_.isEmpty(values?.loginbgimage4) &&
    typeof values?.loginbgimage4 === "object"
  ) {
    imagesArr.push({ key: "loginbgimage4", ...values?.loginbgimage4 });
  }
  // console.log("imagesArr :- ", imagesArr);
  // console.log("EventFormData :- ", EventFormData);

  // event legs data //
  var eventlegData = [];
  _.map(EventFormData?.legsArr, (item, i) => {
    var legGallery = [];
    if (values?.leg_locationtitle?.[i] && values?.leg_materlegidfk?.[i]) {
      var gallery1 =
        values?.leggallery_leggalleryidfk?.[i]?.[0] ||
        values?.leggallery_title?.[i]?.[0]
          ? {
              ...(toEdit ? { eventidfk: values?.eventid } : {}),
              masterlegidfk: values?.leg_materlegidfk?.[i] ?? null,
              leggalleryidfk:
                values?.leggallery_leggalleryidfk?.[i]?.[0] ?? null,
              title: values?.leggallery_title?.[i]?.[0] ?? null,
              ismain: 1,
            }
          : {};
      var gallery2 =
        values?.leggallery_leggalleryidfk?.[i]?.[1] ||
        values?.leggallery_title?.[i]?.[1]
          ? {
              ...(toEdit ? { eventidfk: values?.eventid } : {}),
              masterlegidfk: values?.leg_materlegidfk?.[i] ?? null,
              leggalleryidfk:
                values?.leggallery_leggalleryidfk?.[i]?.[1] ?? null,
              title: values?.leggallery_title?.[i]?.[1] ?? null,
              ismain: 0,
            }
          : {};
      var gallery3 =
        values?.leggallery_leggalleryidfk?.[i]?.[2] ||
        values?.leggallery_title?.[i]?.[2]
          ? {
              ...(toEdit ? { eventidfk: values?.eventid } : {}),
              masterlegidfk: values?.leg_materlegidfk?.[i] ?? null,
              leggalleryidfk:
                values?.leggallery_leggalleryidfk?.[i]?.[2] ?? null,
              title: values?.leggallery_title?.[i]?.[2] ?? null,
              ismain: 0,
            }
          : {};
      var gallery4 =
        values?.leggallery_leggalleryidfk?.[i]?.[3] ||
        values?.leggallery_title?.[i]?.[3]
          ? {
              ...(toEdit ? { eventidfk: values?.eventid } : {}),
              masterlegidfk: values?.leg_materlegidfk?.[i] ?? null,
              leggalleryidfk:
                values?.leggallery_leggalleryidfk?.[i]?.[3] ?? null,
              title: values?.leggallery_title?.[i]?.[3] ?? null,
              ismain: 0,
            }
          : {};
      if (!_.isEmpty(gallery1)) {
        legGallery.push(gallery1);
      }
      if (!_.isEmpty(gallery2)) {
        legGallery.push(gallery2);
      }
      if (!_.isEmpty(gallery3)) {
        legGallery.push(gallery3);
      }
      if (!_.isEmpty(gallery4)) {
        legGallery.push(gallery4);
      }

      eventlegData.push({
        ...(toEdit && !item?.isnew ? { eventlegid: item.eventlegid } : {}),
        ...(toEdit ? { eventidfk: values?.eventid } : {}),
        locationtitle: values?.leg_locationtitle?.[i] ?? null,
        materlegidfk: values?.leg_materlegidfk?.[i] ?? null,
        animationidfk: values?.leg_animationidfk?.[i]
          ? values.leg_animationidfk[i]
          : null,
        detailsidfk: values?.leg_detailsidfk?.[i]
          ? values.leg_detailsidfk[i]
          : null,
        leggallery_by_eventlegidfk: legGallery,
      });
    }
  });
  data.eventleg_by_eventidfk = eventlegData;
  // ======================= //

  // console.log("data :- ", data);

  // setLoading(true);
  var promiseArray = [];
  var fileresponse = {};
  if (toEdit) {
    // Update old Event
    var eventlegids = _.map(EventFormData?.removedArr, "eventlegid");
    var updateEventClientLegData = _.filter(
      data?.eventleg_by_eventidfk,
      (obj) => {
        return obj?.eventlegid;
      }
    );
    // console.log("eventlegids :- ", eventlegids);
    // console.log("updateEventClientLegData :- ", updateEventClientLegData);
    _.map(imagesArr, (img, index) => {
      promiseArray.push(
        new Promise((resolve, reject) => {
          props.uploadImagesWithFormData(
            session,
            img,
            data?.eventid,
            (fileres) => {
              resolve();
              if (fileres?.success) {
                fileresponse[img.key] = fileres?.data?.path ?? null;
              }
            }
          );
        })
      );
    });
    if (eventlegids.length > 0) {
      promiseArray.push(
        new Promise((resolve, reject) => {
          props.deleteMultipleEventLegs(
            session,
            eventlegids.join(","),
            (res) => {
              // console.log("EventLeg Delete:- ", res);
              resolve();
            }
          );
        })
      );
    }
    promiseArray.push(
      new Promise((resolve, reject) => {
        props.deleteAllEventLegsGallery(session, data?.eventid, (res) => {
          // console.log("EventLeg gallery Delete:- ", res);
          resolve();
        });
      })
    );
    if (updateEventClientLegData.length > 0) {
      _.map(updateEventClientLegData, (item, index) => {
        promiseArray.push(
          new Promise((resolve, reject) => {
            props.updateEventClientLegFromEvent(session, item, (fileres) => {
              resolve();
            });
          })
        );
      });
    }
    Promise.all(promiseArray).then(() => {
      // console.log("upload...callback", fileresponse);
      // console.log("final upload...", { ...data, ...fileresponse });
      props.updateEvent(session, { ...data, ...fileresponse }, (res) => {
        if (res.success === 0) {
          showError(COMMON_FAIL_MESSAGE);
        } else {
          showSuccess(LocaleStrings.events_form_saved_success);
          props.finishOperationsCallback();
          props.openEventModal({ showModal: false });
          props.storeAddEventFormData({});
        }
      });
    });
  } else {
    // Add New Event
    props.checkSubdomain(session, values?.subdomain, (response) => {
      // console.log("response :- ", response);
      if (response.success == 0) {
        showError(COMMON_FAIL_MESSAGE);
      } else if (response.success == 2) {
        showError(
          `"${values?.subdomain}" ${LocaleStrings.event_form_error_subdomain_not_available}`
        );
      } else {
        props.createEvent(session, data, (res) => {
          // console.log("res", res);
          // setLoading(false);
          if (res.success === 0) {
            showError(COMMON_FAIL_MESSAGE);
          } else {
            if (imagesArr.length > 0) {
              _.map(imagesArr, (img, index) => {
                promiseArray.push(
                  new Promise((resolve, reject) => {
                    props.uploadImagesWithFormData(
                      session,
                      img,
                      res?.data?.[0]?.eventid,
                      (fileres) => {
                        resolve();
                        if (fileres?.success) {
                          fileresponse[img.key] = fileres?.data?.path ?? null;
                        }
                      }
                    );
                  })
                );
              });

              Promise.all(promiseArray).then(() => {
                // console.log("upload...callback", fileresponse);

                if (!_.isEmpty(fileresponse)) {
                  fileresponse.eventid = res?.data?.[0]?.eventid;
                  props.updateEvent(session, fileresponse, (updateCall) => {
                    showSuccess(LocaleStrings.events_form_saved_success);
                    props.finishOperationsCallback();
                    props.openEventModal({ showModal: false });
                    props.storeAddEventFormData({});
                  });
                }
              });
            } else {
              // console.log("without files");
              showSuccess(LocaleStrings.events_form_saved_success);
              props.finishOperationsCallback();
              props.openEventModal({ showModal: false });
              props.storeAddEventFormData({});
            }
          }
        });
      }
    });
  }
};

const validate = (values, ownProps) => {
  // console.log("values :- ", values);
  // console.log("ownProps :- ", ownProps);
  // console.log("==============================");
  var errors = {};
  var type = values["type"];
  var name = values["name"];
  var subdomain = values["subdomain"];
  var app = values["app"];
  var distributoridfk = values["distributoridfk"];
  var targetentrants = values["targetentrants"];

  var siteopendate = values["siteopendate"];
  var siteclosedate = values["siteclosedate"];
  var regopendate = values["regopendate"];
  var regclosedate = values["regclosedate"];
  var dataentryclosedate = values["dataentryclosedate"];
  var invitationexpires = values["invitationexpires"];
  var autoreminderinterval = values["autoreminderinterval"];
  var withdrawninterval = values["withdrawninterval"];
  var indregcutoffdate = values["indregcutoffdate"];
  var challengecoursestartdate = values["challengecoursestartdate"];
  var challengecourseclosedate = values["challengecourseclosedate"];

  var nooflegs = values["nooflegs"];
  var noofbonuslegs = values["noofbonuslegs"];
  var teamavgdailypointstargetopen = values["teamavgdailypointstargetopen"];
  var dailymaxindividualopen = values["dailymaxindividualopen"];
  var isadvdivisions = values["isadvdivisions"];
  var teamavgdailypointstargetadv = values["teamavgdailypointstargetadv"];
  var dailymaxindividualadv = values["dailymaxindividualadv"];

  var leg_locationtitle = values["leg_locationtitle"];
  var leg_materlegidfk = values["leg_materlegidfk"];

  // Step1
  if (!type || type.trim() === "") {
    errors["type"] = LocaleStrings.requird;
  }
  if (!name || name.trim() === "") {
    errors["name"] = LocaleStrings.requird;
  }
  if (!subdomain || subdomain.trim() === "") {
    errors["subdomain"] = LocaleStrings.requird;
  }
  if (!app || app.trim() === "") {
    errors["app"] = LocaleStrings.requird;
  }
  if (!distributoridfk) {
    errors["distributoridfk"] = LocaleStrings.requird;
  }
  if (!targetentrants) {
    errors["targetentrants"] = LocaleStrings.requird;
  } else if (targetentrants && targetentrants <= 0) {
    errors["targetentrants"] =
      LocaleStrings.events_form_validation_min_entrants;
  }

  // Step2
  if (!siteopendate) {
    errors["siteopendate"] = LocaleStrings.requird;
  }
  if (!siteclosedate) {
    errors["siteclosedate"] = LocaleStrings.requird;
  }
  if (
    siteopendate &&
    siteclosedate &&
    moment(siteopendate).isSameOrAfter(moment(siteclosedate))
  ) {
    errors["siteclosedate"] = LocaleStrings.check_dates_diff;
  }

  if (!regopendate) {
    errors["regopendate"] = LocaleStrings.requird;
  }
  if (!regclosedate) {
    errors["regclosedate"] = LocaleStrings.requird;
  }
  if (
    regopendate &&
    regclosedate &&
    moment(regopendate).isSameOrAfter(moment(regclosedate))
  ) {
    errors["regclosedate"] = LocaleStrings.check_dates_diff;
  }
  if (!dataentryclosedate) {
    errors["dataentryclosedate"] = LocaleStrings.requird;
  }

  if (!invitationexpires) {
    errors["invitationexpires"] = LocaleStrings.requird;
  } else if (invitationexpires && invitationexpires <= 0) {
    errors["invitationexpires"] =
      LocaleStrings.events_form_validation_min_invitation_expires_days;
  }
  if (!autoreminderinterval) {
    errors["autoreminderinterval"] = LocaleStrings.requird;
  } else if (autoreminderinterval && autoreminderinterval <= 0) {
    errors["autoreminderinterval"] =
      LocaleStrings.events_form_validation_min_auto_reminder_days;
  }
  if (!withdrawninterval) {
    errors["withdrawninterval"] = LocaleStrings.requird;
  } else if (withdrawninterval && withdrawninterval <= 0) {
    errors["withdrawninterval"] =
      LocaleStrings.events_form_validation_min_withdrawn_interval;
  }
  if (!indregcutoffdate) {
    errors["indregcutoffdate"] = LocaleStrings.requird;
  }
  if (!challengecoursestartdate) {
    errors["challengecoursestartdate"] = LocaleStrings.requird;
  }
  if (!challengecourseclosedate) {
    errors["challengecourseclosedate"] = LocaleStrings.requird;
  }
  if (
    challengecoursestartdate &&
    challengecourseclosedate &&
    moment(challengecoursestartdate).isSameOrAfter(
      moment(challengecourseclosedate)
    )
  ) {
    errors["challengecourseclosedate"] = LocaleStrings.check_dates_diff;
  }

  // step3
  if (!nooflegs) {
    errors["nooflegs"] = LocaleStrings.requird;
  } else if (nooflegs && nooflegs <= 0) {
    errors["nooflegs"] = LocaleStrings.events_form_validation_min_legs;
  }
  if (!noofbonuslegs) {
    errors["noofbonuslegs"] = LocaleStrings.requird;
  } else if (noofbonuslegs && noofbonuslegs <= 0) {
    errors["noofbonuslegs"] =
      LocaleStrings.events_form_validation_min_bonuslegs;
  }
  if (
    ownProps?.toDisabled &&
    parseInt(ownProps?.initialValues?.nooflegs) +
      parseInt(ownProps?.initialValues?.noofbonuslegs) >
      parseInt(nooflegs) + parseInt(noofbonuslegs)
  ) {
    errors["nooflegs"] = `${
      LocaleStrings.events_form_validation_edit_legs_not_less_last_save
    } i.e. ${
      parseInt(ownProps?.initialValues?.nooflegs) +
      parseInt(ownProps?.initialValues?.noofbonuslegs)
    }`;
    errors["noofbonuslegs"] = `${
      LocaleStrings.events_form_validation_edit_legs_not_less_last_save
    } i.e. ${
      parseInt(ownProps?.initialValues?.nooflegs) +
      parseInt(ownProps?.initialValues?.noofbonuslegs)
    }`;
  }
  if (!teamavgdailypointstargetopen) {
    errors["teamavgdailypointstargetopen"] = LocaleStrings.requird;
  } else if (
    teamavgdailypointstargetopen &&
    teamavgdailypointstargetopen <= 0
  ) {
    errors["teamavgdailypointstargetopen"] =
      LocaleStrings.events_form_validation_min_teamavgdailypointstargetopen;
  }
  if (!dailymaxindividualopen) {
    errors["dailymaxindividualopen"] = LocaleStrings.requird;
  } else if (dailymaxindividualopen && dailymaxindividualopen <= 0) {
    errors["dailymaxindividualopen"] =
      LocaleStrings.events_form_validation_min_dailymaxindividualopen;
  }

  if (isadvdivisions) {
    if (!teamavgdailypointstargetadv) {
      errors["teamavgdailypointstargetadv"] = LocaleStrings.requird;
    } else if (
      teamavgdailypointstargetadv &&
      teamavgdailypointstargetadv <= 0
    ) {
      errors["teamavgdailypointstargetadv"] =
        LocaleStrings.events_form_validation_min_teamavgdailypointstargetadv;
    }
    if (!dailymaxindividualadv) {
      errors["dailymaxindividualadv"] = LocaleStrings.requird;
    } else if (dailymaxindividualadv && dailymaxindividualadv <= 0) {
      errors["dailymaxindividualadv"] =
        LocaleStrings.events_form_validation_min_dailymaxindividualadv;
    }
  }

  // step4
  if (!_.isEmpty(ownProps?.EventFormData)) {
    var totalLegs =
      ownProps?.EventFormData?.legs && ownProps?.EventFormData?.bonuslegs
        ? parseInt(ownProps?.EventFormData?.legs) +
          parseInt(ownProps?.EventFormData?.bonuslegs)
        : 0;
    if (totalLegs != ownProps?.EventFormData?.legsArr?.length) {
      errors["totallegsmismatch"] =
        LocaleStrings.events_form_validation_totalleg_mismatch;
    }

    errors.leg_locationtitle = [];
    errors.leg_materlegidfk = [];
    // This will loop only the actual leg data added
    // _.map(values?.leg_locationtitle, (obj, i) => {
    //   if (!obj || obj.trim() === "") {
    //     // errors[`leg_locationtitle`][i] = LocaleStrings.requird;
    //     // errors.leg_locationtitle.push(LocaleStrings.requird);
    //     errors.leg_locationtitle[i] = LocaleStrings.requird;
    //   }
    // });
    // _.map(values?.leg_materlegidfk, (obj, i) => {
    //   if (!obj) {
    //     errors.leg_materlegidfk[i] = LocaleStrings.requird;
    //   }
    // });

    // This will loop the legs(legs+bonus) added into previous step
    _.times(totalLegs, (i) => {
      if (!leg_locationtitle?.[i] || leg_locationtitle?.[i].trim() === "") {
        // errors["leg_locationtitle"][i] = LocaleStrings.requird;
        errors.leg_locationtitle[i] = LocaleStrings.requird;
      }
      if (!leg_materlegidfk?.[i]) {
        // errors["leg_locationtitle"][i] = LocaleStrings.requird;
        errors.leg_materlegidfk[i] = LocaleStrings.requird;
      }
    });
  }
  // console.log("errors :- ", errors);
  return errors;
};

const mapStateToProps = (state) => {
  var toEdit = !_.isEmpty(state?.AddEventModal?.value);
  var toDisabled = false;
  var initVals = { isactive: 1, isopendivisions: 1, bgtype: "color" };
  if (toEdit) {
    var leg_locationtitle = [];
    var leg_materlegidfk = [];
    var leg_animationidfk = [];
    var leg_detailsidfk = [];
    var leggallery_title = [];
    var leggallery_leggalleryidfk = [];
    _.map(state?.AddEventModal?.value?.eventleg_by_eventidfk, (item) => {
      leg_locationtitle.push(item?.locationtitle);
      leg_materlegidfk.push(item?.materlegidfk);
      leg_animationidfk.push(item?.animationidfk);
      leg_detailsidfk.push(item?.detailsidfk);

      var matchedLeg = _.filter(
        state?.AddEventModal?.value?.leggallery_by_eventidfk,
        (o) => {
          return o.eventlegidfk === item.eventlegid;
        }
      );

      var legtitle = [];
      var leggallery = [];
      _.map(matchedLeg, (obj) => {
        legtitle.push(obj?.title);
        leggallery.push(obj?.leggalleryidfk);
      });

      leggallery_title.push(legtitle);
      leggallery_leggalleryidfk.push(leggallery);
    });

    toDisabled = moment(
      state?.AddEventModal?.value?.activestart
    ).isSameOrBefore(moment());

    initVals = {
      ...state?.AddEventModal?.value,
      ...state?.AddEventModal?.value?.eventsettings_by_eventidfk?.[0],
      leg_locationtitle,
      leg_materlegidfk,
      leg_animationidfk,
      leg_detailsidfk,
      leggallery_title,
      leggallery_leggalleryidfk,
    };
  }

  return {
    session: state.session,
    AddEventModal: state.AddEventModal,
    EventCurrentStep: state.EventCurrentStep,
    toEdit,
    toDisabled,
    initialValues: initVals,
    coachList: state.coachList,
    coachEmails: state.coachEmails,
    systemEmails: state.systemEmails,
    distributorsList: state.distributorsList,
    EventFormData: state.EventFormData,
    settingsReducer: state.settingsReducer,
    certificatesList: state.certificatesList,
    legsList: state.legsList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openEventModal,
      currentStep,
      openEmailRelatedModal,
      storeAddEventFormData,
      fetchCoachs,
      fetchCoachEmails,
      fetchSystemEmails,
      fetchDistributors,
      fetchCertificates,
      fetchLegs,
      fetchSettings,
      checkSubdomain,
      createEvent,
      uploadImagesWithFormData,
      updateEvent,
      deleteMultipleEventLegs,
      deleteAllEventLegsGallery,
      updateEventClientLegFromEvent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EventForm",
    onSubmit: onSubmitForm, // submit function must be passed to onSubmit
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(AddEvent)
);
