import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Button } from "reactstrap";

// constants
import LocaleStrings from "../../../languages/index";
import {
  HOTZONE_NAME_MAXLENGTH,
  BASE_IMAGES_URL,
  LABEL_POSITION_LEFT,
  LABEL_POSITION_TOP,
} from "../../Common/constant";
import { showSuccess, showError } from "../../Common/alertmessage";

// actions
import {
  fetchLegs,
  commonFileUpload,
  removeFileFromS3,
} from "../../../actions";
import { fetchHotzones, updateHotzone } from "../actions";

// components
import {
  renderFieldCheckbox,
  renderFieldText,
  renderRichTextareaWithLabelTinymce,
  renderSelect,
} from "../../Common/base-components";
import Loader from "../../Common/loader";

// files

const LegmapHotzones = (props) => {
  const { session, masterlegList, hotzoneReducer } = props;

  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [masterlegOpts, setMasterlegOpts] = useState([]);
  const [selectedHotzone, setSelectedHotzone] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [imageRefresh, setImageRefresh] = useState(false);
  const [filesToDelete, setfilesToDelete] = useState([]);

  useEffect(() => {
    let localMasterlegs = [];
    if (_.isEmpty(masterlegList)) {
      props.fetchLegs(session, (res) => {
        if (res.success) {
          _.map(res?.data, (masterLegItem) => {
            localMasterlegs.push({
              text: masterLegItem?.name,
              value: _.toInteger(masterLegItem?.id),
            });
          });
          setMasterlegOpts(localMasterlegs);
        }
      });
    } else {
      _.map(masterlegList?.data, (masterLegItem) => {
        localMasterlegs.push({
          text: masterLegItem?.name,
          value: _.toInteger(masterLegItem?.id),
        });
      });
      setMasterlegOpts(localMasterlegs);
    }
  }, []);

  const handleMastergChoose = (masterlegId) => {
    setLoading(true);
    props.fetchHotzones(session, { masterlegId }, (res) => {
      if (res.success) {
        setLoading(false);
      }
    });
  };

  const handleHotzoneSelect = (hotzone) => {
    let localHotzone = _.cloneDeep(hotzone);

    // TinyMCE initial data
    localHotzone.initDetails = hotzone?.hotzonedetails;

    let images = !_.isEmpty(localHotzone.locationimage)
      ? _.split(localHotzone.locationimage, ",")
      : [];
    if (_.isEmpty(images)) {
      localHotzone.locationimage = [];
    } else {
      let localLocationImages = [];
      images.map((item) =>
        localLocationImages.push({ file: item, filename: item })
      );
      localHotzone.locationimage = localLocationImages;
    }

    handleLocationImageRefresh();
    setSelectedHotzone(localHotzone);
  };

  const handleSelectedHotzoneEdit = ({ type = "hotzonename", value = "" }) => {
    let localData = _.cloneDeep(selectedHotzone);

    switch (type) {
      case "hotzonename":
        localData.hotzonename = value;
        break;

      case "hotzonedetails":
        localData.hotzonedetails = value;
        break;

      case "isactive":
        localData.isactive = value;
        break;

      case "locationimage-add":
        let masterlegData = _.find(
          masterlegList?.data,
          (legItem) => legItem?.id == selectedHotzone?.masterlegidfk
        );
        let masterlegFolder =
          masterlegData?.legcode === "nyc"
            ? "newyorkcity"
            : masterlegData?.legcode === "rome"
            ? "italy"
            : masterlegData?.legcode === "nz"
            ? "newzealand"
            : masterlegData?.legcode === "moz"
            ? "mozambique"
            : masterlegData?.legcode;

        let fileLegPath = "legs/#LEGNAME#/details/";
        fileLegPath = fileLegPath.replace("#LEGNAME#", masterlegFolder);

        let reader = new FileReader();
        reader.onloadend = () => {
          let item = reader.result;
          let data = {
            file: item,
            filename: `${fileLegPath}${value.name.replace(/ /g, "_")}`,
          };

          localData.locationimage.push(data);
        };
        reader.readAsDataURL(value);

        handleLocationImageRefresh();
        break;

      case "locationimage-remove":
        localData.locationimage = _.filter(
          selectedHotzone?.locationimage,
          (item, itemKey) => itemKey !== value
        );

        let removeItem = selectedHotzone?.locationimage[value];
        if (!_.includes(removeItem?.filename, ";base64,"))
          setfilesToDelete([...filesToDelete, removeItem?.file]);
        break;

      default:
        break;
    }

    setDataUpdated(true);
    setSelectedHotzone(localData);
  };

  const handleLocationImageRefresh = () => {
    setImageRefresh(true);
    setTimeout(() => {
      setImageRefresh(false);
    }, 50);
  };

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFormOnSubmit = async (e) => {
    e.preventDefault();

    setFormSubmitting(true);

    const hotzoneId = selectedHotzone?.hotzoneid;
    let payloadValue = _.cloneDeep(selectedHotzone);
    delete payloadValue.hotzoneid;
    delete payloadValue.masterlegidfk;
    delete payloadValue.initDetails;

    try {
      let promiseArr = [];
      let deletePromiseArr = [];

      _.map(selectedHotzone?.locationimage, (imageItem, imageItemKey) => {
        promiseArr.push(
          new Promise((resolve, reject) => {
            props.commonFileUpload(
              session,
              { fileData: imageItem?.file, filePath: imageItem?.filename },
              (res) => {
                payloadValue.locationimage[imageItemKey] = imageItem?.filename;
                resolve();
              }
            );
          })
        );
      });

      // Removing deleted files from S3
      _.map(filesToDelete, (imageItem, imageItemKey) => {
        deletePromiseArr.push(
          new Promise((resolve, reject) => {
            props.removeFileFromS3(session, imageItem, (res) => {
              resolve();
            });
          })
        );
      });

      Promise.all(promiseArr).then(() => {
        payloadValue.locationimage = !_.isEmpty(payloadValue?.locationimage)
          ? payloadValue.locationimage.join(",")
          : null;

        props.updateHotzone(
          session,
          { hotzoneId, payload: payloadValue },
          (res) => {
            setFormSubmitting(false);
            if (res?.success) {
              showSuccess(LocaleStrings.hotzone_updated);
              setSelectedHotzone(null);
              handleMastergChoose(selectedHotzone?.masterlegidfk);

              Promise.all(deletePromiseArr).then(() => {});
            } else showError(LocaleStrings.common_fail_message);
          }
        );
      });
    } catch (error) {
      //
    }
  };

  return (
    <section
      className="overflow-auto"
      style={{ height: "calc(100vh - 240px)" }}
    >
      {!_.isEmpty(masterlegOpts) ? (
        <>
          {/* ----- Master leg selector ----- */}
          <div className="row mx-0 d-flex justify-content-center align-items-center mb-3">
            <div className="col-8">
              {renderSelect({
                label: LocaleStrings.hotzone_leg_choose_leg,
                labelposition: LABEL_POSITION_LEFT,
                mandatory: "true",
                placeholder: LocaleStrings.events_form_ph_leg,
                opts: masterlegOpts,
                input: {
                  name: "masterlegSelect",
                  onChange: (e) => handleMastergChoose(e.target.value),
                },
              })}
            </div>
          </div>

          {/* ----- Selected masterleg hotzones list and editing section ----- */}
          {!_.isEmpty(hotzoneReducer) ? (
            <>
              <div className="row mx-0">
                {/* --- Selected masterleg's hotzone list --- */}
                <div className="col-4 border-right">
                  <label className="font-weight-bold text-capitalize">
                    {LocaleStrings.hotzone_list_heading}
                  </label>

                  <div className="d-flex flex-column" style={{ gap: 8 }}>
                    {_.map(hotzoneReducer?.data, (hotzoneItem) => {
                      return (
                        <div
                          key={hotzoneItem?.hotzoneid}
                          className={`col-12 d-flex justify-content-between align-items-center ${
                            !_.isEmpty(selectedHotzone) &&
                            selectedHotzone?.hotzoneid ===
                              hotzoneItem?.hotzoneid
                              ? "bg-light border-info"
                              : "border"
                          } rounded px-3 py-2`}
                          style={{ fontSize: "0.88rem" }}
                        >
                          {/* - Name - */}
                          <span className="">{hotzoneItem?.hotzonename}</span>

                          {/* - Edit hotzone - */}
                          <i
                            className="fa fa-solid fa-pen cursor-pointer"
                            onClick={() => handleHotzoneSelect(hotzoneItem)}
                          ></i>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* --- Selected hotzone edit form --- */}
                <div className="col-8">
                  {!_.isEmpty(selectedHotzone) ? (
                    <>
                      <div className="font-weight-bold mb-2">
                        <span>
                          {LocaleStrings.edit} {LocaleStrings.hotzone_details}
                        </span>
                      </div>

                      <form onSubmit={handleFormOnSubmit}>
                        {/* - Name | Text - */}
                        {renderFieldText({
                          label: LocaleStrings.hotzone_name,
                          labelposition: LABEL_POSITION_TOP,
                          mandatory: "true",
                          type: "text",
                          placeholder: LocaleStrings.hotzone_name_ph,
                          maxlength: HOTZONE_NAME_MAXLENGTH,
                          input: {
                            name: "hotzonename",
                            value: !_.isEmpty(selectedHotzone)
                              ? selectedHotzone?.hotzonename
                              : "",
                            onChange: (e) =>
                              handleSelectedHotzoneEdit({
                                type: "hotzonename",
                                value: e.target.value,
                              }),
                          },
                        })}

                        {/* - Is active | Checkbox - */}
                        {renderFieldCheckbox({
                          label: LocaleStrings.hotzone_active,
                          labelposition: LABEL_POSITION_TOP,
                          mandatory: "true",
                          type: "checkbox",
                          input: {
                            name: "isactive",
                            checked: !_.isEmpty(selectedHotzone)
                              ? selectedHotzone?.isactive
                              : "",
                            onChange: (e) =>
                              handleSelectedHotzoneEdit({
                                type: "isactive",
                                value: e.target.checked,
                              }),
                          },
                        })}

                        {/* - Details | TinyMCE - */}
                        {renderRichTextareaWithLabelTinymce({
                          label: LocaleStrings.hotzone_details,
                          labelposition: LABEL_POSITION_TOP,
                          type: "minimal",
                          placeholder: LocaleStrings.hotzone_details,
                          height: 300,
                          input: {
                            name: "hotzonedetails",
                            value: !_.isEmpty(selectedHotzone)
                              ? selectedHotzone?.hotzonedetails
                              : "",
                            onChange: (e) =>
                              handleSelectedHotzoneEdit({
                                type: "hotzonedetails",
                                value: e,
                              }),
                          },
                          meta: {
                            initial: !_.isEmpty(selectedHotzone)
                              ? selectedHotzone?.initDetails
                              : "",
                          },
                        })}

                        {/* - Leg images | Image */}
                        <div className="d-flex flex-column" style={{ gap: 8 }}>
                          <div className="">
                            <span>{LocaleStrings.hotzone_images}</span>
                          </div>

                          <div
                            className={`d-flex flex-wrap mb-3`}
                            style={{ gap: 8 }}
                          >
                            {/* Image items */}
                            {!imageRefresh ? (
                              _.map(
                                selectedHotzone?.locationimage,
                                (imageItem, imageItemKey) => {
                                  let isBase64 = false;
                                  if (_.includes(imageItem?.file, ";base64,"))
                                    isBase64 = true;

                                  return (
                                    <div
                                      key={`leg_image_${imageItemKey}`}
                                      className="position-relative border rounded"
                                      style={{ minHeight: 80, minWidth: 80 }}
                                    >
                                      <img
                                        src={
                                          isBase64
                                            ? imageItem?.file
                                            : `${BASE_IMAGES_URL}/${imageItem?.file}?api_key=${session?.apiKey}&session_token=${session?.sessionToken}`
                                        }
                                        alt={imageItem?.filename}
                                        className="rounded"
                                        style={{ height: 80, width: "auto" }}
                                      />

                                      <div
                                        className="position-absolute border rounded-circle bg-light d-flex justify-content-center align-items-center cursor-pointer"
                                        style={{
                                          height: "1.5rem",
                                          width: "1.5rem",
                                          text: "0.88rem",
                                          top: 2,
                                          right: 2,
                                        }}
                                        onClick={() =>
                                          handleSelectedHotzoneEdit({
                                            type: "locationimage-remove",
                                            value: imageItemKey,
                                          })
                                        }
                                      >
                                        <i className="fa fa-close"></i>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="" style={{ height: 100 }}></div>
                            )}

                            {/* Add image button */}
                            <div
                              className="bg-light rounded d-flex justify-content-center align-items-center cursor-pointer"
                              style={{ height: 80, width: 80 }}
                              onClick={handleImageUploadClick}
                            >
                              <input
                                ref={fileInputRef}
                                type="file"
                                accept="image/*"
                                className="d-none"
                                onChange={(e) =>
                                  handleSelectedHotzoneEdit({
                                    type: "locationimage-add",
                                    value: e.target.files[0],
                                  })
                                }
                              />
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "0.88rem" }}
                              ></i>
                            </div>
                          </div>
                        </div>

                        {/* - Save button - */}
                        <Button
                          type="submit"
                          className="w-100"
                          color="primary"
                          disabled={
                            formSubmitting ||
                            _.isEmpty(selectedHotzone) ||
                            !dataUpdated
                          }
                        >
                          <i
                            className={
                              formSubmitting ? `fa fa-refresh fa-spin` : ""
                            }
                          ></i>
                          &ensp;
                          {LocaleStrings.button_save}
                        </Button>
                      </form>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ) : loading ? (
            <Loader />
          ) : (
            ""
          )}
        </>
      ) : (
        <span>{LocaleStrings.hotzone_refreshing_leg_details}</span>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    masterlegList: state.legsList,
    hotzoneReducer: state.legmapHotezonesReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLegs,
      fetchHotzones,
      commonFileUpload,
      updateHotzone,
      removeFileFromS3,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LegmapHotzones);
