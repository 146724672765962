import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardBody,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

// constants
import LocaleStrings from "../../languages";
import classnames from "classnames";

// components
import Header from "../Headers/Header";
import MainSettings from "./components/settings";
import Activity from "./components/activity";
import Faq from "./components/faq";
import Region from "./components/region";
import BonusPoints from "./components/bonuspoints";
import EmailTemplate from "./components/emailtemplate";
import LegmapHotzones from "./components/LegmapHotzones";

const Settings = (props) => {
  const { session } = props;

  const tabArr = [
    { key: "settings", text: LocaleStrings.settings },
    { key: "activity", text: LocaleStrings.activity },
    { key: "bonus_points", text: LocaleStrings.bonus_points },
    { key: "faq", text: LocaleStrings.faq },
    { key: "region", text: LocaleStrings.tab_name_region },
    { key: "email", text: LocaleStrings.email_template },
    { key: "hotzones", text: LocaleStrings.hotzone_legmaps_tabpane },
  ];

  const [activeTab, setActiveTab] = useState(tabArr[0].key);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0 h-auto">
              <Nav className="justify-content-center border-bottom-0" tabs>
                {tabArr.map((tab, tabIdx) => (
                  <NavItem className="p-3">
                    <NavLink
                      className={classnames({
                        active: activeTab === tab.key,
                      })}
                      onClick={() => {
                        toggle(tab.key);
                      }}
                    >
                      {tab.text}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <CardBody className="">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="settings">
                    <MainSettings />
                  </TabPane>
                  <TabPane tabId="activity">
                    <Activity />
                  </TabPane>
                  <TabPane tabId="bonus_points">
                    <BonusPoints />
                  </TabPane>
                  <TabPane tabId="faq">
                    <Faq />
                  </TabPane>
                  <TabPane tabId="region">
                    <Region />
                  </TabPane>
                  <TabPane tabId="email">
                    <EmailTemplate />
                  </TabPane>
                  <TabPane tabId="hotzones">
                    <LegmapHotzones />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
