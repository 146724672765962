import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, FieldArray, reset } from "redux-form";
import { Row, Col, Button, Table } from "reactstrap";
import _ from "lodash";
import {
  LABEL_POSITION_TOP,
  itemCount,
  COMMON_FAIL_MESSAGE,
} from "../../Common/constant";
import {
  renderFieldText,
  renderFieldTextarea,
  renderSelect,
} from "../../Common/base-components";
import { showSuccess, showError } from "../../Common/alertmessage";
import LocaleStrings from "../../../languages";
import plus from "../../../assets/img/icons/common/plus-activity-points.svg";
import minus from "../../../assets/img/icons/common/minus-danger-rounded.svg";
import Loader from "../../Common/loader";
import Pagination from "../../Common/pagination";
import { fetchFaq, addFaq, deleteFaq, editFaq, updateFaq } from "../actions";

const initialArrayObj = { faq: [{ question: "", answer: "" }] };

const renderFAQ = ({ fields, meta: { error, submitFailed }, editTrue }) => {
  return (
    <ul style={{ paddingLeft: 0 }}>
      {fields.map((faq, index) => (
        <li key={index} style={{ listStyle: "none" }}>
          <Row>
            <Col sm="8">
              {!editTrue ? (
                <h4>
                  {LocaleStrings.faq}#{index + 1}
                </h4>
              ) : (
                <h4>{LocaleStrings.edit_faq}</h4>
              )}
            </Col>
            <Col sm="4" style={{ textAlign: "right" }}>
              {index > 0 ? (
                <img
                  className="ml-2 cursor-pointer"
                  src={minus}
                  title="Remove FAQ"
                  onClick={() => fields.remove(index)}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Field
            name={`${faq}.question`}
            placeholder={LocaleStrings.faq_question}
            type="text"
            component={renderFieldText}
            labelposition={LABEL_POSITION_TOP}
          />
          <Field
            name={`${faq}.answer`}
            placeholder={LocaleStrings.faq_answer}
            component={renderFieldTextarea}
            labelposition={LABEL_POSITION_TOP}
          />
        </li>
      ))}
      {!editTrue ? (
        <li style={{ listStyle: "none" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                padding: "6px 8px",
                borderRadius: 4,
                background: "#5e72e4",
                cursor: "pointer",
              }}
              onClick={() => fields.push({})}
            >
              <img src={plus} />
            </div>
          </div>
          {submitFailed && error && <span>{error}</span>}
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

const Faq = (props) => {
  let {
    session,
    handleSubmit,
    fetchFaq,
    faqList,
    pristine,
    submitting,
    invalid,
    editFaq,
    editFaqDetails,
    updateFaq,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState([]);
  const toggle = () => setOpen(!dropdownOpen);
  const eventTypeArr = [
    { value: "company", text: "Team" },
    { value: "school", text: "School" },
    { value: "guardian", text: "Guardian" },
  ];
  const [editTrue, setEditTrue] = useState(false);

  useEffect(() => {
    fetchFaq(session);
  }, []);

  const paginationCallback = (pageNum) => {
    props.fetchFaq(session, pageNum, (response) => {});
    setPageNumber(pageNum);
  };

  const onSubmitForm = (values, dispatch) => {
    let error = [];
    values.faq.forEach((act, actIndex) => {
      let objErr = {};
      let c = 0;
      if (!act.question) {
        objErr.question = LocaleStrings.requird;
        c++;
      }
      if (!act.answer) {
        objErr.answer = LocaleStrings.requird;
        c++;
      }
      if (c > 0) {
        error.push(objErr);
      }
    });

    if (error.length > 0) {
      //console.log("error", error);
      setError(error);
      showError(LocaleStrings.question_answer_error);
    } else {
      setLoading(true);

      let resource = [];
      values.faq.forEach((act, actIndex) => {
        let obj = {
          eventtype: values.eventtype,
          question: act.question,
          answer: act.answer,
        };
        resource.push(obj);
      });

      // !toEdit
      !editTrue
        ? props.addFaq(session, resource, (res) => {
            if (res.success) {
              dispatch(reset("FAQForm"));
              setLoading(false);
              fetchFaq(session);
              showSuccess(LocaleStrings.faq_add_success);
            } else {
              setLoading(false);
              showError(COMMON_FAIL_MESSAGE);
            }
          })
        : updateFaq(session, editFaqDetails?.id, resource[0], (res) => {
            if (res.success) {
              dispatch(reset("FAQForm"));
              setLoading(false);
              fetchFaq(session);
              showSuccess(LocaleStrings.faq_update_success);

              let obj = {
                eventtype: "",
                faq: [{ question: "", answer: "" }],
              };
              editFaq(obj);

              setEditTrue(false);
            } else {
              setLoading(false);
              showError(COMMON_FAIL_MESSAGE);
            }
          });
    }
    // reset("FAQForm");
    // reset();
  };

  const faqListRender = () => {
    return _.map(faqList.data, (faq) => {
      let filter = _.filter(selectedActivities, { id: faq.id });
      filter = filter[0]?.id;

      const check = faq.id == filter;

      return (
        <tr>
          <td style={{ width: "30%", whiteSpace: "normal" }}>{faq.question}</td>
          <td style={{ width: "50%", whiteSpace: "normal" }}>{faq.answer}</td>
          <td style={{ width: "15%" }}>
            {faq.eventtype == "company" ? "Team" : _.upperFirst(faq.eventtype)}
          </td>
          <td style={{ width: "5%" }}>
            <i
              className="fa fa-pen"
              aria-hidden="true"
              onClick={() => handleEdit(faq)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
          <td style={{ width: "5%" }}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              onClick={() => handleDelete(faq.id)}
              style={{ cursor: "pointer" }}
            ></i>
          </td>
        </tr>
      );
    });
  };

  const selectAllActivity = (e) => {
    const checked = e.target.checked;
    const arr = [];
    if (checked) {
      _.map(faqList.data, (item) => {
        arr.push({ id: item.id });
      });
    }
    setSelectedActivities(arr);
  };

  const handleDelete = (id) => {
    props.deleteFaq(session, id, (res) => {
      if (res.success) {
        fetchFaq(session);
        showSuccess(LocaleStrings.faq_delete_success);
      } else {
        showError(COMMON_FAIL_MESSAGE);
      }
    });
  };

  const handleEdit = (faqObj) => {
    let obj = {
      id: faqObj.id,
      eventtype: faqObj.eventtype,
      faq: [{ question: faqObj.question, answer: faqObj.answer }],
    };
    editFaq(obj);
    setEditTrue(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Row>
          <Col sm="12">
            <div className="p-3"> {LocaleStrings.faq} </div>
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col sm="6">
            <Field
              name="eventtype"
              placeholder={LocaleStrings.activity_form_ph_type_of_event}
              component={renderSelect}
              opts={eventTypeArr}
            />
          </Col>
        </Row>
        <Row className="justify-content-around">
          <Col sm="6">
            <FieldArray name="faq" component={renderFAQ} editTrue={editTrue} />
          </Col>
        </Row>

        <Row className="justify-content-around my-3">
          <Col sm="6">
            <Button
              type="submit"
              className="w-100"
              color="primary"
              disabled={loading || pristine || invalid || submitting}
            >
              <i className={loading ? `fa fa-refresh fa-spin` : ""}></i>
              &ensp;
              {LocaleStrings.button_save}
            </Button>
          </Col>
        </Row>
      </form>
      <hr className="m-3" />
      <Row className="mt-4 mb-7">
        <Col sm="12">
          {faqList && faqList.data ? (
            <div style={{ marginTop: 15 }}>
              {faqList.data && faqList.data.length > 0 ? (
                <div className="faq-list">
                  <Table
                    className="tablelist align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: "30%" }}>
                          {LocaleStrings.faq_question}
                        </th>
                        <th scope="col" style={{ width: "50%" }}>
                          {LocaleStrings.faq_answer}
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          {LocaleStrings.activity_form_ph_type_of_event}
                        </th>
                        <th scope="col" style={{ width: "5%" }}></th>
                        <th scope="col" style={{ width: "5%" }}></th>
                      </tr>
                    </thead>

                    <tbody>{faqListRender()}</tbody>
                  </Table>
                </div>
              ) : (
                <div className="no-content-message">
                  {LocaleStrings.no_record_activity}
                </div>
              )}
            </div>
          ) : (
            <div className="loaderstyle">
              <Loader />
            </div>
          )}

          {faqList && faqList.count > itemCount ? (
            <div>
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={itemCount}
                totalItemsCount={faqList?.count}
                pageRangeDisplayed={3}
                onChange={paginationCallback}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </div>
  );
};

const validate = (values) => {
  var errors = {
    eventtype: "",
    faq: [],
  };

  if (!values?.eventtype || values?.eventtype.trim() === "") {
    errors.eventtype = LocaleStrings.requird;
  }

  values?.faq?.forEach((f, i) => {
    errors.faq.push({});
    if (!f.question) {
      errors.faq[i].question = "Required*";
    }

    if (!f.answer) {
      errors.faq[i].answer = "Required*";
    }
  });

  return errors;
};

const mapStateToProps = (state) => {
  // console.log("settingsReducer: ", state.settingsReducer);

  var initVals = initialArrayObj;
  var toEdit = !_.isEmpty(state?.editFaqDetails);

  if (toEdit) {
    initVals = {
      faq: state?.editFaqDetails.faq,
      eventtype: state?.editFaqDetails.eventtype,
    };
  }

  return {
    session: state.session,
    faqList: state.faqList,
    editFaqDetails: state.editFaqDetails,
    initialValues: initVals,
    toEdit,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchFaq, addFaq, deleteFaq, editFaq, updateFaq },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "FAQForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Faq)
);
